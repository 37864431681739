import React, {Component} from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Form,
  FormGroup,
  Input,
  Label,
  Row, Modal, ModalBody, ModalFooter, ModalHeader, Alert
} from 'reactstrap';
import axios from 'axios';
import {Link} from "react-router-dom";
import PNotify from 'pnotify/dist/es/PNotify';
import lang from './../../../Language/en-us'
// import lang from './../../../Language/fr-ca'

export default class CreateStaff extends Component {
  constructor(props) {
    super(props);
    this.getSchools();

    this.state = {
      collapse: true,
      fadeIn: true,
      schools: [],
      timeout: 300,
        name: '',
        selected_school: '',
      logo: '',
        user_name: '',
      file: null,
      enable: true,
      email: '',
      success: false,
      error: false,
      validation: false,
      password: '',
      role_id: localStorage.getItem('role'),
      school: localStorage.getItem('school_id'),
    };

    this.toggle = this.toggle.bind(this);
    this.togglePrimary = this.togglePrimary.bind(this);

    this.school = this.school.bind(this);
    this.name = this.name.bind(this);
    this.address = this.address.bind(this);
    this.contact = this.contact.bind(this);
    this.picture = this.picture.bind(this);
    this.user_name = this.user_name.bind(this);
    this.email = this.email.bind(this);
    this.password = this.password.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  togglePrimary() {
    this.setState({
      primary: !this.state.primary,
    });
  }

    user_name(e) {
    this.setState({
        user_name: e.target.value
    })
  }
  email(e) {
    this.setState({
      email: e.target.value
    })
  }

  password(e) {
    this.setState({
      password: e.target.value
    })
  }

  school(e) {
    this.setState({
      school: e.target.value
    })
  }

  name(e) {
      if (/^[0-9a-zA-Z \b]+$/.test(e.target.value)) {
          this.setState({
              name: e.target.value
          });
      }
  }

  address(e) {
    this.setState({
      address: e.target.value
    })
  }

  contact(e) {
    this.setState({
      contact: e.target.value
    })
  }

  picture(e) {
    this.setState({file: e.target.files[0]})
  }

  getSchools() {
    if (localStorage.getItem('role') === '1' || localStorage.getItem('role') === '2') {
      const account = {
        id: localStorage.getItem('id'),
        school_id: localStorage.getItem('school_id'),
        token: localStorage.getItem('token'),
        role: localStorage.getItem('role')
      };
      let uri = global.BASE_URL + '/getschoollist';

      axios.post(uri, account).then((response) => {
          if(response.data.length === 1 || response.data.length == 1){
              this.setState({selected_school: response.data[0].id});
          }
        this.setState({schools: response.data}).bind();
      }).catch(error => {
      });
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({
      enable: false
    });

    const formData = new FormData();
    formData.append('picture', this.state.file);
    formData.append('school', this.state.school);
    formData.append('name', this.state.name);
    formData.append('contact', this.state.contact);
    formData.append('address', this.state.address);
    formData.append('email', this.state.email);
    formData.append('password', this.state.password);
    formData.append('user_name', this.state.user_name);
    formData.append('user_id', localStorage.getItem('id'));

    let uri = global.BASE_URL + '/createstaffmember';
    axios.post(uri, formData).then((response) => {
      if (response.data === 'validation!') {
        // alert('Fill all the fields');
        PNotify.alert({
          text: "Fill all the required fields!",
          type: 'error'
        });
        // this.setState({validation: true});
      } else if (response.data === 'duplicate Email') {
        // alert('Fill all the fields');
        PNotify.alert({
          text: "Email already Exists",
          type: 'error'
        });
        // this.setState({validation: true});
      } else {
        PNotify.alert({
          text: "Staff has been created successfully!",
          type: 'success'
        });
        // this.setState({success: true});
        // alert('Staff Added Successfully');
        // this.props.history.push("/Staff");
      }
    }).catch(error => {
      PNotify.alert({
        text: "Some thing went wrong try again latter!",
        type: 'error'
      });
      // this.setState({error: true});
      this.setState({enable: true});
    });
  }

  render() {
    let success = this.state.success ? <div><Alert color="primary">
      Staff Successfull created
    </Alert></div> : <div></div>;
    let error = this.state.error ? <div><Alert color="danger">
      Some thing went wrong! try again latter
    </Alert></div> : <div></div>;
    let validation = this.state.validation ? <div><Alert color="warning">
      Fill all the fields
    </Alert></div> : <div></div>;

    let schools = this.state.schools;
    let name = this.state.name;
    let selected_school = this.state.selected_school;
    return (
      <div className="animated fadeIn">
        <Row>
          <Card style={{width: 80 + "%", marginLeft: 10 + '%'}}>
            <CardHeader>

              {success}
              {error}
              {validation}
              <Link style={{marginRight: "10px"}} to={{pathname: '/Staff/'}}
                    className="pull-right btn btn-sm btn-primary"><i className="icon icon-arrow-left"/> {lang[73]}
              </Link>

              <strong>{lang[63]}</strong>
            </CardHeader>
            <CardBody>
              <Form onSubmit={this.handleSubmit} id="staff_form" key={"form1"}>
                <FormGroup>
                  {(() => {
                    if (this.state.role_id === '1' || this.state.role_id === '2') {
                      return <FormGroup>
                        <Label htmlFor="nf-school">School</Label>
                        <select name={"school_id"} defaultValue={selected_school} className="form-control col-12" onChange={this.school} required>
                          <option value={""}>Select School</option>
                          {schools.map(item =>
                            <option key={item.id} value={item.id}>{item.name}</option>
                          )};
                        </select>
                      </FormGroup>;
                    } else {
                      return <input type="hidden" defaultValue={localStorage.getItem('school_id')} name="school_id"
                                    onChange={this.school}/>;
                    }
                  })()}
                  <input type="hidden" name="id" value={localStorage.getItem('id')}/>
                  <Label htmlFor="nf-name">Name</Label>
                  <Input type="text" id="nf-name" name="name" placeholder="Staff Name" autoComplete="name"
                         maxLength="50" value={name}
                         onChange={this.name} required/>
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="nf-address">Address</Label>
                  <textarea id="nf-address" name="address" placeholder="Enter staff member address" autoComplete="staff"
                            style={{width: 100 + '%'}} maxLength="200" onChange={this.address}/>
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="nf-contact-number">Contact Number</Label>
                  <Input id="nf-contact-number" name="contact" type={"number"} placeholder="Enter contact number.."
                         autoComplete="contact" maxLength="15" onChange={this.contact}/>
                </FormGroup>

                <FormGroup>
                  <Button color="primary" onClick={this.togglePrimary} className="mr-1">Create User</Button>
                  <Modal isOpen={this.state.primary} toggle={this.togglePrimary}
                         className={'modal-primary ' + this.props.className}>
                    <ModalHeader toggle={this.togglePrimary}>Create User</ModalHeader>
                    <ModalBody>
                      <FormGroup>
                        <Label htmlFor="nf-email">User Name</Label>
                        <Input id="nf-user_name" name="user_name" type={"text"} placeholder="Enter User Name"
                               maxLength="50"
                               onChange={this.user_name}/>
                      </FormGroup> <FormGroup>
                        <Label htmlFor="nf-email">Email</Label>
                        <Input id="nf-email" name="email" type={"email"} placeholder="Enter Email"
                               maxLength="50"
                               onChange={this.email}/>
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="nf-password">Password</Label>
                        <Input id="nf-password" name="password" type={"password"} placeholder="Enter Password"
                               onChange={this.password}/>
                      </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                      <Button color="secondary" onClick={this.togglePrimary}>Ok</Button>
                    </ModalFooter>
                  </Modal>
                </FormGroup>

                <FormGroup>
                  <Label htmlFor="nf-image">Picture</Label>
                  <Input id="nf-image" name="picture" type={"file"} accept={"image/*"} onChange={this.picture}/>
                </FormGroup>

                <Button type="submit" disabled={!this.state.enable} className="create-user-button" size="sm" color="primary"><i
                  className="fa fa-dot-circle-o"/> Submit</Button>
                <Button type="reset" size="sm" color="danger"><i className="fa fa-ban"/> Reset</Button>
              </Form>
            </CardBody>
          </Card>
        </Row>
      </div>
    );
  }
}
