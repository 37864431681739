import React, {Component} from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Form,
  FormGroup,
  Input,
  Label,
  Row, Alert
} from 'reactstrap';
import axios from 'axios';
import {Link} from 'react-router-dom';
import PNotify from 'pnotify/dist/es/PNotify';
import lang from './../../../../Language/en-us'
import {AppSwitch} from "@coreui/react";
// import lang from './../../../../Language/fr-ca'
export default class EditStaff extends Component {
  constructor(props) {
    super(props);
    this.getSingleStaff();
    this.getSchools();
    this.status = this.status.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      collapse: true,
      fadeIn: true,
      timeout: 300,
      items: [],
      schools: [],
      enable: true,
      success: false,
      error: false,
      validation: false,

      name: '',
      address: '',
      role_id: localStorage.getItem('role'),
      logo: '',
      file: null,
      edit_id: '',
      loading: true,
    };
    this.name = this.name.bind(this);
    this.address = this.address.bind(this);
    this.school = this.school.bind(this);
    this.contact = this.contact.bind(this);
    this.logo = this.logo.bind(this);
  }
    status(e) {
        var all_sms = document.getElementById('status').value;
        if(all_sms == '1' || all_sms === 1){
            document.getElementById('status').value = '0';
        }else{
            document.getElementById('status').value = '1';
        }
    }
  getSingleStaff() {
    const values = {
      id: this.props.match.params.id
    };
    let uri = global.BASE_URL + '/getsinglestaff';
    console.log(uri);
    axios.post(uri, values).then((response) => {
      if (response.status === 200) {
        console.log(response);
        this.setState({items: response.data});
        this.setState({edit_id: response.data[0].id});
        this.setState({name: response.data[0].name});
        this.setState({address: response.data[0].address});
        this.setState({contact: response.data[0].contact});
        this.setState({school: response.data[0].school_id});
        this.setState({status: response.data[0].status});
      } else {
        this.setState({items: response.data});
      }
    }).catch(error => {
    });
  }

  getSchools() {
    const account = {
      id: localStorage.getItem('id'),
      school_id: localStorage.getItem('school_id'),
      token: localStorage.getItem('token'),
      role: localStorage.getItem('role')
    };
    let uri = global.BASE_URL + '/getschoollist';

    axios.post(uri, account).then((response) => {
      this.setState({schools: response.data, loading: false});
    }).catch(error => {
      console.log(error);
    });
  }

  school(e) {
    this.setState({
      school: e.target.value
    })
  }

  name(e) {
    this.setState({
      name: e.target.value
    })
  }

  address(e) {
    this.setState({
      address: e.target.value
    })
  }

  contact(e) {
    this.setState({
      contact: e.target.value
    })
  }

  // status(e) {
  //   this.setState({
  //     status: e.target.value
  //   })
  // }

  logo(e) {
    this.setState({file: e.target.files[0]})
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({enable: false});
      var status = document.getElementById('status').value;
    const formData = new FormData();
    formData.append('logo', this.state.file);
    formData.append('school', this.state.school);
    formData.append('name', this.state.name);
    formData.append('contact', this.state.contact);
    formData.append('address', this.state.address);
    formData.append('edit_id', this.state.edit_id);
    formData.append('status', status);
    formData.append('user_id', localStorage.getItem('id'));
    formData.append('remember_token', localStorage.getItem('token'));
    formData.append('token', localStorage.getItem('token'));

    let uri = global.BASE_URL + '/staffupdate';
    axios.post(uri, formData).then((response) => {
        this.setState({enable: true});
      if (response.data === 'validation!') {
        this.setState({enable: true});
        PNotify.alert({
          text: "Fill all the required fields!",
          type: 'error'
        });
        // this.setState({validation: true});
        console.log(formData);
        // alert('Fill all the fields');
      } else {
        // alert('Data Updated Successfully');
        //   this.setState({success: true});
        // this.props.history.push("/Staff");
        PNotify.alert({
          text: "Staff has been upadated successfully!",
          type: 'success'
        });
      }

    }).catch(error => {
      this.setState({enable: true});
      PNotify.alert({
        text: "Some thing went wrong try again latter!",
        type: 'error'
      });
      // this.setState({error: true});
    });
  }

  render() {
    let success = this.state.success ? <div><Alert color="primary">
      Staff Edited Successfully
    </Alert></div> : <div></div>;
    let error = this.state.error ? <div><Alert color="danger">
      Some thing went wrong! try again latter
    </Alert></div> : <div></div>;
    let validation = this.state.validation ? <div><Alert color="warning">
      Fill all the fields
    </Alert></div> : <div></div>;

    let {items, schools } = this.state;
    let content = this.state.loading ? <div className="loading"/> : <CardBody>
      {items.map(item =>
        <Form onSubmit={this.handleSubmit} id="school_form" key={"form1"}>
          <input type={"hidden"} defaultValue={item.id} name={"id"}/>
          <input type={"hidden"} defaultValue={item.status} name={"status"} id={"status"}/>
          {(() => {
            if (this.state.role_id === '1' || this.state.role_id === '2') {
              return <FormGroup>
                <Label htmlFor="nf-contact">School</Label>
                <select name={"school_id"} className="form-control col-12"
                        onChange={this.school} required>

                  <option value={item.school_id}>{item.school_name}</option>
                  {schools.map(item =>
                    <option key={item.id} value={item.id}>{item.name}</option>
                  )};
                </select>

              </FormGroup>
            } else {
              return <input type="hidden" defaultValue={item.school_id}
                            name="school_id" onChange={this.school}/>;
            }
          })()}
          <FormGroup>
            <Label htmlFor="nf-name">Name</Label>
            <Input type="text" id="nf-name" name="name" placeholder="Enter Name"
                   defaultValue={item.name} onChange={this.name}
                   maxLength="50"
                   autoComplete="name"/>
          </FormGroup>
          <FormGroup>
            <Label htmlFor="nf-address">Address</Label>
            <textarea id="nf-address" name="address" placeholder="Enter Address.."
                      autoComplete="address" onChange={this.address}
                      maxLength="200"
                      style={{width: 100 + '%'}} defaultValue={item.address}/>
          </FormGroup>
          <FormGroup>
            <Label htmlFor="nf-number">Contact Number</Label>
            <Input id="nf-number" name="contact" type={"number"} placeholder="Enter Contact Number"
                   autoComplete="number" onChange={this.contact}
                   maxLength="15"
                   defaultValue={item.contact}/>
          </FormGroup>
          <FormGroup>
            <Label htmlFor="nf-image">Picture</Label>
            <Input type="file" accept={"image/*"} id="nf-image" name="logo" onChange={this.logo}/>
          </FormGroup>
          <FormGroup>
            <Label htmlFor="nf-logo">Active</Label>
              <br></br>
              <AppSwitch className={'mx-1'}  id={'statuss'} name={'statuss'} variant={'pill'} color={'primary'}
                         value={'1'} checked={item.status}  onChange={this.status}
              />
            {/*<select name={"status"} className={"col-12 form-control"} required onChange={this.status}*/}
                    {/*defaultValue={item.status}>*/}

              {/*{(() => {*/}
                {/*switch (item.status) {*/}
                  {/*case '1' :*/}
                    {/*return <option value="1">Active</option>;*/}
                  {/*case '0' :*/}
                    {/*return <option value="0">In Active</option>;*/}
                {/*}*/}
              {/*})}*/}
              {/*<option value="1">Active</option>*/}
              {/*<option value="0">In Active</option>*/}
            {/*</select>*/}
          </FormGroup>
          <Button type="submit" size="sm" color="primary" disabled={!this.state.enable}><i
            className="fa fa-dot-circle-o"/> Submit</Button>
        </Form>
      )}
    </CardBody>;
    return (
      <div className="animated fadeIn">
        <Row>
          <Card style={{width: 80 + "%", marginLeft: 10 + '%'}}>
            <CardHeader>

              {success}
              {error}
              {validation}
              <Link to={{pathname: '/Staff'}} className="pull-right btn btn-sm btn-primary"><i
                className="icon icon-arrow-left"/> {lang[73]}</Link>
              <strong>{lang[64]}</strong>
            </CardHeader>
            {content}
          </Card>
        </Row>
      </div>
    );
  }
}
