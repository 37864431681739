import React, {Component} from 'react';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
  Alert
} from 'reactstrap';
import axios from 'axios';
import {Link} from "react-router-dom";
import PNotify from 'pnotify/dist/es/PNotify';
import lang from './../../../Language/en-us'
import { AppSwitch } from '@coreui/react'
// import lang from './../../../Language/fr-ca'

export default class CreateSchool extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.school_type = this.school_type.bind(this);
    this.school_name = this.school_name.bind(this);
    this.address = this.address.bind(this);
    this.user_name = this.user_name.bind(this);
    this.contact_number = this.contact_number.bind(this);
    this.email = this.email.bind(this);
    this.password = this.password.bind(this);
    this.user_role = this.user_role.bind(this);
    this.user_id = this.user_id.bind(this);
    this.user_school = this.user_school.bind(this);
    this.logo = this.logo.bind(this);
    this.allow_sms = this.allow_sms.bind(this);
    this.allow_library = this.allow_library.bind(this);
    this.allow_create_branch = this.allow_create_branch.bind(this);
    this.state = {
      collapse: true,
      fadeIn: true,
      timeout: 300,
      school_name: '',
      address: '',
      user_name: '',
      contact_number: '',
      email: '',
      password: '',
      file: null,
      enabled: true,
      user_role: localStorage.getItem('role'),
      user_id: localStorage.getItem('id'),
      user_school: localStorage.getItem('school_id'),
      logo: '',
      enable: true,
      loading: false,
      headoffice: [],
      school_type: '',
      success: false,
      error: false,
      duplicate_email: false,
      validation: false,
      role_id: localStorage.getItem('role'),
    };
  }

  school_type(e) {
    this.setState({
      school_type: e.target.value
    })
    let uri = global.BASE_URL + '/getheadQuarter';
    axios.post(uri).then((response) => {
      this.setState({headoffice: response.data}).bind();
      console.log(response);
    }).catch(error => {
    });
  }

  logo(e) {
    this.setState({file: e.target.files[0]})
  }

  school_name(e) {
    this.setState({
      school_name: e.target.value
    })
  }
  allow_sms(e) {
      var all_sms = document.getElementById('all_sms').value;
      if(all_sms == '1' || all_sms === 1){
         document.getElementById('all_sms').value = '0';
      }else{
          document.getElementById('all_sms').value = '1';
      }
      var all_sms = document.getElementById('all_sms').value;
  }

  allow_library(e) {
      var all_sms = document.getElementById('all_library').value;
      if(all_sms == '1' || all_sms === 1){
          document.getElementById('all_library').value = '0';
      }else{
          document.getElementById('all_library').value = '1';
      }
      var all_library = document.getElementById('all_library').value;

  }

  allow_create_branch(e) {
      var all_sms = document.getElementById('all_create_branch').value;
      if(all_sms == '1' || all_sms === 1){
          document.getElementById('all_create_branch').value = '0';
      }else{
          document.getElementById('all_create_branch').value = '1';
      }
      var all_create_branch = document.getElementById('all_create_branch').value;
  }

  address(e) {
    this.setState({
      address: e.target.value
    })
  }

  user_name(e) {
    this.setState({
      user_name: e.target.value
    })
  }

  contact_number(e) {
    this.setState({
      contact_number: e.target.value
    })
  }

  email(e) {
    this.setState({
      email: e.target.value
    })
  }

  password(e) {
    this.setState({
      password: e.target.value
    })
  }

  user_role(e) {
    this.setState({
      user_role: e.target.value
    })
  }

  user_id(e) {
    this.setState({
      user_id: e.target.value
    })
  }

  user_school(e) {
    this.setState({
      user_school: e.target.value
    })
  }


  handleSubmit(e) {
    e.preventDefault();
    this.setState({
      enable: false,
      loading: true,
      enabled: false
    });
      var all_sms = document.getElementById('all_sms').value;
      var all_library = document.getElementById('all_library').value;
      var all_create_branch = document.getElementById('all_create_branch').value;
    const formData = new FormData();
    formData.append('logo', this.state.file);
    formData.append('school_name', this.state.school_name);
    formData.append('address', this.state.address);
    formData.append('user_name', this.state.user_name);
    formData.append('contact_number', this.state.contact_number);
    formData.append('email', this.state.email);
    formData.append('password', this.state.password);
    formData.append('user_role', this.state.user_role);
    formData.append('user_id', this.state.user_id);
    formData.append('user_school', this.state.user_school);
    formData.append('allow_sms', all_sms);
    formData.append('allow_library', all_library);
    formData.append('allow_create_branch', all_create_branch);

    let uri = global.BASE_URL + '/create';
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }
    axios.post(uri, formData, config).then((response) => {
      // alert('Data Added Successfully');
      if (response.data === 'Validation') {
        this.setState({loading: false});
        PNotify.alert({
          text: "Fill all the required fields!",
          type: 'error'
        });
        // alert('Fill all the fields');
        // this.setState({validation: true});
        this.setState({enabled: true});
      }
      if (response.data === 'School created!') {
        this.setState({loading: false});
        // this.setState({success: true});
        PNotify.alert({
          text: "School has been created successfully!",
          type: 'success'
        });
        // alert('School has been successfully created');
        // this.props.history.push("/Schools");
      }
      if (response.data === 'Duplicate Email') {
        this.setState({loading: false});
        // alert('Email Already taken');
        this.setState({enabled: true});
        PNotify.alert({
          text: "Email already exists, Select an other one!",
          type: 'error'
        });
        // this.setState({duplicate_email: true});
        // this.props.history.push("/Schools");
      }

    }).catch(error => {
      this.setState({loading: false});
      this.setState({enabled: true});
      PNotify.alert({
        text: "Some thing went try again latter!",
        type: 'error'
      });
      // this.setState({error: true});
      // alert('Error');
    });
  }

  render() {
    let success = this.state.success ? <div><Alert color="primary">
      School Created Successfully
    </Alert></div> : <div></div>;
    let error = this.state.error ? <div><Alert color="danger">
      Some thing went wrong! try again latter
    </Alert></div> : <div></div>;
    let validation = this.state.validation ? <div><Alert color="warning">
      Fill all the fields
    </Alert></div> : <div></div>;
    let duplicate_email = this.state.duplicate_email ? <div><Alert color="warning">
      Email already taken
    </Alert></div> : <div></div>;
    let loader = this.state.loading ? <div className="loading"/> : <div></div>;
    return (
      <div className="animated fadeIn">
        <Row>
          <Card style={{width: 80 + "%", marginLeft: 10 + '%'}}>
            <CardHeader>
              {success}
              {error}
              {validation}
              {duplicate_email}
              <Link to={{pathname: '/schools'}} className={"pull-right btn btn-sm btn-primary"}><i
                className="icon icon-arrow-left"/>&nbsp;{lang[73]}</Link>
              <strong>{lang[71]}</strong>
            </CardHeader>
            <CardBody>
              <Form onSubmit={this.handleSubmit} id="school_form form" key={"form1"}>
                <input type="hidden" name="user_role" value={localStorage.getItem('role')}/>
                <input type="hidden" name="user_id" value={localStorage.getItem('id')}/>
                <input type="hidden" name="user_school" value={localStorage.getItem('school_id')}/>
                <input type="hidden" name="all_sms" id={'all_sms'} value='0'/>
                <input type="hidden" name="all_library" id={'all_library'} value='0'/>
                <input type="hidden" name="all_create_branch" id={'all_create_branch'} value='0'/>

                {/*<p>{this.state.color || "white"}</p>*/}

                <FormGroup>
                  <Label htmlFor="nf-name">School Name</Label>
                  <Input type="text" id="nf-name" name="school_name" placeholder="School Name"
                         autoComplete="name"
                         onChange={this.school_name} required/>

                </FormGroup>
                <FormGroup>
                  <Label htmlFor="nf-address">School Address</Label>
                  <textarea id="nf-address" name="address" placeholder="School Address"
                            autoComplete="address"
                            onChange={this.address} style={{width: 100 + '%'}} required/>

                </FormGroup>
                <FormGroup>
                  <Label htmlFor="nf-contactname">Contact Person Name</Label>
                  <Input id="nf-contactname" name="user_name" type={"text"}
                         placeholder="Enter Contact Person Name.."
                         autoComplete="contact" onChange={this.user_name} required/>

                </FormGroup>
                <FormGroup>
                  <Label htmlFor="nf-number">Contact Number</Label>
                  <Input type="number" id="nf-number" name="contact_number"
                         placeholder={"Enter Contact Number"}
                         onChange={this.contact_number} required/>

                </FormGroup>
                <FormGroup>
                  <Label htmlFor="nf-email">Contact Email</Label>
                  <Input type="email" id="nf-email" name="email" onChange={this.email} required/>

                </FormGroup>
                <FormGroup>
                  <Label htmlFor="nf-password">Password</Label>
                  <Input type="password" id="nf-password" name="password"
                         placeholder={"Enter Password for School Admin"} onChange={this.password}
                         required/>

                </FormGroup>
                <Row>

                  {(() => {
                    switch (this.state.role_id) {
                      case '1':
                        return <Col xs="12" md="4"><FormGroup>
                          <Label htmlFor="nf-contact">Allow SMS</Label>
                            <br></br>
                            <AppSwitch className={'mx-2'}  id={'allow_sms'} name={'allow_sms'} variant={'pill'} color={'primary'}
                                       value={'1'}
                                       onChange={ this.allow_sms}
                            />
                          {/*<select name={"allow_sms"} onChange={this.allow_sms}*/}
                                  {/*className="form-control col-12" required>*/}
                            {/*<option value={""}>Select</option>*/}
                            {/*<option value={"1"}>Yes</option>*/}
                            {/*<option value={"0"}>No</option>*/}

                          {/*</select>*/}
                        </FormGroup></Col>;
                      default:
                        return;
                    }
                  })()}
                  {(() => {
                    switch (this.state.role_id) {
                      case '1':
                        return <Col xs="12" md="4"><FormGroup>
                          <Label htmlFor="nf-contact">Allow Library</Label>
                            <br></br>
                            <AppSwitch className={'mx-2'}  id={'allow_library'} name={'allow_library'} variant={'pill'} color={'primary'}
                                       value={'1'}
                                       onChange={ this.allow_library}
                            />
                          {/*<select name={"allow_library"} onChange={this.allow_library}*/}
                                  {/*className="form-control col-12"*/}
                                  {/*required>*/}
                            {/*<option value={""}>Select</option>*/}
                            {/*<option value={"1"}>Yes</option>*/}
                            {/*<option value={"0"}>No</option>*/}

                          {/*</select>*/}
                        </FormGroup></Col>;
                      default:
                        return;
                    }
                  })()}
                  {(() => {
                    switch (this.state.role_id) {
                      case '1':
                        return <Col xs="12" md="4"><FormGroup>
                          <Label htmlFor="nf-contact">Allow Create Branch</Label>
                            <br></br>
                            <AppSwitch className={'mx-3'}  id={'allow_create_branch'} name={'allow_create_branch'} variant={'pill'} color={'primary'}
                                       value={'1'}
                                       onChange={ this.allow_create_branch}
                            />
                          {/*<select name={"allow_create_branch"} onChange={this.allow_create_branch}*/}
                                  {/*className="form-control col-12"*/}
                                  {/*required>*/}
                            {/*<option value={""}>Select</option>*/}
                            {/*<option value={"1"}>Yes</option>*/}
                            {/*<option value={"0"}>No</option>*/}

                          {/*</select>*/}
                        </FormGroup></Col>;
                      default:
                        return;
                    }
                  })()}
                </Row>

                <FormGroup>
                  <Label htmlFor="nf-logo">Logo</Label>
                  <Input type="file" accept="image/*" id="nf-logo" name="logo" onChange={this.logo}/>
                </FormGroup>
                {loader}
                <Button type="submit" disabled={!this.state.enabled} size="sm" color="primary"><i
                  className="fa fa-dot-circle-o"/> Submit</Button>

                <Button type="reset" size="sm" color="danger"><i className="fa fa-ban"/> Reset</Button>
              </Form>
            </CardBody>
            <CardFooter/>
          </Card>
        </Row>
      </div>
    );
  }
}
