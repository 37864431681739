export default [
  'Dashboard',
  'Schools',
  'Students',
  'Classes',
  'Year Of Entry',
  'Subjects',
  'Result',
  'Result Category',
  'Upload Result',
  'Fees',
  'Fee Structure',
  'Student Fee',
  'Fee Vouchers',
  'Detail Vouchers',
  'Fee Defaulter',
  'Staff',
  'Users',
  'Student Attendance',
  'Staff Attendance',
  'Settings',
  'School Settings',
  'Head Office settings',
  'Library',
  'List',
  'Add Folder',
  'Timetable',
  'Events',
  'Logs',
  'Add New',
  'Edit',
  'Schools List',
  'Subjects',
  'Student Result',
  'Add File',
  'Edit School',
  'Edit Student',
  'Add Student',
  'View Staff Attendance',
  'Edit Attendance',
  'New Attendance',
  'View Attendance',
  'Student Details',
  'Files',
  'Voucher Details',
  'Edit Voucher',
  'Create Event',
  'Edit Event',
  'Bulk Results',
  'Fee Voucher',
  'Single Fee Voucher',
  'Single Voucher',
  'Add Class',
  'Edit Class',
  'Class Details',
  'Add Year Of Entry',
  'Edit Year Of Entry',
  'Add Subject',
  'Edit Subject',
  'Add Result Category',
  'Edit Result Category',
  'Edit',
  'Add Fee Structure',
  'Edit Fee Structure',
  'Add Staff',
  'Edit Staff',
  'Create User',
  'Edit User',
  'Staff Attendance For',
  'Student Attendance For',
  'Fee Voucher For',
  'Fee Defaulter Bar Graph',
  'Create School',
  'Create New Branch',
  'Go Back',
  'Select All',
  'Transfer',
  'Export as',
  'Email Template',
  'SMS Template',
  'Attendance List',
  'Attendance Details',
  'Fee Defaulters',
  'Bulk Upload',
  'BioMetric Attendance',
  'Home Work',
  'Import From CSV',
  'Pay Voucher',
  'Roles',
    'ID Number',
    'Roles',
    'Create Roles',
    'Edit Roles',
    'Send SMS/Email',
    'System Configuration',
    'Fee Component',
    'Add Fee Component',
    'Edit Fee Component',
    'View Detail Notifications',
    'subscription',
    'Teacher Time Table',
    'Attendance Report',
    'Deleted School',
    'Admission',
    'Attendance',
    'Receive Payment',
    'Roles',
    'Reporting',
    'Fee Detail',
    'Fee Detail Student',
    'Fee Defaulters',
    'Students',
    'Fee Voucher Reporting',
    'Student Voucher Reporting',
    'Student List',
    'Student Detail',
    'Parent Reporting',
    'Detailed Student Reporting',
]
