import React, {Component} from 'react';
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row
} from 'reactstrap';
import axios from 'axios';
import {Link} from 'react-router-dom';
import PNotify from 'pnotify/dist/es/PNotify';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {email: '', password: '', enable: true, loading: false};
    this.checkUser();
    this.handleChange1 = this.handleChange1.bind(this);
    this.handleChange2 = this.handleChange2.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange1(e) {
    this.setState({
      email: e.target.value,
      password: '',
      loading: false,
    })
  }

  handleChange2(e) {
    this.setState({
      password: e.target.value
    })
  }

  checkUser() {
    PNotify.defaults.styling = 'material';
// This icon setting requires the Material Icons font. (See below.)
    PNotify.defaults.icons = 'material';
    PNotify.defaults.icons = 'fontawesome4'; // Font Awesome 4
// or
    PNotify.defaults.icons = 'fontawesome5'; // Font Awesome 5
    if (localStorage.getItem('token') === '' || localStorage.getItem('token') === ""
      || localStorage.getItem('token') === null || localStorage.getItem('token') === undefined) {
      this.props.history.push("/login");
    } else {
      const account = {
        token: localStorage.getItem('token'),
        id: localStorage.getItem('id'),
        email: localStorage.getItem('email'),
        invalidData: true
      };

      let uri = global.BASE_URL + '/checkUser';
      axios.post(uri, account).then((response) => {
        if (response.status === 200) {
          //
            this.props.history.push("/dashboard");

        } else {
          window.location.reload();
        }
      }).catch(error => {
        // window.location.reload();
        // this.props.history.push("/login");
        // alert('Email or Password does not match');
      });
    }
  }

  componentWillUpdate(nextProps, nextState) {
    nextState.invalidData = !(nextState.email && nextState.password);
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({enable: false, loading: true});
    const account = {
      email: this.state.email,
      password: this.state.password
    };
    let uri = global.BASE_URL + '/login';
    axios.post(uri, account).then((response) => {
      if (response.data === 'User Inactive!') {
        this.setState({loading: false, enable: true});
        // alert('User Inactive');
        PNotify.alert({
          text: "User is inactive!",
          type: 'error'
        });
      } else if (response.data === 'School Inactive!') {
        this.setState({loading: false, enable: true});
        PNotify.alert({
          text: "User's School is Inactive",
          type: 'error'
        });
        // alert("User's School is Inactive");
      } else if (response.data === 'Subscription Expire!') {
        this.setState({loading: false, enable: true});
        PNotify.alert({
          text: "Subscription has been Expired!",
          type: 'error'
        });
      }  else if (response.data === 'Subscription Not Available! Contact to Admin!') {
        this.setState({loading: false, enable: true});
        PNotify.alert({
          text: "Subscription Not Available! Contact to Admin!",
          type: 'error'
        });
      }   else if (response.data === 'Subscription Not Activate') {
        this.setState({loading: false, enable: true});
        PNotify.alert({
          text: "Subscription Not Activate! Contact to your head office!",
          type: 'error'
        });
      } else if (response.status === 200) {
        localStorage.setItem('id', response.data.id);
        localStorage.setItem('name', response.data.name);
        localStorage.setItem('role', response.data.role);
        localStorage.setItem('email', response.data.email);
        localStorage.setItem('school_id', response.data.school_id);
        localStorage.setItem('token', response.data.remember_token);
        localStorage.setItem('permission', response.data.permission);
        localStorage.setItem('is_create_branch', response.data.is_create_branch);
        localStorage.setItem('subscription', response.data.subscription);
        localStorage.setItem('subscription_approved', response.data.subscription_approved);
        // console.log(localStorage.getItem('id'));
        //TODO
        //call api and get the related menu
        //global.navigation = data from api
        this.props.history.push("/dashboard");
        window.location.reload();
        this.setState({loading: false});
      }
    }).catch(error => {
      this.setState({loading: false, enable: true});
      PNotify.alert({
        text: "Email or Password does not match!",
        type: 'error'
      });
    });
  }

  render() {
    let loader = this.state.loading ? <div className="loading"/> : <div></div>
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <CardGroup>
                <Card className="p-4">
                  <CardBody>
                    <Form onSubmit={this.handleSubmit}>
                      <h1>Login</h1>
                      <p className="text-muted">Sign In to your account</p>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-user"/>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input type="text" placeholder="email" autoComplete="email"
                               name={"email"} onChange={this.handleChange1}/>
                      </InputGroup>
                      <InputGroup className="mb-4">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-lock"/>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input type="password" placeholder="Password"
                               autoComplete="current-password" name={"password"}
                               onChange={this.handleChange2}/>
                      </InputGroup>
                      <Row>
                        <Col xs="6">
                          <Button color="primary" type="submit" className="px-4"
                                  disabled={this.state.invalidData || !this.state.enable}>Login</Button>
                        </Col>
                        <Col xs="6" className="text-right">
                          <Link to={{pathname: '/forgotPassword'}}>Forgot Password</Link>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="6">
                          {loader}
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
                <Card className="text-white bg-primary py-5 d-md-down-none" style={{width: 44 + '%'}}>
                  <CardBody className="text-center">
                    <div>
                      <h2>Sign up</h2>
                      <p>Want to be more productive or Just wanna shift to online school
                        management system to ensure better performance of your staff..?</p>
                      <Link to={{pathname: '/register'}}><Button color="primary" className="mt-3"
                                                                 active>Register
                        Now!</Button></Link>
                    </div>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Login;
