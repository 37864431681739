import './polyfill'
import './constants';
// import './assets/js/es6-shim.js';
// import './assets/js/websdk.client.bundle.min.js';
// import './assets/js/fingerprint.sdk.min.js';
// import './assets/js/searchApp.js';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
// disable ServiceWorker
// import registerServiceWorker from './registerServiceWorker';

ReactDOM.render(<App />, document.getElementById('root'));
// disable ServiceWorker
// registerServiceWorker();
