import './../../../index';
import React, {Component} from 'react';
import {
  Badge, Card, CardBody, CardHeader, Col, Row, Input, Form, FormGroup, Label,
} from 'reactstrap';
import axios from "axios/index";
import {Link} from 'react-router-dom';
import ReactTable from 'react-table';
import PNotify from 'pnotify/dist/es/PNotify';
import lang from "../../../Language/en-us";

class Class extends Component {
  constructor(props) {
    super(props);
    this.getclasses();
    this.getSchools();
    this.state = {
      school: '',
      items: [],
      schools: [],
      loading: true,
      hasError: false,
      class_name: '',
      role_id: localStorage.getItem('role'),
    };
    this.school = this.school.bind(this);
  }

  getclasses() {
    const account = {
      id: localStorage.getItem('id'),
      school_id: localStorage.getItem('school_id'),
      token: localStorage.getItem('token'),
      role: localStorage.getItem('role'),
    };
    let uri = global.BASE_URL + '/BatchListForSchool';

    axios.post(uri, account).then((response) => {
      this.setState({
        items: response.data,
        loading: false,
      });
    }).catch(error => {
    });
  }

  school(e) {
    this.setState({
      school: e.target.value
    });
    const account = {
      id: localStorage.getItem('id'),
      school_id: e.target.value,
      token: localStorage.getItem('token'),
      role: localStorage.getItem('role'),
      filter: '1'
    };
    let uri = global.BASE_URL + '/BatchListForSchool';

    axios.post(uri, account).then((response) => {
      this.setState({
        items: response.data,
        loading: false,
      });
    }).catch(error => {
    });
  }

  getSchools() {
    const account = {
      id: localStorage.getItem('id'),
      school_id: localStorage.getItem('school_id'),
      token: localStorage.getItem('token'),
      role: localStorage.getItem('role')
    };
    let uri = global.BASE_URL + '/getschoollist';
    axios.post(uri, account).then((response) => {
      this.setState({schools: response.data});
    }).catch(error => {
    });
  }

  deleteBatch(e) {
    e.preventDefault();
    let confirm = window.confirm('Do you really want to delete?');
    if (confirm) {

      if (localStorage.getItem('role') === '1' || localStorage.getItem('role') === '2') {
        if (this.state.school === '' || this.state.school === null) {
          // alert('Please select school first');
          PNotify.alert({
            text: "Select School First!",
            type: 'error'
          });
          return;
        }
      }
      const account = {
        delete_id: e.target.id,
        school_id_head: this.state.school,
        school_id: localStorage.getItem('school_id'),
        id: localStorage.getItem('id'),
        token: localStorage.getItem('token'),
        role_id: localStorage.getItem('role'),
      };
      let uri = global.BASE_URL + '/DeleteBatch';

      axios.post(uri, account).then((response) => {
        // alert(response.data);
        if (response.data === 'Batch Deleted!') {
          this.getclasses();
          PNotify.alert({
            text: "Batch has been deleted successfully!",
            type: 'success'
          });
          // alert('Batch has been deleted successfully');
          // window.location.reload();
        } else {
          PNotify.alert({
            text: "Some thing went wrong try again latter!",
            type: 'error'
          });
          // alert('Error, Try again later!');
        }
      }).catch(error => {
        PNotify.alert({
          text: "Some thing went wrong try again latter!",
          type: 'error'
        });
        // alert('Something went wrong, try again later');
      });
    }
  }


  render() {
    let {items, schools} = this.state;
    let content = this.state.loading ? <div className="loading"/> : <ReactTable
      data={items}
      columns={[
        {
          Header: "Batch Name",
          accessor: 'name'
        },
        {
          Header: 'Start Date',
          accessor: 'year'
        },
        {
          Header: 'End Date',
          accessor: 'end_date'
        },
        {
          Header: 'Status',
          accessor: 'status',
          Cell: ({value}) => (value === '1' || value === "1" || value === 1 ?
            <Badge className="badge-success">Active</Badge> :
            <Badge className="badge-danger">In Active</Badge>),
          filterMethod: (filter, row) => {
            if (filter.value === "all") {
              return true;
            }
            if (filter.value === '1') {
              return row[filter.id] === '1';
            }
            return row[filter.id] === '0';
          },
          Filter: ({filter, onChange}) =>
            <select
              onChange={event => onChange(event.target.value)}
              style={{width: "100%"}}
              value={filter ? filter.value : "all"}
            >
              <option value="all">Show All</option>
              <option value='1'>Active</option>
              <option value='0'>In Active</option>
            </select>
        },
        {
          Header: 'Action',
          Cell: row => (
            <div>
              <a href={'javascript:void(0)'} onClick={this.deleteBatch.bind(this)} id={row.original.id}
                 className="btn btn-danger btn-sm mr-1"><i className="fa fa-trash"/></a>
              <Link to={{pathname: `Batches/edit/${row.original.id}`}} id={row.original.id}
                    className="btn btn-primary btn-sm"><i className="fa fa-pencil"/></Link>
                <Link style={{marginLeft: "4px"}} to={{pathname: `/batches/detail/${row.original.id}`}} id={row.original.id}
                      className="btn btn-primary btn-sm"><i className="fa fa-eye"/></Link>
            </div>
          )
        }

      ]}
      defaultPageSize={5}
      className="-striped -highlight -sm"
      showPagination={true}
      showPaginationTop={false}
      showPaginationBottom={true}
      pageSizeOptions={[5, 10, 20, 25, 50, 100]}
      filterable
    />;
    if (this.state.hasError) {
      this.props.history.push('/404')
    }
    return (
      <div className="animated fadeIn">
        <Row>
          <Col>
            <Card>
              <CardHeader>
                <i className="fa fa-align-justify"/> Batches
                <Link to={{pathname: 'Batches/Add'}} className="btn btn-info btn-sm pull-right"><i
                  className={"icon-plus"}/> {lang[28]}</Link>
              </CardHeader>
              <CardBody>
                {(() => {
                  if (this.state.role_id === '1' || this.state.role_id === '2'){
                    return <Form>
                      <Row>
                        <FormGroup>
                          <Col md="12">
                            <Label htmlFor="selectSm"> </Label>
                          </Col>
                          <Col xs="12" md="12">
                            <Input type="select" name="clss" id="SelectLm"
                                   onChange={this.school}>
                              <option value={""}>Select School</option>
                              {schools.map(item =>
                                <option key={item.id}
                                        value={item.id}>{item.name}</option>
                              )};
                            </Input>
                          </Col>
                        </FormGroup>
                      </Row>
                    </Form>;
                  }
                })()}

                {content}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>

    );
  }
}

export default Class;
