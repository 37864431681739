import './../../../index';
import React, {Component} from 'react';
import {
  Card, CardBody, CardHeader, Col, Row,
  Input, Form, FormGroup, Label
} from 'reactstrap';
import {Link} from 'react-router-dom';
import axios from "axios/index";
import ReactTable from 'react-table';
import lang from './../../../Language/en-us'
// import lang from './../../../Language/fr-ca'

class Tables extends Component {
  constructor(props) {
    super(props);
    this.getBatches();
    this.getSchools();
    this.state = {
      items: [],
      classes: [],
      batches: [],
      category: [],
      subjects: [],
      schools: [],
      loading: false,
      clss: '',
      batch: '',
      catego: '',
      marks: [],
      handleEmailListChange: [],
      role_id: localStorage.getItem('role'),
    };
    this.school = this.school.bind(this);
    this.clss = this.clss.bind(this);
    this.batch = this.batch.bind(this);
  }

  getSchools() {
    const account = {
      id: localStorage.getItem('id'),
      school_id: localStorage.getItem('school_id'),
      token: localStorage.getItem('token'),
      role: localStorage.getItem('role')
    };
    let uri = global.BASE_URL + '/getschoollist';

    axios.post(uri, account).then((response) => {
      this.setState({schools: response.data}).bind();
    }).catch(error => {
    });
  }

  clss(e) {
    this.setState({
      clss: e.target.value,
      loading: true,
    });
    e.preventDefault();
    const account = {
      id: localStorage.getItem('id'),
      school_id: localStorage.getItem('school_id'),
        school: this.state.school,
      token: localStorage.getItem('token'),
      role: localStorage.getItem('role'),
      clss: e.target.value,
      batch: this.state.batch,
      active: '1',
    };
    let uri = global.BASE_URL + '/StudentListForResultSchool';

    axios.post(uri, account).then((response) => {
      this.setState({
        items: response.data,
        loading: false,
      });
    }).catch(error => {
    });
  }

  batch(e) {
    this.setState({
      batch: e.target.value
    });
    const account = {
      id: localStorage.getItem('id'),
      school_id: localStorage.getItem('school_id'),
      school: this.state.school,
      token: localStorage.getItem('token'),
      role: localStorage.getItem('role'),
      batch: e.target.value,
      filter: '1',
      active: '1',
    };
    let uri = global.BASE_URL + '/ClassListByBatchForSchool';

    axios.post(uri, account).then((response) => {
      this.setState({classes: response.data});
    }).catch(error => {
    });

      let uris = global.BASE_URL + '/StudentListForResultSchool';

      axios.post(uris, account).then((response) => {
          this.setState({
              items: response.data,
              loading: false,
          });
      }).catch(error => {
      });
  }

  getBatches() {
    const account = {
      id: localStorage.getItem('id'),
      school_id: localStorage.getItem('school_id'),
      token: localStorage.getItem('token'),
      role: localStorage.getItem('role'),
      active: '1',
    };
    let uri = global.BASE_URL + '/BatchListForSchool';

    axios.post(uri, account).then((response) => {
      this.setState({batches: response.data});
    }).catch(error => {
    });
  }

  school(e) {
    this.setState({
      school: e.target.value
    });
    const account = {
      id: localStorage.getItem('id'),
      school_id: e.target.value,
      token: localStorage.getItem('token'),
      role: localStorage.getItem('role'),
      filter: '1',
      active: '1',
    };
    let uris = global.BASE_URL + '/BatchListForSchool';

    axios.post(uris, account).then((response) => {
      this.setState({batches: response.data});
    }).catch(error => {
    });

      let uri = global.BASE_URL + '/StudentListForResultSchool';

      axios.post(uri, account).then((response) => {
          this.setState({
              items: response.data,
              loading: false,
          });
      }).catch(error => {
      });
  }

  render() {
    let { items, classes, batches, schools } = this.state;
    let content = this.state.loading ? <div className="loading"/> :<ReactTable
          data={items}
          columns={[
              {
                  Header: "student ID",
                  accessor: 'studentID'
              },  {
                  Header: "Roll #",
                  accessor: 'roll_number'
              },
              {
                  Header: 'Name',
                  accessor: 'name'
              },
              {
                  Header: 'Class',
                  accessor: 'class'
              },
              {
                  Header: 'Batch',
                  accessor: 'batch'
              },
              {
                  Header: 'Action',
                  Cell: row => (
                      <div>
                          <Link to={{pathname: `/UpdateResult/StudentResult/${row.original.id}`}} id={row.original.id} className="btn btn-primary btn-sm">Submit Result</Link>
                      </div>
                  )
              }

          ]}
          defaultPageSize={10}
          className="-striped -highlight -sm"
          showPagination={true}
          showPaginationTop={false}
          showPaginationBottom={true}
          pageSizeOptions={[5, 10, 20, 25, 50, 100]}
          filterable
      />;
    return (
      <div className="animated fadeIn">

        <Row>
          <Col>
            <Card>

              <CardHeader>
                <i className="fa fa-align-justify"/> {lang[6]}
                  <Link to={{pathname: '/UpdateResult/bulk'}} className="btn btn-info btn-sm pull-right"><i
                      className="fa fa-upload"/> {lang[82]}</Link>
              </CardHeader>

              <CardBody>
                <Form>
                  <Row>
                    {(() => {
                      if( this.state.role_id === '1' || this.state.role_id === '2' ){
                        return <FormGroup>
                          <Col md="12">
                            <Label htmlFor="selectSm"> </Label>
                          </Col>
                          <Col xs="12" md="12">
                            <Input type="select" name="school_id" id="SelectLm" onChange={this.school} required>
                              <option value={""}>Select School</option>
                              {schools.map(item =>
                                <option key={item.id} value={item.id}>{item.name}</option>
                              )};
                            </Input>
                          </Col>
                        </FormGroup>;
                      } else {
                        return <input type="hidden" defaultValue={localStorage.getItem('school_id')}
                                      name="school_id"/>;
                      }
                    })()}
                    <FormGroup>
                      <Col md="12">
                        <Label htmlFor="selectSm"> </Label>
                      </Col>
                      <Col xs="12" md="12">
                        <Input type="select" name="batch" id="SelectLm" onChange={this.batch}>
                          <option value={""}>Select Batch</option>
                          {batches.map(item =>
                            <option key={item.id} value={item.id}>{item.name}</option>
                          )};
                        </Input>
                      </Col>
                    </FormGroup>
                    <FormGroup>
                      <Col md="12">
                        <Label htmlFor="selectSm"> </Label>
                      </Col>
                      <Col xs="12" md="12">
                        <Input type="select" name="clss" id="SelectLm" onChange={this.clss}>
                          <option value={""}>Select Class</option>
                          {classes.map(item =>
                            <option key={item.id} value={item.id}>{item.name}</option>
                          )};
                        </Input>
                      </Col>
                    </FormGroup>
                  </Row>
                </Form>
                  {content}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>

    );

  }
}

export default Tables;
