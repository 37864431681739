import React, {Component} from 'react';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row, Alert
} from 'reactstrap';
import axios from 'axios';
import {Link} from "react-router-dom";
import PNotify from 'pnotify/dist/es/PNotify';
import lang from './../../../Language/en-us'
// import lang from './../../../Language/fr-ca'

export default class CreateClass extends Component {
  constructor(props) {
    super(props);
    this.getBatch();
    this.getTeachers();
    this.getSchools();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      collapse: true,
      fadeIn: true,
      schools: [],
      subjects: [],
      setting: [],
      timeout: 300,
        selected_school: '',
      batches: [],
      teachers: [],
      success: false,
      error: false,
      validation: false,
      enable: true,
      loadingss: false,
      role_id: localStorage.getItem('role'),
    };
    this.school = this.school.bind(this);
    this.batch = this.batch.bind(this);
  }

  getTeachers() {
    const account = {
      id: localStorage.getItem('id'),
      school_id: localStorage.getItem('school_id'),
      token: localStorage.getItem('token'),
      role: localStorage.getItem('role'),
      active: '1',
    };
    let uri = global.BASE_URL + '/TeacherListForSchool';

    axios.post(uri, account).then((response) => {
      console.log(response);
      this.setState({teachers: response.data}).bind();
    }).catch(error => {
    });
    let urls = global.BASE_URL + '/getschoolsettings';
    axios.post(urls, account).then((responses) => {
      this.setState({setting: responses.data});
    }).catch(error => {
      // alert('Email or Password does not match');
    });
  }

  getBatch() {
    const account = {
      id: localStorage.getItem('id'),
      school_id: localStorage.getItem('school_id'),
      token: localStorage.getItem('token'),
      role: localStorage.getItem('role'),
      active: '1',
    };
    let uri = global.BASE_URL + '/BatchListForSchool';

    axios.post(uri, account).then((response) => {
      this.setState({batches: response.data});
    }).catch(error => {
    });
  }

  school(e) {
    this.setState({
      school: e.target.value
    });
    const account = {
      id: localStorage.getItem('id'),
      school_id: e.target.value,
      token: localStorage.getItem('token'),
      role: localStorage.getItem('role'),
      filter: '1',
      active: '1',
    };
    // const {history} = this.props;
    let uri = global.BASE_URL + '/BatchListForSchool';

    axios.post(uri, account).then((response) => {
      this.setState({batches: response.data}).bind();
    }).catch(error => {
      // alert('Email or Password does not match');
    });

    let url = global.BASE_URL + '/TeacherListForSchool';
    axios.post(url, account).then((responses) => {
      this.setState({teachers: responses.data});
    }).catch(error => {
      // alert('Email or Password does not match');
    });

    let urls = global.BASE_URL + '/getschoolsettings';
    axios.post(urls, account).then((responses) => {
      this.setState({setting: responses.data});
    }).catch(error => {
      // alert('Email or Password does not match');
    });
  }

  batch(e) {
    this.setState({
      batch: e.target.value
    });
    const account = {
      id: e.target.value,
    };
    // const {history} = this.props;
    let uri = global.BASE_URL + '/BatchSubject';

    axios.post(uri, account).then((response) => {
      this.setState({subjects: response.data}).bind();
    }).catch(error => {
      // alert('Email or Password does not match');
    });
  }

  getSchools() {
    const account = {
      id: localStorage.getItem('id'),
      school_id: localStorage.getItem('school_id'),
      token: localStorage.getItem('token'),
      role: localStorage.getItem('role'),
      active: '1',
    };
    // const {history} = this.props;
    let uri = global.BASE_URL + '/getschoollist';

    axios.post(uri, account).then((response) => {
        if(response.data.length === 1 || response.data.length == 1){
            this.setState({selected_school: response.data[0].id});
        }
      this.setState({schools: response.data}).bind();
    }).catch(error => {
      // alert('Email or Password does not match');
    });
  }

  handleSubmit(e) {
    this.setState({loadingss: true});
    //this.setState({enable: false});
    e.preventDefault();
    var serialize = require('form-serialize');
    var form = document.querySelector('#class_form');

    var obj = serialize(form, {hash: true});
    let uri = global.BASE_URL + '/CreateClasses';
    axios.post(uri, obj).then((response) => {
      if (response.data === 'validation') {

        PNotify.alert({
          text: "Fill all the required fields!",
          type: 'error'
        });
        // this.setState({validation: true});
        this.setState({loadingss: false});
        // alert('Fill all fields');
      } else if (response.data === 'Class created!') {
        // alert('Class Created Successfully');
        // this.props.history.push("/Classes");
        this.setState({loadingss: false});
        PNotify.alert({
          text: "Class has been created successfully!",
          type: 'success'
        });
        // this.setState({success: true});
      }
      //console.log(obj);
      //console.log(response);

    }).catch(error => {
      // this.setState({error: true});
      PNotify.alert({
        text: "Some thing went wrong try again latter!",
        type: 'error'
      });
      // alert('Some thing went wrong try again latter');
      this.setState({loadingss: false});
      this.setState({enable: false});
      console.log(error);
    });
  }

  render() {

    let loader = this.state.loadingss ? <div className="loading"/> : <div></div>
    let teachers = this.state.teachers;
    let batches = this.state.batches;
    let schools = this.state.schools;
    let subjects = this.state.subjects;
    let selected_school = this.state.selected_school;
    let success = this.state.success ? <div><Alert color="primary">
      Class Created Successfully
    </Alert></div> : <div></div>;
    let error = this.state.error ? <div><Alert color="danger">
      Some thing went wrong! try again latter
    </Alert></div> : <div></div>;
    let validation = this.state.validation ? <div><Alert color="warning">
      Fill all the fields
    </Alert></div> : <div></div>;
    let setting = this.state.setting;
    return (
      <div className="animated fadeIn">
        <Row>
          <Card style={{width: 80 + "%", marginLeft: 10 + '%'}}>
            <CardHeader>


              {success}
              {error}
              {validation}
              <Link to={{pathname: '/Classes'}} className={"pull-right btn btn-sm btn-primary"}><i
                className="icon icon-arrow-left"/>&nbsp;{lang[73]}</Link>
              <strong>{lang[51]}</strong>
            </CardHeader>
            <CardBody>
              <Form onSubmit={this.handleSubmit} id="class_form" key={"form1"}>
                {(() => {
                  if (this.state.role_id === '1' || this.state.role_id === '2') {
                    return <FormGroup>
                      <Label htmlFor="nf-contact">School</Label>
                      <select name={"school_id"} defaultValue={selected_school} className="form-control col-12"
                              onChange={this.school} required>
                        <option value={""}>Select School</option>
                        {schools.map(item =>
                          <option key={item.id} value={item.id}>{item.name}</option>
                        )};
                      </select>

                    </FormGroup>;
                  } else {
                    return <input type="hidden" defaultValue={localStorage.getItem('school_id')}
                                  name="school_id"/>;
                  }
                })()}
                <input type="hidden" name="id" value={localStorage.getItem('id')}/>
                <FormGroup>
                  <Label htmlFor="nf-name">Class Name</Label>
                  <Input type="text" id="nf-name" name="class_name" placeholder="Class Name"
                         autoComplete="name" required/>
                  <p id="error-class"></p>

                </FormGroup>
                <FormGroup>
                  <Label htmlFor="nf-email">Class Incharge</Label>
                  <select name={"teacher"} className={"col-12 form-control"} required>
                    <option value={""}>Select Teacher</option>
                    {teachers.map(item =>
                      <option key={item.id} value={item.id}>{item.name}</option>
                    )};
                  </select>

                </FormGroup>
                <FormGroup>
                  <Label htmlFor="nf-logo">Batch</Label>
                  <select name={"batch_id"} className={"col-12 form-control"} required
                          onChange={this.batch}>
                    <option value={""}>Select Batch</option>
                    {batches.map(item =>
                      <option key={item.id} value={item.id}>{item.name}</option>
                    )};
                  </select>

                </FormGroup>


                <FormGroup row>
                  <Col md="3"><Label>Subjects</Label></Col>
                  {subjects.map(item =>
                    <Col md="3" sm="9" lg="3" xs="9">
                      <FormGroup check className="checkbox">

                        <Input className="form-check-input"
                               type="checkbox" id={item.subject_id}
                               name="subjects[]" value={item.subject_id}/>

                        <Label check className="form-check-label "
                               htmlFor="checkbox1">{item.subject_name}</Label>
                      </FormGroup>
                    </Col>
                  )}
                </FormGroup>
                {setting.map(item =>
                  <FormGroup>
                    <Label htmlFor="nf-logo">Attendance Time</Label>

                    <input type={"time"} name={"attendance_time"} defaultValue={item.attendace_time}
                           className={"col-12 form-control"}
                           required>

                    </input>
                  </FormGroup>
                )}
                {loader}
                <Button type="submit" size="sm" disabled={!this.state.enable} color="primary"><i
                  className="fa fa-dot-circle-o"/> Submit</Button>
                <Button type="reset" size="sm" color="danger"><i className="fa fa-ban"/> Reset</Button>
              </Form>
            </CardBody>
            <CardFooter/>
          </Card>
        </Row>
      </div>
    );
  }
}
