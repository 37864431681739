import React, {Component} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {Container} from 'reactstrap';
import {
    AppAside,
    AppBreadcrumb,
    AppFooter,
    AppHeader,
    AppSidebar,
    AppSidebarFooter,
    AppSidebarForm,
    AppSidebarHeader,
    AppSidebarMinimizer,
    AppSidebarNav,
} from '@coreui/react';
import navigation from '../../_nav';
import routes from '../../routes';
import DefaultAside from './DefaultAside';
import DefaultFooter from './DefaultFooter';
import DefaultHeader from './DefaultHeader';
import axios from "axios/index";

class DefaultLayout extends Component {
    constructor(props) {
        super(props);
        this.checkUser();


        var today = new Date();
        if ((today.getMonth() + 1) <= 9) {
            var month = "0" + (today.getMonth() + 1);
        } else {
            var month = (today.getMonth() + 1);
        }
        if (today.getDate() <= 9) {
            var day = "0" + (today.getDate());
        } else {
            var day = (today.getDate());
        }
        var date = today.getFullYear() + '-' + month + '-' + day;
        this.state = {
            items: [],
            subscription_date: localStorage.getItem('subscription'),
            subs_date: date,
        }
        this.subs();
    }

    checkUser() {
        if (localStorage.getItem('token') === '' || localStorage.getItem('token') === null
            || localStorage.getItem('token') === "" || localStorage.getItem('token') === undefined) {
            this.props.history.push("/login");
        } else {
            const account = {
                token: localStorage.getItem('token'),
                id: localStorage.getItem('id'),
                email: localStorage.getItem('email'),
            };

            let uri = global.BASE_URL + '/checkUser';
            axios.post(uri, account).then((response) => {
            }).catch(error => {
                this.props.history.push("/login");
            });
        }
    }

    subs() {
        if (localStorage.getItem('subscription_approved') == '0' && localStorage.getItem('role') == '2') {
            if (this.state.subscription_date >= this.state.subs_date) {
                if (window.location.href.match('subscription')) {
                } else {
                    this.props.history.push("/subscription");
                    // location.href="/subscription";
                    // window.location.reload();
                }
            }
        }
    }

    render() {
        return (
            <div className="app">
                <AppHeader fixed>
                    <DefaultHeader/>
                </AppHeader>
                <div className="app-body">
                    <AppSidebar fixed display="lg">
                        <AppSidebarHeader/>
                        <AppSidebarForm/>
                        <AppSidebarNav navConfig={navigation} {...this.props} />
                        <AppSidebarFooter/>
                        <AppSidebarMinimizer/>
                    </AppSidebar>
                    <main className="main">
                        <AppBreadcrumb appRoutes={routes}/>
                        <Container fluid>
                            <Switch>
                                {routes.map((route, idx) => {
                                        return route.component ? (
                                                <Route key={idx} path={route.path} exact={route.exact} name={route.name}
                                                       render={props => (
                                                           <route.component {...props} />
                                                       )}/>)
                                            : (null);
                                    },
                                )}
                                <Redirect from="*" to="/404"/>
                            </Switch>
                        </Container>
                    </main>
                    <AppAside fixed hidden>
                        <DefaultAside/>
                    </AppAside>
                </div>
                <AppFooter>
                    <DefaultFooter/>
                </AppFooter>
            </div>
        );

    }

}

export default DefaultLayout;
