import React from 'react'
import axios from 'axios'
import {
  Row,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  FormGroup,
  Label,
  Button
} from "reactstrap"
import PNotify from 'pnotify/dist/es/PNotify'
import lang from './../../../Language/en-us'
// import lang from './../../../Language/fr-ca'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default class EmailTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emailText: '',
      emailTextDefault: '',
      emailType: '',
        selected_school: '',
      school_id: '',
      schools: [],
      success: false,
      error: false,
      validation: false,
      isSubmit: true,
      isUpdate: false,
      isEnableUpdate: true,
      role_id: localStorage.getItem('role')
    };
    this.getSchools();
  }

  getSchools() {
    const account = {
      id: localStorage.getItem('id'),
      school_id: localStorage.getItem('school_id'),
      token: localStorage.getItem('token'),
      role: localStorage.getItem('role')
    };
    let uri = global.BASE_URL + '/getschoollist';

    axios.post(uri, account).then((response) => {
        if(response.data.length === 1 || response.data.length == 1){
            this.setState({selected_school: response.data[0].id});
        }
      this.setState({
        schools: response.data
      });

    }).catch(error => {
    });
  }

  // onChange = e => {
  //   const {
  //     target: {value, name}
  //   } = e ;
  //   this.setState({
  //     [name]: value
  //   })
  // };

  onSchoolChange = e => {
    this.setState({
      school_id: e.target.value,
      emailText: '',
      emailType: '',
      isSubmit: true,
      isUpdate: false
    })
  };

  onEmailTextChange = e => {
    this.setState({
      emailText: e
    })
  };

  onEmailTypeChange = e => {
    this.setState({
      emailType: e.target.value,
      isSubmit: true,
      emailText: '',
      isUpdate: false,
      isEnableUpdate: true
    });
    let uri = global.BASE_URL + '/getEmailTemplate';
    axios.post(uri, {
      school_id: this.state.school_id,
      emailType: e.target.value
    }).then((response) => {
      this.setState({
        emailTextDefault: response.data[0].email_html
      });
      if (response.data[0].email_html !== null) {
        this.setState({
          isSubmit: false,
          isUpdate: true,
          isEnableUpdate: true
        })
      }
    }).catch(e => {
      return e;
    })
  };

  //Submit Email
  onSubmit = e => {
    e.preventDefault();
    this.setState({
      isSubmit: false,
      isUpdate: false,
      isEnableUpdate: false
    });
    if (this.state.school_id === '') {
      this.setState({
        school_id: localStorage.getItem('school_id')
      });
    }
    if (this.state.school_id === '' || this.state.emailText === '' || this.state.emailType === '') {
      PNotify.alert({
        text: "Please Fill All The Fields",
        type: 'notice'
      });
      this.setState({
        isSubmit: true,
        isUpdate: false,
        isEnableUpdate: false
      });
      return;
    }

    const formData = new FormData();
    formData.append('emailText', this.state.emailText);
    formData.append('emailType', this.state.emailType);
    formData.append('school_id', this.state.school_id);

    let uri = global.BASE_URL + '/setEmailTemplate';
    axios.post(uri, formData).then((response) => {
      if (response.data === 'Success') {
        this.setState({enable: false});
        PNotify.alert({
          text: "Success",
          type: 'success'
        });
        this.setState({
          isUpdate: true,
          isEnableUpdate: true
        })
      } else if (response.data === 'Already') {
        PNotify.alert({
          text: "Only one template can be allowed at a time",
          type: 'info'
        });
        this.setState({
          isUpdate: true,
          isEnableUpdate: true
        })
      } else {
        PNotify.alert({
          text: "Some thing went wrong try again later!",
          type: 'error'
        });
      }
    }).catch(error => {
      this.setState({
        isSubmit: true,
      });
      PNotify.alert({
        text: "Some thing went wrong try again later!",
        type: 'error'
      });
    });
  };

  //Update Email
  updateEmail = e => {
    e.preventDefault();
    this.setState({
      isEnableUpdate: false
    });
    if (this.state.school_id === '') {
      this.setState({
        school_id: localStorage.getItem('school_id')
      });
    }
    if (this.state.school_id === '' || this.state.emailText === '' || this.state.emailType === '') {
      PNotify.alert({
        text: "Please Fill All The Fields",
        type: 'notice'
      });
      this.setState({
        isEnableUpdate: true
      });
      return;
    }

    const formData = new FormData();
    formData.append('emailText', this.state.emailText);
    formData.append('emailType', this.state.emailType);
    formData.append('school_id', this.state.school_id);

    let uri = global.BASE_URL + '/updateEmailTemplate';
    axios.post(uri, formData).then((response) => {
      if (response.data === 'Success') {
        this.setState({enable: false});
        PNotify.alert({
          text: "Successfully Updated",
          type: 'success'
        });
        this.setState({
          isEnableUpdate: true
        });
      } else if (response.data === 'Not Defined') {
        PNotify.alert({
          text: "No template found for this school or type",
          type: 'info'
        });
        this.setState({
          isSubmit: true,
          isUpdate: false,
          isEnableUpdate: false
        });
      } else {
        PNotify.alert({
          text: "Some thing went wrong try again later!",
          type: 'error'
        });
      }
    }).catch(error => {
      this.setState({
        isUpdate: true,
        isEnableUpdate: true
      });
      PNotify.alert({
        text: "Some thing went wrong try again latter!",
        type: 'error'
      });
    });
  };

  render() {
    let {success, error, validation, schools, emailType, isSubmit, isUpdate, isEnableUpdate ,selected_school} = this.state;
    let style = {
      width: '50%',
      margin: '5px auto',
      backgroundColor: '#a2a0a078',
      borderRadius: '3px',
      padding: '10px'
    };
    let modules = {
      toolbar: [
        [{header: []}, {font: []}],
        ['bold', 'italic', 'underline', 'strike'],
        [{color: []}, {background: []}],
        [{list: 'ordered'}, {list: 'bullet'}],
        ['link']
      ],
    };
    let emailTypeInfo = emailType === 'absent' ? 'Put {school_name} for School Name, {student_name} for Student Name,' +
      ' {student_id} for Student Roll NO, {date} for Date & {student_class} for Student Class'
      : emailType === 'defaulter' ? 'Put {school_name} for School Name, ' +
        '{student_name} for Student Name, {student_id} for Student Roll NO, {student_class} for Student Class, {fee_month} for month ' +
        '& {date} for Date'
        : emailType === 'event' ? 'Put {school_name} for School Name, ' +
          '{event_name} for Event Name, {start_date} for Start Date & {end_data} for End Date ' : '';
    let updateButton = isUpdate ? <Button type="submit" size="sm" color="info"
                                          onClick={this.updateEmail} disabled={!isEnableUpdate}><i
      className="fa fa-dot-circle-o"/> Update</Button> : '';
    return (
      <div className="animated fadeIn">
        <Row>
          <Card style={{width: 80 + "%", marginLeft: 10 + '%'}}>
            {success}
            {error}
            {validation}
            <CardHeader>
              <strong>{lang[77]}</strong>
            </CardHeader>
            <CardBody>
              <Form onSubmit={this.onSubmit} id="email_form" key={"email_form"}>
                {(() => {
                  if (this.state.role_id === '1' || this.state.role_id === '2') {
                    return <FormGroup>
                      <Label htmlFor="school_id">School</Label>
                      <select name='school_id' className="form-control col-12" required value={selected_school}
                              onChange={this.onSchoolChange}>
                        <option value={""}>Select School</option>
                        {schools.map(item =>
                          <option key={item.id} value={item.id}>{item.name}</option>
                        )};
                      </select>

                    </FormGroup>;
                  } else {
                    return <input type="hidden" defaultValue={localStorage.getItem('school_id')}
                                  name="school_id"/>;
                  }
                })()}
                <FormGroup>
                  <Label htmlFor="emailType">Email Type</Label>
                  <select name='emailType' className="form-control col-12" onChange={this.onEmailTypeChange}
                          required value={this.state.emailType}>
                    <option value={""}>Select Type</option>
                    <option key={'email1'} value={'absent'}>Absent</option>
                    <option key={'email2'} value={'defaulter'}>Fee Defaulter</option>
                    <option key={'email3'} value={'event'}>Event</option>
                  </select>
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="emailText">Email Template</Label>
                  <ReactQuill
                    modules={modules}
                    value={this.state.emailText}
                    onChange={this.onEmailTextChange}
                    placeholder={'Start designing your Own Template'}
                  />
                  <small className={'form-text text-muted'}>{emailTypeInfo}</small>
                </FormGroup>
                <br/>
                <br/>
                <div>
                  <Button type="submit" size="sm" color="primary"
                          onClick={this.onSubmit} disabled={!isSubmit}><i
                    className="fa fa-dot-circle-o"/> Submit</Button>
                  {updateButton}
                  <Button type="reset" size="sm" color="danger"><i className="fa fa-ban"/> Reset</Button>
                </div>
              </Form>
              <br/>
              <h3>Preview</h3>
              <div style={style} dangerouslySetInnerHTML={{__html: this.state.emailText}}/>
            </CardBody>
            <CardFooter/>
          </Card>
        </Row>
      </div>
    )
  }
}
