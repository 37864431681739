import React, {Component} from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Form,
  FormGroup,
  Input,
  Label,
  Row, Alert
} from 'reactstrap';
import axios from 'axios';
import {Link} from 'react-router-dom';
import PNotify from 'pnotify/dist/es/PNotify';
import lang from './../../../../Language/en-us'
import {AppSwitch} from "@coreui/react";
// import lang from './../../../../Language/fr-ca'

export default class EditStaff extends Component {
  constructor(props) {
    super(props);
    this.getSingleUser();
    this.getSchools();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.status = this.status.bind(this);
    this.password = this.password.bind(this);
    this.state = {
      collapse: true,
      fadeIn: true,
      timeout: 300,
      success: false,
      error: false,
      validation: false,
      items: [],
      schools: [],
      name: '',
      address: '',
      logo: '',
      role_id: localStorage.getItem('role'),
      enable: true,
      loading: true,
    };

  }
    status(e) {
        var all_sms = document.getElementById('status').value;
        if(all_sms == '1' || all_sms === 1){
            document.getElementById('status').value = '0';
        }else{
            document.getElementById('status').value = '1';
        }
    }
  password(e) {
    this.setState({
      password: '1'
    })
  }

  getSingleUser() {
    const values = {
      id: this.props.match.params.id
    };
    let uri = global.BASE_URL + '/getsingleuser';
    console.log(uri);
    axios.post(uri, values).then((response) => {
      // browserHistory.push('localhost:8080/reactProject/public');
      if (response.status === 200) {
        console.log(response);
        this.setState({items: response.data});
      }
      // console.log(response.data);
    }).catch(error => {
    });
  }

  getSchools() {
    const account = {
      id: localStorage.getItem('id'),
      school_id: localStorage.getItem('school_id'),
      token: localStorage.getItem('token'),
      role: localStorage.getItem('role')
    };
    let uri = global.BASE_URL + '/getschoollist';

    axios.post(uri, account).then((response) => {
      this.setState({schools: response.data, loading: false});
    }).catch(error => {
    });
  }

  handleSubmit(e) {
    this.setState({enable: false});
    e.preventDefault();
    var serialize = require('form-serialize');
    var form = document.querySelector('#school_form');
    var obj = serialize(form, {hash: true});

    let uri = global.BASE_URL + '/updatesingleuser';
    axios.put(uri, obj).then((response) => {
        this.setState({enable: true});
      // browserHistory.push('localhost:8080/reactProject/public');
      // alert('Data Update Successfully');
      //   this.setState({success: true});
      PNotify.alert({
        text: "User has been updated successfully!",
        type: 'success'
      });
      console.log(obj);
      console.log(response);
      // this.props.history.push("/Users");
    }).catch(error => {
      PNotify.alert({
        text: "Some thing went wrong try again latter!",
        type: 'error'
      });
      // this.setState({error: true});
      this.setState({enable: true});
    });
  }

  render() {
    let success = this.state.success ? <div><Alert color="primary">
      User Successfully Updated
    </Alert></div> : <div></div>;
    let error = this.state.error ? <div><Alert color="danger">
      Some thing went wrong! try again latter
    </Alert></div> : <div></div>;
    let validation = this.state.validation ? <div><Alert color="warning">
      Fill all the fields
    </Alert></div> : <div></div>;
    let items = this.state.items;
    let schools = this.state.schools;
    let content = this.state.loading ? <div className="loading"/> : <CardBody>
      {items.map(item =>
        <Form onSubmit={this.handleSubmit} id="school_form" key={"form1"}>
          <input type={"hidden"} defaultValue={item.id} name={"id"}/>
          <input type={"hidden"} defaultValue={this.state.password} name={"pass"}/>
          <input type={"hidden"} defaultValue={localStorage.getItem('id')} name={"user_id"}/>
          <input type={"hidden"} defaultValue={item.status} name={"status"} id={"status"}/>
          {(() => {
            if (this.state.role_id === '1' || this.state.id === '2') {
              return <FormGroup>
                <Label htmlFor="nf-contact">School</Label>
                <select name={"school_id"} className="form-control col-12"
                        onChange={this.school} required>
                  <option value={item.school_id}>{item.school_name}</option>
                  {schools.map(item =>
                    <option key={item.id} value={item.id}>{item.name}</option>
                  )};
                </select>

              </FormGroup>
            } else {
              return <input type="hidden" defaultValue={item.school_id}
                            name="school_id" onChange={this.school}/>;
            }
          })()}
          <FormGroup>
            <Label htmlFor="nf-name">Name</Label>
            <Input type="text" id="nf-name" name="name" placeholder="Enter Name"
                   defaultValue={item.name} disabled
                   maxLength="50"
                   autoComplete="name"/>

          </FormGroup>
          <FormGroup>
            <Label htmlFor="nf-email">Email</Label>
            <Input id="nf-email" name="email" type="email" placeholder="Enter Your Email.." required maxLength="50" disabled
                   autoComplete="address" defaultValue={item.email}/>

          </FormGroup>
          {/*<FormGroup>*/}
            {/*<Label htmlFor="nf-username">Username</Label>*/}
            {/*<Input id="nf-username" name="username" type="text" placeholder="Enter Username" maxLength="50" disabled*/}
                   {/*autoComplete="usernanme" defaultValue={item.username}/>*/}

          {/*</FormGroup>*/}
          {/*<FormGroup>*/}
            {/*<Label htmlFor="nf-password">Password</Label>*/}
            {/*<Input type="password" id="nf-password" name="password" defaultValue={item.password}*/}
                   {/*placeholder="Enter Password" onChange={this.password}*/}
                   {/*autoComplete=""/>*/}

          {/*</FormGroup>*/}
          <FormGroup>
            <Label htmlFor="nf-logo">Active</Label>
              <br></br>
              <AppSwitch className={'mx-1'}  id={'statuss'} name={'statuss'} variant={'pill'} color={'primary'}
                         value={'1'} checked={item.status}  onChange={this.status}
              />
            {/*<select name={"status"} className={"col-12 form-control"} required defaultValue={item.status}>*/}
              {/*<option value="">Select Status</option>*/}
              {/*<option value="1">Active</option>*/}
              {/*<option value="0">In Active</option>*/}
            {/*</select>*/}

          </FormGroup>
          <Button type="submit" disabled={!this.state.enable} size="sm" color="primary"><i
            className="fa fa-dot-circle-o"/> Submit</Button>
        </Form>
      )}
    </CardBody>;
    return (
      <div className="animated fadeIn">
        <Row>
          <Card style={{width: 80 + "%", marginLeft: 10 + '%'}}>
            <CardHeader>

              {success}
              {error}
              {validation}
              <Link to={{pathname: '/Users'}} className="pull-right btn btn-sm btn-primary"><i
                className="icon icon-arrow-left"/>{lang[73]}</Link>
              <strong>{lang[66]}</strong>
            </CardHeader>
            {content}
          </Card>
        </Row>
      </div>
    );
  }
}
