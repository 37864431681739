import './../../../index';
import React, {Component} from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Badge
} from 'reactstrap';
import axios from "axios";
import {Link} from 'react-router-dom';
import ReactTable from 'react-table';
import lang from "../../../Language/en-us";

class Tables extends Component {
  constructor(props) {
    super(props);
    this.getcategory();
    this.getSchools();
    this.state = {
      items: [],
      schools: [],
      loading: true,
      role_id: localStorage.getItem('role'),
    };
    this.school = this.school.bind(this);
  }

  school(e) {
    this.setState({
      school: e.target.value
    });
    const account = {
      id: localStorage.getItem('id'),
      school_id: e.target.value,
      token: localStorage.getItem('token'),
      role_id: localStorage.getItem('role'),
      filter: '1'
    };
    let uri = global.BASE_URL + '/ResultCategoryListForSchool';

    axios.post(uri, account).then((response) => {
      this.setState({
        items: response.data,
        loading: false,
      });
    }).catch(error => {
    });
  }

  getcategory() {
    const account = {
      id: localStorage.getItem('id'),
      school_id: localStorage.getItem('school_id'),
      token: localStorage.getItem('token'),
      role: localStorage.getItem('role')
    };
    let uri = global.BASE_URL + '/ResultCategoryListForSchool';

    axios.post(uri, account).then((response) => {
      console.log(response);
      this.setState({
        items: response.data,
        loading: false,
      });
    }).catch(error => {
    });
  }

  getSchools() {
    const account = {
      id: localStorage.getItem('id'),
      school_id: localStorage.getItem('school_id'),
      token: localStorage.getItem('token'),
      role: localStorage.getItem('role')
    };
    let uri = global.BASE_URL + '/getschoollist';

    axios.post(uri, account).then((response) => {
      this.setState({schools: response.data});
    }).catch(error => {
    });
  }

  render() {
    let {schools, items} = this.state;
    let content = this.state.loading ? <div className="loading"/> : <ReactTable
      data={items}
      columns={[
        {
          Header: "Name",
          accessor: 'name'
        },
        {
          Header: 'School Name',
          accessor: 'school_name'
        },
        {
          Header: 'Status',
          accessor: 'status',
          Cell: ({value}) => (value === '1' || value === 1 || value === "1" ?
            <Badge className="badge-success">Active</Badge> :
            <Badge className="badge-danger">In Active</Badge>),
          filterMethod: (filter, row) => {
            if (filter.value === "all") {
              return true;
            }
            if (filter.value === '1') {
              return row[filter.id] === '1';
            }
            return row[filter.id] === '0';
          },
          Filter: ({filter, onChange}) =>
            <select
              onChange={event => onChange(event.target.value)}
              style={{width: "100%"}}
              value={filter ? filter.value : "all"}
            >
              <option value="all">Show All</option>
              <option value='1'>Active</option>
              <option value='0'>In Active</option>
            </select>
        },
        {
          Header: 'Actions',
          Cell: row => (
            <Link to={{pathname: `/ResultCategory/edit/${row.original.id}`}} className={"btn btn-primary btn-sm"}><i
              className="fa fa-pencil"/></Link>)
        },
      ]}
      defaultPageSize={10}
      className="-striped -highlight -sm -responsive"
      showPagination={true}
      showPaginationTop={false}
      showPaginationBottom={true}
      pageSizeOptions={[5, 10, 20, 25, 50, 100]}
      filterable
    />;
    return (
      <div className="animated fadeIn">
        <Row>
          <Col>
            <Card>
              <CardHeader>
                <i className="fa fa-align-justify"/> Result Categories
                <Link to={{pathname: "/ResultCategory/Add"}}
                      className={"btn btn-info btn-sm pull-right"}><i
                  className={"icon-plus"}/> {lang[28]}</Link>
              </CardHeader>
              <CardBody>
                {(() => {
                  if (this.state.role_id === '1' || this.state.role_id === '2') {
                    return <Form>
                      <Row>
                        <FormGroup>
                          <Col md="12">
                            <Label htmlFor="selectSm"> </Label>
                          </Col>
                          <Col xs="12" md="12">
                            <Input type="select" name="clss" id="SelectLm"
                                   onChange={this.school}>
                              <option value={""}>Select School</option>
                              {schools.map(item =>
                                <option key={item.id}
                                        value={item.id}>{item.name}</option>
                              )};
                            </Input>
                          </Col>
                        </FormGroup>
                      </Row>
                    </Form>;
                  }
                })()}
                {content}

              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>

    );
  }
}

export default Tables;
