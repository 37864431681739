import React, {Component} from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
    Col,
    Alert
} from 'reactstrap';
import axios from 'axios';
import {Link} from 'react-router-dom';
import PNotify from 'pnotify/dist/es/PNotify';
import {AppSwitch} from "@coreui/react";
// import Progress from "reactstrap/src/Progress";
export default class EditStudent extends Component {
    constructor(props) {
        super(props);
        this.getClasses();
        this.getBatch();
        this.getSingleStudent();
        this.getSchools();
        this.toggle = this.toggle.bind(this);
        this.toggleFade = this.toggleFade.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            collapse: true,
            fadeIn: true,
            classes: [],
            batch: [],
            parentsList: [],
            getSingleStudentParents: [],
            items: [],
            schools: [],
            timeout: 300,
            role_id: localStorage.getItem('role'),
            enable: true,
            loading: true,
            loadings: false,
            name: '',
            address: '',
            logo: '',
            finger_count: 0,
            success: false,
            error: false,
            validation: false,
            file: null,
            edit_id: '',
        };
        this.name = this.name.bind(this);
        this.gender = this.gender.bind(this);
        this.school = this.school.bind(this);
        this.class = this.class.bind(this);
        this.batch = this.batch.bind(this);
        this.age = this.age.bind(this);
        this.picture = this.picture.bind(this);
        this.status = this.status.bind(this);
        this.roll = this.roll.bind(this);
        this.parent_name = this.parent_name.bind(this);
        this.parent_number = this.parent_number.bind(this);
        this.relation = this.relation.bind(this);
        this.parent_Email = this.parent_Email.bind(this);

        this.finger_button = this.finger_button.bind(this);
        localStorage.setItem('finger_print1','');
        localStorage.setItem('finger_print2','');
        localStorage.setItem('finger_print3','');
    }

    finger_button(e) {
        console.log(localStorage.getItem('finger_print1'));
        if(localStorage.getItem('finger_print1') !== '' && localStorage.getItem('finger_print1') !== 'undefined'){
            this.setState({
                finger_print_val :33,
                finger_count :1,
            });
        }
        if(localStorage.getItem('finger_print2') !== '' && localStorage.getItem('finger_print2') !== 'undefined'){
            this.setState({
                finger_print_val :66,
                finger_count :2,
            });
        }
        if(localStorage.getItem('finger_print3') !== '' && localStorage.getItem('finger_print3') !== 'undefined'){
            this.setState({
                finger_print_val :100,
                finger_count :3,
            });
        }
    }
    getClasses() {
        const account = {
            id: localStorage.getItem('id'),
            school_id: localStorage.getItem('school_id'),
            token: localStorage.getItem('token'),
            role_id: localStorage.getItem('role'),
        };
        let uri = global.BASE_URL + '/ClassListForSchool';
        axios.post(uri, account).then((response) => {
            this.setState({classes: response.data});
        }).catch(error => {
        });
    }

    getBatch() {
        const account = {
            id: localStorage.getItem('id'),
            school_id: localStorage.getItem('school_id'),
            token: localStorage.getItem('token'),
            role: localStorage.getItem('role'),
        };
        let uri = global.BASE_URL + '/BatchListForSchool';
        axios.post(uri, account).then((response) => {
            this.setState({batch: response.data});
        }).catch(error => {
        });
    }
    getSingleStudent() {
        const values = {
            id: this.props.match.params.id
        };
        let uri = global.BASE_URL + '/getsinglestudent';
        console.log(uri);
        axios.post(uri, values).then((response) => {
            if (response.status === 200) {
                this.setState({items: response.data});
            }
        }).catch(error => {
        });
        const valuesa = {
            id: this.props.match.params.id
        };
        let uria = global.BASE_URL + '/getSingleStudentParents';
        // console.log(uri);
        axios.post(uria, valuesa).then((response) => {
            if (response.status === 200) {
                this.setState({getSingleStudentParents: response.data});
            }
        }).catch(error => {
        });
    }

    getSchools() {
        const account = {
            id: localStorage.getItem('id'),
            school_id: localStorage.getItem('school_id'),
            token: localStorage.getItem('token'),
            role: localStorage.getItem('role')
        };
        let uri = global.BASE_URL + '/getschoollist';

        axios.post(uri, account).then((response) => {
            this.setState({schools: response.data, loading: false});
        }).catch(error => {
        });
    }

    toggle() {
        this.setState({collapse: !this.state.collapse});
    }

    toggleFade() {
        this.setState((prevState) => {
            return {fadeIn: !prevState}
        });
    }

    school(e) {
        this.setState({
            school: e.target.value
        })
    }

    name(e) {
        this.setState({
            name: e.target.value
        })
    }

    gender(e) {
        this.setState({
            gender: e.target.value
        })
    }

    class(e) {
        this.setState({
            class: e.target.value
        })
    }
    parent_Email(e) {
        this.setState({
            parent_Email: e.target.value
        })
    }

    batch(e) {
        this.setState({
            batches: e.target.value
        })
        const account = {
            id: localStorage.getItem('id'),
            school_id: this.state.school,
            token: localStorage.getItem('token'),
            batch: e.target.value,
            role: localStorage.getItem('role'),
            active: '1',
        };
        let uri = global.BASE_URL + '/ClassListByBatchForSchool';

        axios.post(uri, account).then((response) => {
            this.setState({classes: response.data});
        }).catch(error => {
            console.log(error);
        });
    }
    age(e) {
        this.setState({
            age: e.target.value
        })
    }

    roll(e) {
        this.setState({
            roll: e.target.value
        })
    }

    parent_name(e) {
        this.setState({
            parent_name: e.target.value
        })
    }

    parent_number(e) {
        this.setState({
            parent_contact: e.target.value
        })
    }

    relation(e) {
        this.setState({
            relation: e.target.value
        })
    }

    status(e) {
        var all_sms = document.getElementById('status').value;
        if(all_sms == '1' || all_sms === 1){
            document.getElementById('status').value = '0';
        }else{
            document.getElementById('status').value = '1';
        }
    }

    picture(e) {
        this.setState({picture: e.target.files[0]})
    }

    handleSubmit(e) {
        this.setState({enable: false, loadingss: true});
        e.preventDefault();
        let serialize = require('form-serialize');
        let form = document.querySelector('#student_form');
        let obj = serialize(form, {hash: true});

        let uri = global.BASE_URL + '/updatesinglestudent';
        axios.put(uri, obj ).then((response) => {
            this.setState({enable: true, loadingss: false});
            const formData = new FormData();
            formData.append('image', this.state.picture);
            formData.append('edit_id',this.props.match.params.id);
            let uris = global.BASE_URL + '/updatesinglestudentImage';
            axios.post(uris, formData ).then(response => {
              return response
            })
            PNotify.alert({
                text: "Student has been updated successfully!",
                type: 'success'
            });
            this.setState({loadingss: false});
        }).catch(error => {
            PNotify.alert({
                text: "Some thing went wrong try again latter!",
                type: 'error'
            });
            this.setState({enable: true, loadingss: false});
        });

    }

    render() {

        console.log(localStorage.getItem('finger_print1'));
        if(localStorage.getItem('finger_print1') !== '' && localStorage.getItem('finger_print1') !== 'undefined'){
            // this.setState({
            //     finger_print_val :33,
            //     finger_count :1,
            // });
            let finger_print_val = 33;
            let finger_count = 1;
        }
        if(localStorage.getItem('finger_print2') !== '' && localStorage.getItem('finger_print2') !== 'undefined'){
            let finger_print_val = 66;
            let finger_count = 2;
        }
        if(localStorage.getItem('finger_print3') !== '' && localStorage.getItem('finger_print3') !== 'undefined'){
            let finger_print_val = 100;
            let finger_count = 3;
        }
        let success = this.state.success ? <div><Alert color="primary">
            Student Data Successfully Updated
        </Alert></div> : <div/>;
        let error = this.state.error ? <div><Alert color="danger">
            Some thing went wrong! try again latter
        </Alert></div> : <div/>;
        let validation = this.state.validation ? <div><Alert color="warning">
            Fill all the fields
        </Alert></div> : <div/>;

        let loader = this.state.loadingss ? <div className="loading"/> : <div/>
        let classes = this.state.classes;
        let batch = this.state.batch;
        let schools = this.state.schools;
        let finger_count = this.state.finger_count;
        let items = this.state.items;
        let getSingleStudentParents = this.state.getSingleStudentParents;
        let content = this.state.loading ? <div className="loading"/> : <CardBody>
            {items.map(item =>
                <Form onSubmit={this.handleSubmit} id="student_form" key={"form1"}  encType="multipart/form-data">
                    <Input defaultValue={item.id} name={"id"} hidden/>
                    <Input defaultValue={item.parent_id} name={"parent_id"} hidden/>
                    <input type={"hidden"} defaultValue={localStorage.getItem('token')} name={"token"}/>
                    <input type={"hidden"} defaultValue={localStorage.getItem('token')} name={"remember_token"}/>
                    <input type={"hidden"} defaultValue={localStorage.getItem('id')} name={"user_id"}/>
                    <input type={"hidden"} defaultValue={item.status} name={"status"} id={'status'}/>
                    {(() => {
                        switch (this.state.role_id) {
                            case '2':
                                return <FormGroup>
                                    <Label htmlFor="nf-contact">School</Label>
                                    <select name={"school_id"} className="form-control col-12"
                                            onChange={this.school}
                                            defaultValue={item.school_id} required>

                                        <option value={""}>Select School</option>
                                        {schools.map(item =>
                                            <option key={item.id} value={item.id}>{item.name}</option>
                                        )};
                                    </select>
                                </FormGroup>;
                            case '1':
                                return <FormGroup>
                                    <Label htmlFor="nf-contact">School</Label>
                                    <select name={"school_id"} className="form-control col-12"
                                            onChange={this.school}
                                            defaultValue={item.school_id} required>

                                        <option value={""}>Select School</option>
                                        {schools.map(item =>
                                            <option key={item.id} value={item.id}>{item.name}</option>
                                        )};
                                    </select>
                                </FormGroup>;
                            case '3':
                                return <input type="hidden"
                                              defaultValue={localStorage.getItem('school_id')}
                                              name="school_id" onChange={this.school} required/>;
                        }
                    })()}
                    <FormGroup>
                        <Label htmlFor="nf-name">Student Name</Label>
                        <Input type="text" id="nf-name" name="student_name" placeholder="Student Name"
                               defaultValue={item.name} autoComplete="student_name" onChange={this.name} required/>
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="nf-gender">Gender</Label>
                        <select name={"gender"} className='form-control col-12' id="nf-gender"
                                defaultValue={item.gender} onChange={this.gender} required>
                            <option value={""}>Select Gender</option>
                            <option value={"Male"}>Male</option>
                            <option value={"Female"}>Female</option>
                        </select>
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="nf-image">Batch</Label>
                        <select name={"batch"} className={"form-control col-12"} onChange={this.batch} required>
                            <option value={item.batch_id}>{item.batch_name}</option>
                            {batch.map(item =>
                                <option key={item.id} value={item.id}>{item.name}</option>
                            )}
                        </select>
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="nf-contact">Class</Label>
                        <select name={"class"} className="form-control col-12" onChange={this.class} required>
                            <option value={item.class_id}>{item.class_name}</option>
                            {classes.map(item =>
                                <option key={item.id} value={item.id}>{item.name}</option>
                            )};
                        </select>
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="nf-image">Roll No</Label>
                        <Input type={"text"} name={"roll"} defaultValue={item.roll_number} maxLength="10" onChange={this.roll} required/>
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="nf-image">Student Id</Label>
                        <Input type={"text"} name={"roll"} defaultValue={item.studentID} maxLength="14" disabled  required/>
                    </FormGroup>
                    {getSingleStudentParents.map(item =>
                        <Row>
                            <Col xs="3">
                                <FormGroup>
                                    <input type={"hidden"} id={"guardian_id"} name={"guardian_id[]"} defaultValue={item.id}/>
                                    <Label htmlFor="nf-parentName">Guardian Name</Label>
                                    <Input type={"text"} name={"parent_name[]"}
                                           defaultValue={item.name} onChange={this.parent_name} required/>
                                </FormGroup>
                            </Col>  <Col xs="2">
                                <FormGroup>
                                    <Label htmlFor="nf-parentName">Guardian CNIC</Label>
                                    <Input type={"text"} name={"parent_cnic[]"} maxLength={"13"} minLength={"13"}
                                           defaultValue={item.cnic} required/>
                                </FormGroup>
                            </Col>
                            <Col xs="2">
                                <FormGroup>
                                    <Label htmlFor="nf-parentName">Guardian Number</Label>
                                    <Input type={"number"} name={"parent_contact[]"} maxLength="20"
                                           defaultValue={item.contact_number} onChange={this.parent_number} required/>
                                </FormGroup>
                            </Col>
                            <Col xs="3">
                                <FormGroup>
                                    <Label htmlFor="nf-parentName">Guardian Email</Label>
                                    <Input type={"text"} name={"gaurdian_email[]"} maxLength="50"
                                           defaultValue={item.email} onChange={this.parent_Email} required/>
                                </FormGroup>
                            </Col>
                            <Col xs="2">
                                <FormGroup>
                                    <Label htmlFor="nf-parentName">Relation</Label>
                                    <select className="form-control col-12"
                                            defaultValue={item.relation} name={'parent_relation[]'}
                                            onChange={this.relation} required>
                                        <option value="">Select Relation</option>
                                        <option value={"father"}>Father</option>
                                        <option value={"Mother"}>Mother</option>
                                        <option value={"Sibling"}>Sibling</option>
                                        <option value={"Uncle/Aunt"}>Uncle/Aunt</option>
                                        <option value={"Others"}>Others</option>
                                    </select>
                                </FormGroup>
                            </Col>
                        </Row>
                    )}

                    <FormGroup>
                        <Label htmlFor="nf-image">Picture</Label>
                        <Input type="file" id="nf-image" name="image" accept="image/*" onChange={this.picture}/>
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="nf-status">Active</Label>
                        <br></br>
                        <AppSwitch className={'mx-2'}  id={'statuss'} name={'statuss'} variant={'pill'} color={'primary'}
                                   value={'1'} checked={item.status}
                                   onChange={this.status}
                        />
                        {/*<select name={"status"} className={"col-12 form-control"} defaultValue={item.status}*/}
                                {/*onChange={this.status} required>*/}

                            {/*<option value="">Select Status</option>*/}
                            {/*<option value="1">Active</option>*/}
                            {/*<option value="0">In Active</option>*/}
                        {/*</select>*/}
                    </FormGroup>

                    {/*<div className="form-group">*/}
                        {/*<div className="col-md-10">*/}
                            {/*<input type="hidden" id="fingerprintCount" defaultValue={finger_count}/>*/}
                            {/*<Button type="button" value="1" onClick={this.finger_button}*/}
                                    {/*id="btnfinger" className="btn btn-success">Capture Finger Print*/}
                            {/*</Button>*/}
                        {/*</div>*/}
                    {/*</div>*/}
                    {/*<Row>*/}
                        {/*<Col xs="3" />*/}
                        {/*<Col xs="6">*/}
                            {/*<div className="progress-group">*/}
                                {/*<div className="progress-group-header">*/}
                                    {/*<i style={{fontSize: "20px", marginRight: "10px"}}*/}
                                       {/*className="fas fa-fingerprint"/>*/}
                                    {/*<span className="title">Add 3 times</span>*/}
                                    {/*<span className="ml-auto font-weight-bold"><span*/}
                                        {/*className="text-muted small">({this.state.finger_print_val}%)</span></span>*/}
                                {/*</div>*/}
                                {/*<div className="progress-group-bars">*/}
                                    {/*<Progress className="progress-xs" color="success" value={this.state.finger_print_val}/>*/}
                                {/*</div>*/}
                            {/*</div>*/}
                        {/*</Col>*/}
                    {/*</Row>*/}
                    <br/>
                    {/*<div className="form-group">*/}
                        {/*<label className="control-label col-md-2"/>*/}
                        {/*<div className="col-md-10">*/}
                            {/*<img height="200" id="imgfinger"*/}
                                 {/*src={'http://localhost:8080/finger/download.png'} alt={"Yout Image Here"}/>*/}
                        {/*</div>*/}
                    {/*</div>*/}
                    {loader}
                    <Button type="submit" disabled={!this.state.enable} size="sm" color="primary"><i
                        className="fa fa-dot-circle-o"/> Submit</Button>
                </Form>
            )}
        </CardBody>;
        return (
            <div className="animated fadeIn">
                <Row>
                    <Card style={{width: 80 + "%", marginLeft: 10 + '%'}}>
                        <CardHeader>
                            {success}
                            {error}
                            {validation}
                            <Link to={{pathname: '/Students'}} className={"pull-right btn btn-sm btn-primary"}><i className="icon icon-arrow-left"/>&nbsp;Go back</Link>
                            <strong>Edit</strong> Student
                        </CardHeader>
                        {content}
                    </Card>
                </Row>
            </div>
        );
    }
}
