import React, {Component} from 'react';
import {Line} from 'react-chartjs-2';
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
  Table,
} from 'reactstrap';
import {getStyle} from '@coreui/coreui/dist/js/coreui-utilities'

import axios from "axios/index";

// Main Chart

//Random Numbers
function random(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

var elements = 30;
var present = [];
var absents = [];
var data3 = [];

for (var i = 0; i <= elements; i++) {
  present.push(random(50, 200));
  absents.push(random(80, 100));
  data3.push(65);
}


// console.log("random-"+random(50, 200));

// const mainChart = {
//   labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'],
//   datasets: [
//     {
//       label: 'My First dataset',
//       backgroundColor: hexToRgba(brandInfo, 10),
//       borderColor: brandInfo,
//       pointHoverBackgroundColor: '#fff',
//       borderWidth: 2,
//       data: present,
//     },
//     {
//       label: 'My Second dataset',
//       backgroundColor: 'transparent',
//       borderColor: brandSuccess,
//       pointHoverBackgroundColor: '#fff',
//       borderWidth: 2,
//       data: absents,
//     },
//     // {
//     //     label: 'My Third dataset',
//     //     backgroundColor: 'transparent',
//     //     borderColor: brandDanger,
//     //     pointHoverBackgroundColor: '#fff',
//     //     borderWidth: 1,
//     //     borderDash: [8, 5],
//     //     data: data3,
//     // },
//   ],
// };

// const mainChartOpts = {
//   tooltips: {
//     enabled: false,
//     custom: CustomTooltips,
//     intersect: true,
//     mode: 'index',
//     position: 'nearest',
//     callbacks: {
//       labelColor: function (tooltipItem, chart) {
//         return {backgroundColor: chart.data.datasets[tooltipItem.datasetIndex].borderColor}
//       }
//     }
//   },
//   maintainAspectRatio: false,
//   legend: {
//     display: false,
//   },
//   scales: {
//     xAxes: [
//       {
//         gridLines: {
//           drawOnChartArea: false,
//         },
//       }],
//     yAxes: [
//       {
//         ticks: {
//           beginAtZero: true,
//           maxTicksLimit: 5,
//           stepSize: Math.ceil(250 / 5),
//           max: 250,
//         },
//       }],
//   },
//   elements: {
//     point: {
//       radius: 0,
//       hitRadius: 10,
//       hoverRadius: 4,
//       hoverBorderWidth: 3,
//     },
//   },
// };

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.getCounts();
    this.getclasses();
    this.getbatches();
    this.attendance();
    this.getstaff();
    this.toggle = this.toggle.bind(this);
    this.onRadioBtnClick = this.onRadioBtnClick.bind(this);
    const monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    var today = new Date(),
      month = (monthNames[today.getMonth()]),
      year = today.getFullYear();

    this.state = {
      month: month
    };

    this.state = {
      year: year
    };
    this.state = {
      dropdownOpen: false,
      radioSelected: 2,
      counts: [],
      classes: [],
      batch: [],
      staff: [],
      mChart: [],
      mChartOpt: [],
      month: month,
      year: year,
      loading: true,
    };
  }

  attendance() {
    localStorage.setItem('impersonation_id', this.props.match.params.id);
    localStorage.setItem('role', '3');
    localStorage.setItem('school_id', this.props.match.params.id);
    this.props.history.push("/dashboard");
    window.location.reload();
    const values = {
      user_role: localStorage.getItem('role'),
      user_id: localStorage.getItem('id'),
      user_school_id: localStorage.getItem('school_id'),
      id: this.props.match.params.id,
    };
    let uri = global.BASE_URL + '/getAttendenceGraphSingleSchool';
    axios.post(uri, values).then((response) => {
      if (response.status === 200) {
        console.log(response.data.mchartOpt);
        this.setState({mChart: response.data.mchart, mChartOpt: response.data.mchartOpt});
      }
    }).catch(error => {
    });
  }

  getCounts() {
    const values = {
      id: this.props.match.params.id,
      user_role: localStorage.getItem('role'),
      user_id: localStorage.getItem('id'),
      token: localStorage.getItem('token'),
      user_school_id: localStorage.getItem('school_id')
    };
    let uri = global.BASE_URL + '/getSchoolDetailCount';
    console.log(uri);
    axios.post(uri, values).then((response) => {
      // browserHistory.push('localhost:8080/reactProject/public');
      if (response.status === 200) {
        console.log(response);
        this.setState({counts: response.data});
      }
      // console.log(response.data);
    }).catch(error => {
    });
  }

  getclasses() {
    const account = {
      id: this.props.match.params.id,
      user_id: localStorage.getItem('id'),
      user_school_id: localStorage.getItem('school_id'),
      token: localStorage.getItem('token'),
      user_token: localStorage.getItem('token')
    };
    // const {history} = this.props;
    let uri = global.BASE_URL + '/ClassListForSchoolDetail';

    axios.post(uri, account).then((response) => {
      this.setState({classes: response.data});
    }).catch(error => {
      // alert('Email or Password does not match');
    });
  }

  getbatches() {
    const account = {
      id: this.props.match.params.id,
      user_id: localStorage.getItem('id'),
      token: localStorage.getItem('token'),
      user_school_id: localStorage.getItem('school_id'),
      user_token: localStorage.getItem('token')
    };
    // const {history} = this.props;
    let uri = global.BASE_URL + '/BatchListForSchoolDetail';

    axios.post(uri, account).then((response) => {
      this.setState({batch: response.data});
    }).catch(error => {
      // alert('Email or Password does not match');
    });
  }

  getstaff() {
    const account = {
      id: this.props.match.params.id,
      user_id: localStorage.getItem('id'),
      token: localStorage.getItem('token'),
      user_school_id: localStorage.getItem('school_id'),
      user_token: localStorage.getItem('token')
    };
    // const {history} = this.props;
    let uri = global.BASE_URL + '/StaffListForSchoolDetail';

    axios.post(uri, account).then((response) => {
      this.setState({staff: response.data, loading: false});
    }).catch(error => {
      // alert('Email or Password does not match');
    });
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }

  onRadioBtnClick(radioSelected) {
    this.setState({
      radioSelected: radioSelected,
    });
  }

  render() {
    let counts = this.state.counts;
    let classes = this.state.classes;
    let batch = this.state.batch;
    let staff = this.state.staff;
    let mChart = this.state.mChart;
    let mChartOpt = this.state.mChartOpt;
    let content = this.state.loading ? <div className="loading"/> : <div>
      <div className="text-value">{counts.students}</div>
      <div>Total Students</div>
    </div>;
    let content1 = this.state.loading ? <div className="loading"/> : <div>
      <div className="text-value">{counts.staff}</div>
      <div>Total Staff</div>
    </div>;
    let content2 = this.state.loading ? <div className="loading"/> : <div>
      <div className="text-value">{counts.batch}</div>
      <div>Total Batches</div>
    </div>;
    let content3 = this.state.loading ? <div className="loading"/> : <div>
      <div className="text-value">{counts.class}</div>
      <div>Total Classes</div>
    </div>;
    let content4 = this.state.loading ? <div className="loading"/> : <tbody>


    {classes.map(classe =>
      <tr key={classe.id}>
        <td>{classe.name}</td>
        <td>{classe.teacher}</td>
        <td>{classe.batch}</td>
      </tr>
    )}

    </tbody>;
    let content5 = this.state.loading ? <div className="loading"/> : <tbody>


    {batch.map(batc =>
      <tr key={batc.id}>
        <td>{batc.name}</td>
        <td>{batc.year}</td>
      </tr>
    )}

    </tbody>;
    let content6 = this.state.loading ? <div className="loading"/> : <tbody>


    {staff.map(staf =>
      <tr key={staf.id}>
        <td>{staf.name}</td>
        <td>{staf.name}</td>
      </tr>
    )}

    </tbody>;
    return (
      <div className="animated fadeIn">
        <Row>
          <Col xs="12" sm="6" lg="3">
            <Card className="text-white bg-success">
              <CardBody className="pb-0">
                {content1}
              </CardBody>
              <div className="chart-wrapper mx-3" style={{height: '70px'}}>
                {/*<Line data={cardChartData2} options={cardChartOpts2} height={70} />*/}
              </div>
            </Card>
          </Col>

          <Col xs="12" sm="6" lg="3">
            <Card className="text-white bg-primary">
              <CardBody className="pb-0">
                {content}
              </CardBody>
              <div className="chart-wrapper mx-3" style={{height: '70px'}}>
                {/*<Line data={cardChartData2} options={cardChartOpts2} height={70} />*/}
              </div>
            </Card>
          </Col>

          <Col xs="12" sm="6" lg="3">
            <Card className="text-white bg-warning">
              <CardBody className="pb-0">
                {content2}
              </CardBody>
              <div className="chart-wrapper mx-3" style={{height: '70px'}}>
                {/*<Line data={cardChartData2} options={cardChartOpts2} height={70} />*/}
              </div>
            </Card>
          </Col>

          <Col xs="12" sm="6" lg="3">
            <Card className="text-white bg-danger">
              <CardBody className="pb-0">
                {content3}
              </CardBody>
              <div className="chart-wrapper mx-3" style={{height: '70px'}}>
                {/*<Line data={cardChartData2} options={cardChartOpts2} height={70} />*/}
              </div>
            </Card>
          </Col>
        </Row>


        <Row>
          <Col xs="12" sm="6" lg="6">
            <Card>

              <CardHeader>
                <i className="fa fa-align-justify"/> Classes
              </CardHeader>

              <CardBody>
                <Table hover bordered striped responsive size="sm" key={"school_table"}>
                  <thead>
                  <tr>
                    <th>Class Name</th>
                    <th>Teacher</th>
                    <th>Batch</th>
                  </tr>
                  </thead>
                  {content4}
                </Table>

              </CardBody>
            </Card>
          </Col>

          <Col xs="12" sm="6" lg="6">
            <Card>

              <CardHeader>
                <i className="fa fa-align-justify"/> Batches
              </CardHeader>

              <CardBody>
                <Table hover bordered striped responsive size="sm" key={"school_table"}>
                  <thead>
                  <tr>
                    <th>Batch Name</th>
                    <th>Year</th>
                  </tr>
                  </thead>
                  {content5}
                </Table>

              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col xs="12" sm="12" lg="12">
            <Card>

              <CardHeader>
                <i className="fa fa-align-justify"/> Staff
              </CardHeader>

              <CardBody>
                <Table hover bordered striped responsive size="sm" key={"school_table"}>
                  <thead>
                  <tr>
                    <th>sr#</th>
                    <th>staff</th>
                  </tr>
                  </thead>
                  {content6}
                </Table>

              </CardBody>
            </Card>
          </Col>


        </Row>


        <Row>
          <Col>
            <Card>
              <CardBody>
                <Row>
                  <Col sm="5">
                    <CardTitle className="mb-0">Attandance</CardTitle>
                    <div className="small text-muted">{this.state.month} {this.state.year}</div>
                  </Col>
                  {/*<Col sm="7" className="d-none d-sm-inline-block">*/}
                  {/*<Button color="primary" className="float-right"><i*/}
                  {/*className="icon-cloud-download"/></Button>*/}
                  {/*<ButtonToolbar className="float-right" aria-label="Toolbar with button groups">*/}
                  {/*<ButtonGroup className="mr-3" aria-label="First group">*/}
                  {/*<Button color="outline-secondary"*/}
                  {/*onClick={() => this.onRadioBtnClick(1)}*/}
                  {/*active={this.state.radioSelected === 1}>Day</Button>*/}
                  {/*<Button color="outline-secondary"*/}
                  {/*onClick={() => this.onRadioBtnClick(2)}*/}
                  {/*active={this.state.radioSelected === 2}>Month</Button>*/}
                  {/*<Button color="outline-secondary"*/}
                  {/*onClick={() => this.onRadioBtnClick(3)}*/}
                  {/*active={this.state.radioSelected === 3}>Year</Button>*/}
                  {/*</ButtonGroup>*/}
                  {/*</ButtonToolbar>*/}
                  {/*</Col>*/}
                </Row>
                <div className="chart-wrapper" style={{marginTop: 40 + 'px'}}>
                  <Line data={mChart} options={mChartOpt} height={100}/>
                </div>
              </CardBody>

            </Card>
          </Col>
        </Row>


      </div>
    );
  }
}

export default Dashboard;
