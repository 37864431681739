import {
    Component
} from "react";
import lang from './Language/en-us';
import axios from "axios";

// import lang from './Language/fr-ca';
class navigation extends Component {

    constructor(props) {
        super(props);


        this.items = [
            {
                name: lang[0],
                db_name: 'Dashboard',
                url: '/dashboard',
                icon: 'icon-speedometer',
            },
            {
                name: lang[102],
                db_name: 'Admission',
                url: '/students/create',
                icon: 'icon-speedometer',
            },
            {
                name: lang[1],
                db_name: 'Schools',
                url: '/Schools',

                icon: 'fa fa-university',
            },
            {
                name: 'Definition',
                db_name: 'Definition',
                url: '/definition',
                icon: 'fa fa-graduation-cap',
                children: [{
                    name: lang[2],
                    db_name: 'Students',
                    url: '/Students',
                    icon: 'fa fa-address-card',
                },
                    {
                        name: lang[3],
                        db_name: 'Classes',
                        url: '/Classes',
                        class: "classes",
                        icon: 'fa fa-desktop',
                    },
                    {
                        name: lang[5],
                        id: 'subjects_tour',
                        db_name: 'Subjects',
                        url: '/Subjects',
                        class: 'subject-list',
                        icon: 'fa fa-book',
                    },
                    {
                        name: lang[4],
                        db_name: 'Year Of Entry',
                        url: '/Batches',
                        class: 'year-of-entry',
                        icon: 'icon-layers',
                    },
                    {
                        name: lang[15],
                        db_name: 'Staff',
                        url: '/Staff',
                        class: 'staff-members',
                        icon: 'fa fa-users',
                    },
                    {
                        name: lang[25],
                        db_name: 'TimeTable',
                        class: 'timetable',
                        url: '/TimeTable',
                        icon: 'fa fa-calendar',
                    },
                    {
                        name: lang[99],
                        db_name: 'TeacherTimeTable',
                        class: 'teachertimetable',
                        url: '/TeacherTimeTable',
                        icon: 'fa fa-calendar',
                    },
                    {
                        name: lang[94],
                        db_name: 'Fee Components',
                        url: '/FeeComponent',
                        icon: 'fa fa-gear',
                    }, {
                        name: lang[10],
                        db_name: 'Fee Structure',
                        url: '/FeeStructure',
                        icon: 'fa fa-gear',
                    },
                    {
                        name: lang[7],
                        db_name: 'Result Category',
                        url: '/ResultCategory',
                        class: 'result-category',
                        icon: 'fa fa-graduation-cap',
                    },
                ],
            }, {
                name: lang[103],
                db_name: 'Attendance',
                url: '/Attendance',
                icon: 'fa fa-graduation-cap',
                class: 'Attendance',
                children: [
                    {
                        name: lang[17],
                        db_name: 'Attendance',
                        url: '/Attandance',
                        class: 'student-attendance',
                        icon: 'fa fa-check',
                    },
                    {
                        name: lang[18],
                        db_name: 'Staff Attendance',
                        url: '/StaffAttandance',
                        class: 'staff-attendance',
                        icon: 'fa fa-check',
                    }
                ]
            },
            {
                name: lang[9],
                db_name: 'Fee',
                url: '/Fee',
                class: 'student-fee',
                icon: 'fa fa-money',
                children: [
                    {
                        name: lang[12],
                        db_name: 'Voucher List',
                        url: '/FeeVoucherList',
                        class: 'fee-voucher',
                        icon: 'fa fa-gear',
                    },

                    {
                        name: lang[13],
                        db_name: 'Voucher List Detail',
                        url: '/FeeVoucherDetailList',
                        icon: 'fa fa-gear',
                    },
                    {
                        name: lang[11],
                        db_name: 'Student Fee',
                        url: '/StudentFee',
                        class: 'student-fee',
                        icon: 'fa fa-gear',
                    },
                    {
                        name: lang[14],
                        db_name: 'Fee Defaulter',
                        url: '/FeeDefaulter',
                        class: 'fee-defaulter',
                        icon: 'fa fa-gear',
                    },
                    {
                        name: lang[104],
                        db_name: 'Receive Payment',
                        url: '/receivePayment',
                        icon: 'fa fa-money',
                    }
                ],
            },
            {
                name: lang[6],
                db_name: 'Upload Result',
                url: '/UpdateResult',
                class: 'result-category',
                icon: 'fa fa-graduation-cap',
            },
            {
                name: lang[84],
                db_name: 'HomeWork',
                url: '/HomeWork',
                class: 'homework',
                icon: 'fa fa-tasks',
            },
            {
                name: lang[22],
                db_name: 'Library List',
                url: '/Library',
                class: 'library',
                icon: 'fa fa-folder-open',
            },
            {
                name: lang[26],
                db_name: 'Events',
                url: '/Events',
                class: 'events',
                icon: 'fa fa-calendar-check-o',
            },

            {
                name: lang[19],
                db_name: 'Settings',
                url: '/Settings',
                icon: 'fa fa-gear',
                class: 'settings-main',
                children: [
                    {
                        name: lang[20],
                        db_name: 'School Settings',
                        url: '/Settings',
                        class: 'school-settings',
                        icon: 'fa fa-gear',
                    },
                    {
                        name: lang[77],
                        db_name: 'Email Template',
                        url: '/EmailTemplate',
                        class: 'email-template',
                        icon: 'fa fa-gear',
                    },
                    {
                        name: lang[78],
                        db_name: 'SMS Temaplate',
                        url: '/SMSTemplate',
                        class: 'sms-template',
                        icon: 'fa fa-gear',
                    },
                    {
                        name: lang[16],
                        db_name: 'Users',
                        url: '/users',
                        class: 'user',
                        icon: 'fa fa-users',
                    },
                ],
            },
            {
                name: lang[105],
                db_name: 'Roles',
                url: '/roles',
                class: 'user',
                icon: 'fa fa-users',
            },

            {
                name: lang[106],
                db_name: 'Reporting',
                url: '/reporting',
                icon: 'fa fa-list',
                class: 'reporting-main',
                children: [
                    {
                        name: lang[107],
                        db_name: 'Fee Voucher Reporting',
                        url: '/feeVoucherReporting',
                        class: 'school-settings',
                        icon: 'fa fa-money',
                    },
                    {
                        name: lang[108],
                        db_name: 'Fee Voucher Student Wise',
                        url: '/FeeVoucherStudentWise',
                        class: 'school-settings',
                        icon: 'fa fa-money',
                    },
                    {
                        name: lang[109],
                        db_name: 'Fee Defaulters Reporting',
                        url: '/ReportingFeeDefaulters',
                        class: 'school-settings',
                        icon: 'fa fa-user',
                    },
                    {
                        name: lang[110],
                        db_name: 'Students Reporting',
                        class: 'school-settings',
                        icon: 'fa fa-money',
                        children: [
                            {
                                name: lang[113],
                                db_name: 'Student List',
                                url: '/ReportingStudentList',
                                class: 'school-settings',
                                icon: 'fa fa-user',
                            }, {
                                name: lang[114],
                                db_name: 'Student Detail',
                                url: '/ReportingStudentDetail',
                                class: 'school-settings',
                                icon: 'fa fa-user',
                            },

                        ]
                    }
                ]
            },

            {
                name: lang[27],
                db_name: 'Logs',
                url: '/logs',
                icon: 'fa fa-files-o',
            },


        ];

        let a = localStorage.getItem('permission');
        if (localStorage.getItem('permission')) {
            let perm = localStorage.getItem('permission').split(',');
            var da = [];
            let coun = 0;
            this.items.forEach(function (item) {
                perm.forEach(function (item_static) {
                    if (item.db_name === item_static) {
                        da[coun] = item;
                    } else {
                    }
                });
                coun++;
            });
            this.items = da;
            console.log(da);
        } else {
            this.items = [{
                name: lang[0],
                db_name: 'Dashboard',
                url: '/dashboard',
                icon: 'icon-speedometer',
            },
            ]
        }

    }
}

export default new navigation()
