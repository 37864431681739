import React, {Component} from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Col, Alert
} from 'reactstrap';
import axios from 'axios';
import {Link} from 'react-router-dom';
import PNotify from 'pnotify/dist/es/PNotify';
import lang from './../../../../Language/en-us'
import { AppSwitch } from '@coreui/react'
// import lang from './../../../../Language/fr-ca'

export default class EditSchool extends Component {
  constructor(props) {
    super(props);
    this.getSingleSchool();
    this.toggle = this.toggle.bind(this);
    this.toggleFade = this.toggleFade.bind(this);
    this.allow_sms = this.allow_sms.bind(this);
    this.allow_library = this.allow_library.bind(this);
    this.allow_create_branch = this.allow_create_branch.bind(this);
    this.status = this.status.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      collapse: true,
      fadeIn: true,
      loadingss: false,
      timeout: 300,
      items: [],
        allow_sms: '',
      name: '',
      address: '',
      logo: '',
      enable: true,
      loading: true,
      success: false,
      error: false,
      validation: false,
      role_id: localStorage.getItem('role'),
    };
    this.logo = this.logo.bind(this);

  }

  toggle() {
    this.setState({collapse: !this.state.collapse});
  }

  toggleFade() {
    this.setState((prevState) => {
      return {fadeIn: !prevState}
    });
  }

  allow_sms(e) {
     var all_sms = document.getElementById('all_sms').value;
     if(all_sms == '1' || all_sms === 1){
         var all_sms = document.getElementById('all_sms').value = '0';
     }else{
         var all_sms = document.getElementById('all_sms').value = '1';
     }
  }

  allow_library(e) {
      var all_lib = document.getElementById('all_library').value;
      if(all_lib == '1' || all_lib === 1){
           document.getElementById('all_library').value = '0';
      }else{
           document.getElementById('all_library').value = '1';
      }
  }

  allow_create_branch(e) {
      var all_create_bra = document.getElementById('all_create_branch').value;
      if(all_create_bra == '1' || all_create_bra === 1){
          document.getElementById('all_create_branch').value = '0';
      }else{
           document.getElementById('all_create_branch').value = '1';
      }
  }
    status(e) {
      var all_sms = document.getElementById('all_status').value;
      if(all_sms == '1' || all_sms === 1){
          var all_sms = document.getElementById('all_status').value = '0';
      }else{
          var all_sms = document.getElementById('all_status').value = '1';
      }
  }

  getSingleSchool() {
    const values = {
      id: this.props.match.params.id,
      user_role: localStorage.getItem('role'),
      user_id: localStorage.getItem('id'),
      user_school_id: localStorage.getItem('school_id')
    };
    let uri = global.BASE_URL + '/singleSchool';
    console.log(uri);
    axios.post(uri, values).then((response) => {
      if (response.status === 200) {
        console.log(response);
        this.setState({items: response.data, loading: false});
      }
    }).catch(error => {
    });
  }

  logo(e) {
    this.setState({logo: e.target.files[0]})
  }

  handleSubmit(e) {

    this.setState({loadingss: true});
    this.setState({enable: false});
    e.preventDefault();
    var serialize = require('form-serialize');
    var form = document.querySelector('#school_form');

    var obj = serialize(form, {hash: true});
    let uri = global.BASE_URL + '/updatesingleschool';
    axios.put(uri, obj).then((response) => {
      const formData = new FormData();
      formData.append('image', this.state.logo);
      formData.append('edit_id', this.props.match.params.id);
      let uris = global.BASE_URL + '/updatesingleschoolImage';
      axios.post(uris, formData).then((response) => {
          this.setState({loadingss: false});
          this.setState({enable: true});
        // alert('Student Updated Successfully');
        // this.setState({loadingss: false});
        // this.props.history.push('/Students')
      }).catch(error => {
        // alert('Some thing went wrong try again latter');
        // this.setState({enable: true, loadingss: false});
      });
      // alert('Data Update Successfully');
      PNotify.alert({
        text: "School has been updated successfully!",
        type: 'success'
      });

      // this.props.history.push("/Schools");
    }).catch(error => {
      // alert('Some thing went wrong try again latter');
      this.setState({loadingss: false});
      PNotify.alert({
        text: "Some thing went wrong try again latter!",
        type: 'error'
      });
      this.setState({enable: true});
    });
  }

  render() {

    let loader = this.state.loadingss ? <div className="loading"/> : <div></div>
    let items = this.state.items;

    let success = this.state.success ? <div><Alert color="primary">
      School Updated Successfully
    </Alert></div> : <div></div>;
    let error = this.state.error ? <div><Alert color="danger">
      Some thing went wrong! try again latter
    </Alert></div> : <div></div>;
    let validation = this.state.validation ? <div><Alert color="warning">
      Fill all the fields
    </Alert></div> : <div></div>;


    let content = this.state.loading ? <div className="loading"/> : <CardBody>
      {items.map(item =>
        <Form onSubmit={this.handleSubmit} id="school_form" key={"form1"}>
          <input type={"hidden"} defaultValue={item.id} name={"id"}/>
          <input type={"hidden"} defaultValue={item.person_id} name={"person_id"}/>
          <input type={"hidden"} defaultValue={localStorage.getItem('token')} name={"token"}/>
          <input type={"hidden"} defaultValue={localStorage.getItem('token')} name={"remember_token"}/>
          <input type={"hidden"} defaultValue={localStorage.getItem('id')} name={"user_id"}/>
          <input type={"hidden"} defaultValue={localStorage.getItem('role')} name={"role"}/>
          <input type={"hidden"} defaultValue={item.allow_sms} name={"all_sms"} id={'all_sms'}/>
          <input type={"hidden"} defaultValue={item.allow_library} name={"all_library"} id={'all_library'}/>
          <input type={"hidden"} defaultValue={item.is_create_branch} name={"all_create_branch"} id={'all_create_branch'}/>
          <input type={"hidden"} defaultValue={item.status} name={"all_status"} id={'all_status'}/>
          <FormGroup>
            <Label htmlFor="nf-name">Name</Label>
            <Input type="text" id="nf-name" name="name" placeholder="Enter Name" defaultValue={item.name}
                   autoComplete="name"/>

          </FormGroup>
          <FormGroup>
            <Label htmlFor="nf-address">Address</Label>
            <textarea id="nf-address" name="address" placeholder="Enter Address.." autoComplete="address"
                      style={{width: 100 + '%'}} defaultValue={item.address}/>

          </FormGroup>
          <FormGroup>
            <Label htmlFor="nf-contact">Contact Name</Label>
            <Input id="nf-contact" name="contact_name" placeholder="Contact Person Name" autoComplete="name"
                   defaultValue={item.contact}/>

          </FormGroup>
          <FormGroup>
            <Label htmlFor="nf-email">Contact Email</Label>
            <Input id="nf-email" name="contact_email" placeholder="Enter Address.." autoComplete="email"
                   defaultValue={item.email}/>

          </FormGroup>
          <FormGroup>
            <Label htmlFor="nf-address">Contact Number</Label>
            <Input id="nf-address" name="contact_number" placeholder="Contact Person Phone" autoComplete="number"
                   defaultValue={item.number}/>

          </FormGroup>
          <FormGroup>
            <Label htmlFor="nf-logo">Logo</Label>
            <Input type="file" accept={"image/*"} id="nf-logo" name="logo" onChange={this.logo}/>

          </FormGroup>

          <Row>

            {(() => {
              switch (this.state.role_id) {
                case '1':
                  return <Col xs="12" md="4"><FormGroup>
                    <Label htmlFor="nf-contact">Allow SMS</Label>
                      <br></br>
                      <AppSwitch className={'mx-2'}  id={'allow_sms'} name={'allow_sms'} variant={'pill'} color={'primary'}
                                 value={'1'} checked={item.allow_sms}  onChange={this.allow_sms}
                      />
                    {/*<select name={"allow_sms"} onChange={this.allow_sms}*/}
                            {/*defaultValue={item.allow_sms}*/}
                            {/*className="form-control col-12" required>*/}
                      {/*<option value={""}>Select</option>*/}
                      {/*<option value={"1"}>Yes</option>*/}
                      {/*<option value={"0"}>No</option>*/}

                    {/*</select>*/}
                  </FormGroup></Col>;
                default:
                  return;
              }
            })()}
            {(() => {
              switch (this.state.role_id) {
                case '1':
                  return <Col xs="12" md="4"><FormGroup>
                    <Label htmlFor="nf-contact">Allow Library</Label>
                      <br></br>
                      <AppSwitch className={'mx-3'}  id={'allow_library'} name={'allow_library'} variant={'pill'} color={'primary'}
                                 value={'1'} checked={item.allow_library}  onChange={this.allow_library}
                      />
                    {/*<select name={"allow_library"} onChange={this.allow_library}*/}
                            {/*defaultValue={item.allow_library}*/}
                            {/*className="form-control col-12"*/}
                            {/*required>*/}
                      {/*<option value={""}>Select</option>*/}
                      {/*<option value={"1"}>Yes</option>*/}
                      {/*<option value={"0"}>No</option>*/}

                    {/*</select>*/}
                  </FormGroup></Col>;
                default:
                  return;
              }
            })()}
            {(() => {
              switch (this.state.role_id) {
                case '1':
                  return <Col xs="12" md="4"><FormGroup>
                    <Label htmlFor="nf-contact">Allow Create Branch</Label>
                      <br></br>
                      <AppSwitch className={'mx-5'}  id={'allow_create_branch'} name={'allow_create_branch'} variant={'pill'} color={'primary'}
                                 value={'1'} checked={item.is_create_branch}  onChange={this.allow_create_branch}
                      />
                    {/*<select name={"allow_create_branch"} onChange={this.allow_create_branch}*/}
                            {/*defaultValue={item.is_create_branch}*/}
                            {/*className="form-control col-12"*/}
                            {/*required>*/}
                      {/*<option value={""}>Select</option>*/}
                      {/*<option value={"1"}>Yes</option>*/}
                      {/*<option value={"0"}>No</option>*/}

                    {/*</select>*/}
                  </FormGroup></Col>;
                default:
                  return;
              }
            })()}
          </Row>


          <FormGroup>
            <Label htmlFor="nf-logo">Active</Label>
              <br></br>
              <AppSwitch className={'mx-2'}  id={'status'} name={'status'} variant={'pill'} color={'primary'}
                         value={'1'} checked={item.status}  onChange={this.status}
              />
            {/*<select name={"status"} className={"col-12 form-control"} required defaultValue={item.status}>*/}
              {/*<option value="">Select Status</option>*/}
              {/*<option value="1">Active</option>*/}
              {/*<option value="0">In Active</option>*/}
            {/*</select>*/}

          </FormGroup>
          {loader}
          <Button type="submit" disabled={!this.state.enable} size="sm" color="primary"><i
            className="fa fa-dot-circle-o"/> Submit</Button>
        </Form>
      )}
    </CardBody>;
    return (
      <div className="animated fadeIn">
        <Row>
          <Card style={{width: 80 + "%", marginLeft: 10 + '%'}}>
            <CardHeader>
              {success}
              {error}
              {validation}
              <Link to={'/Schools'} className="pull-right btn btn-sm btn-primary"><i
                className="icon icon-arrow-left"/>{lang[73]}</Link>
              <strong>{lang[34]}</strong>
            </CardHeader>
            {content}
          </Card>
        </Row>
      </div>
    );
  }
}
