import './../../../index';
import React, {Component} from 'react';
import {
    Badge, Card, CardBody, CardHeader, Col, Row,
    Input, Form, FormGroup
} from 'reactstrap';
import axios from "axios/index";
import {Link} from 'react-router-dom';
import CsvDownloader from 'react-csv-downloader';
import lang from './../../../Language/en-us'
import PNotify from 'pnotify/dist/es/PNotify';
// import lang from './../../../Language/fr-ca'

// import ReactTable from 'react-table';

class Tables extends Component {
    constructor(props) {
        super(props);
        this.getSchools();
        this.getBatch();
        this.state = {
            items: [],
            classes: [],
            batch: '',
            class: '',
            schools: [],
            school: '',
            count: 20,
            sortBy: [{'id': 1, 'name': 'Roll Number', 'value': 'roll'}, {
                'id': 2,
                'name': 'Student Name',
                'value': 'name'
            }],
            batches: [],
            sortOrder: '',
            hasError: false,
            loadingButton: false,
            loading: true,
            enable: true,
            enableSave: false,
            role_id: localStorage.getItem('role'),
        };
        this.addDefaultSrc = this.addDefaultSrc.bind(this);
    }

    addDefaultSrc(ev){
        ev.target.src = 'https://portal.e-idara.com/student_avatar.png'
    }

    school = (e) => {
        this.setState({
            school: e.target.value,
            enableSave: false,
            count: 20
        });
        const account = {
            id: localStorage.getItem('id'),
            school_id: e.target.value,
            count: this.state.count,
            token: localStorage.getItem('token'),
            role_id: localStorage.getItem('role'),
            sort: this.state.sortOrder
        };
        let uri = global.BASE_URL + '/StudentsList';

        axios.post(uri, account).then((response) => {
            this.setState({
                items: response.data,
                loading: false,
                count: this.state.count + 20,
                enableSave: true
            });
            let uri = global.BASE_URL + '/BatchListForSchool';
            axios.post(uri, account).then((response) => {
                this.setState({
                    batches: response.data
                })
            })
        }).catch(error => {
        });
    }

    sortOrder = (e) => {
        this.setState({
            sortOrder: e.target.value
        });
        this.componentDidMount(e.target.value);
    };

    loadMoreData = () => {
        this.setState({
            enable: false,
            loadingButton: true,
        });

        let school_id = this.state.school === '' ? localStorage.getItem('school_id') : this.state.school;

        const account = {
            id: localStorage.getItem('id'),
            school_id: school_id,
            count: this.state.count,
            token: localStorage.getItem('token'),
            role_id: localStorage.getItem('role'),
            sort: this.state.sortOrder
        };
        // const {history} = this.props;
        let uri = global.BASE_URL + '/StudentsList';

        axios.post(uri, account).then((response) => {
            this.setState({
                items: response.data,
                count: this.state.count + 20,
                enable: true,
                loadingButton: false,
                loading: false,
                enableSave: true
            });
        }).catch(error => {
        });
    }

    componentDidMount = (e) => {
        this.setState({
            loading: true
        });
        let school_id = this.state.school === '' ? localStorage.getItem('school_id') : this.state.school;

        const account = {
            id: localStorage.getItem('id'),
            school_id: school_id,
            token: localStorage.getItem('token'),
            count: this.state.count,
            role_id: localStorage.getItem('role'),
            sort: e
        };
        let uri = global.BASE_URL + '/StudentsList';

        axios.post(uri, account).then((response) => {
            if (response.data.length > 0) {
                this.setState({
                    items: response.data,
                    loading: false,
                    enableSave: true
                });
            } else {
                this.setState({
                    items: response.data,
                    loading: false,
                    enableSave: true
                });
            }
        }).catch(error => {
        });
    }

    selectBatch = (e) => {
        if (e.target.value !== '') {
            this.setState({
                batch: e.target.value,
                loading: true,
                enableSave: false
            });
            let school_id = this.state.school === '' ? localStorage.getItem('school_id') : this.state.school;

            const values = {
                school_id: school_id,
                batch_id: e.target.value,
                token: localStorage.getItem('token'),
                sort: this.state.sortOrder
            };
            let uri2 = global.BASE_URL + '/StudentListForBatch';
            axios.post(uri2, values).then((response) => {
                this.setState({
                    items: response.data,
                    loading: false,
                    enableSave: true
                });
            });
            let uri = global.BASE_URL + '/ClassListForBatch'
            axios.post(uri, values).then((response) => {
                this.setState({
                    classes: response.data
                });
            });
        }
    }

    selectClass = (e) => {
        if (e.target.value !== '') {
            this.setState({
                class: e.target.value,
                loading: true,
                enableSave: false
            })

            let school_id = this.state.school === '' ? localStorage.getItem('school_id') : this.state.school;

            let uri = global.BASE_URL + '/StudentListForClass';
            const values = {
                school_id: school_id,
                batch_id: this.state.batch,
                class_id: e.target.value,
                token: localStorage.getItem('token'),
                sort: this.state.sortOrder
            };
            axios.post(uri, values).then((response) => {
                this.setState({
                    items: response.data,
                    loading: false,
                    enableSave: true
                });
                console.log(this.state.items);
            });
        }
    }

    searchByName = (e) => {
        this.setState({
            loading: true,
            enableSave: false
        })

        let school_id = this.state.school === '' ? localStorage.getItem('school_id') : this.state.school;
        // console.log(this.state.batch, ',', this.state.class, ',', school_id);
        // return
        let uri = global.BASE_URL + '/searchStudentByName';
        const values = {
            school_id: school_id,
            batch_id: this.state.batch,
            class_id: this.state.class,
            std_name: e.target.value,
            token: localStorage.getItem('token'),
            role_id: localStorage.getItem('role'),
            sort: this.state.sortOrder
        };

        axios.post(uri, values).then((response) => {
            this.setState({
                items: response.data,
                loading: false,
                enableSave: true
            });
        });

    }

    getSchools = () => {
        const account = {
            id: localStorage.getItem('id'),
            school_id: localStorage.getItem('school_id'),
            token: localStorage.getItem('token'),
            role: localStorage.getItem('role')
        };
        let uri = global.BASE_URL + '/getschoollist';

        axios.post(uri, account).then((response) => {
            this.setState({
                schools: response.data
            });
        }).catch(error => {
        });
    }

    getBatch = () => {

        const account = {
            id: localStorage.getItem('id'),
            school_id: localStorage.getItem('school_id'),
            token: localStorage.getItem('token'),
            role: localStorage.getItem('role')
        };
        let uri = global.BASE_URL + '/BatchListForSchool';

        axios.post(uri, account).then((response) => {
            this.setState({
                batches: response.data
            });
        }).catch(error => {
        });
    }

    deleteStudent(e) {
        // e.preventDefault();
        let confirm = window.confirm('Do you really want to delete?');
        let school_id = this.state.school !== '' ? this.state.school : localStorage.getItem('school_id');
        if (confirm) {
            if (localStorage.getItem('role') === '1' || localStorage.getItem('role') === '2') {
                if (school_id === '' || school_id === null) {
                    PNotify.alert({
                        text: "Select School First!",
                        type: 'error'
                    });
                    return;
                }
            }

            const account = {
                delete_id: e,
                school_id_head: this.state.school,
                school_id: school_id,
                id: localStorage.getItem('id'),
                token: localStorage.getItem('token'),
                role_id: localStorage.getItem('role'),
            };
            let uri = global.BASE_URL + `/DeleteStudent/${e}/${localStorage.getItem('token')}`;

            axios.delete(uri, account).then((response) => {
                if (response.data === 'Student Deleted!') {
                    PNotify.alert({
                        text: "Student has been deleted successfully!",
                        type: 'success'
                    });
                    this.componentDidMount();
                } else {
                    PNotify.alert({
                        text: "Some thing went wrong try again latter!",
                        type: 'error'
                    });
                }
            }).catch(error => {
                PNotify.alert({
                    text: "Some thing went wrong try again latter!",
                    type: 'error'
                });
            });
        }
    }

    render() {
        let style = {
            textAlign: 'left'
        };
        let {items, schools, batches, classes, sortBy} = this.state;
        const columns = [{
            id: 'studentID',
            displayName: 'Roll Number'
        }, {
            id: 'name',
            displayName: 'name'
        }, {
            id: 'gender',
            displayName: 'gender'
        }, {
            id: 'age',
            displayName: 'Age'
        }, {
            id: 'class_name',
            displayName: 'Class'
        }, {
            id: 'batch_name',
            displayName: 'Batch'
        }, {
            id: 'school_name',
            displayName: 'School'
        }, {
            id: 'parent_name',
            displayName: 'Parent Name'
        }];
        let content = this.state.loading ? <div className="loading"/> : <div>
            <div className="row">
                {items.map(item =>
                    <div className="col-md-6 col-xl-3" key={item.id}>
                        <div className="card m-b-30">
                            <div className="card-body row">
                                <div className="col-6">
                                    <Link to={{pathname: `/StudentProfile/${item.id}`}}>
                                        {(() => {
                                            if (item.picture === null || item.picture === ''|| item.picture == '') {
                                                return <img className="img-responsive"
                                                            src={'https://portal.e-idara.com/assets/img/avatars/9.png'}
                                                            alt={item.name} height="70px" width="70px" />;
                                            } else {
                                                return <img className="img-responsive" src={item.picture}
                                                            onError={this.addDefaultSrc}
                                                            alt={item.name} height="70px" width={"70px"} />
                                            }
                                        })()}
                                    </Link>
                                </div>
                                <div className="col-6 card-title align-self-center mb-0">
                                    <h5>{item.name}</h5>
                                    <p className="m-0">{item.studentID}</p>
                                </div>
                            </div>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item"><i style={{fontSize: 28 + "px"}}
                                                                   className="fa fa-venus-mars float-right"/>{item.gender}
                                </li>
                                <li className="list-group-item"><i style={{fontSize: 28 + "px"}}
                                                                   className="fa fa-calendar float-right"/>{item.date_of_birth}
                                </li>
                                <li className="list-group-item"><i style={{fontSize: 28 + "px"}}
                                                                   className="fas fa-book-reader float-right"/>{item.class_name}
                                </li>
                                <li className="list-group-item"><i style={{fontSize: 28 + "px"}}
                                                                   className="fas fa-user-graduate float-right"/>{item.batch_name}
                                </li>
                                <li className="list-group-item"><i style={{fontSize: 28 + "px"}}
                                                                   className="fas fa-school float-right"/>School:
                                    <div className="list-content">
                                        {item.school_name}
                                    </div>
                                </li>
                                <li className="list-group-item"><i style={{fontSize: 28 + "px"}}
                                                                   className="fas fa-user-tie float-right"/>
                                    <div className="list-content">
                                        {item.parent_name}
                                    </div>
                                </li>
                                <li className="list-group-item">
                                    <span style={style}>Status</span>
                                    {item.status === '1' || item.status === 1 || item.status === "1" ?
                                        <Badge className="badge-success float-right">Active</Badge> :
                                        <Badge className="badge-danger float-right">In Active</Badge>}
                                </li>
                            </ul>
                            <div className="card-body">
                                <div className="float-right btn-group btn-group-sm">
                                    {(() => {
                                        if (this.state.role_id === '1' || this.state.role_id === '2' || this.state.role_id === '3') {
                                            return <a href={'javascript:void(0)'}
                                                      className="btn btn-danger mr-1 tooltips"
                                                      data-placement="top" data-toggle="tooltip"
                                                      data-original-title="View"
                                                      onClick={this.deleteStudent.bind(this, `${item.id}`)}
                                            ><i className="fa fa-trash"/></a>
                                        }
                                    })()}
                                    <Link to={{pathname: `/StudentProfile/${item.id}`}}
                                          className="btn btn-success mr-1 tooltips"
                                          data-placement="top" data-toggle="tooltip"
                                          data-original-title="View"><i className="fa fa-eye"/></Link>
                                    <Link to={{pathname: `/Student/edit/${item.id}`}}
                                          className="btn btn-primary tooltips"
                                          data-placement="top" data-toggle="tooltip"
                                          data-original-title="Edit"><i className="fa fa-pencil"/></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <Row>
                <Col id="load-more-button">
                    <button className="btn btn-success btn-md" disabled={!this.state.enable}
                            onClick={this.loadMoreData}>
                        {(() => {
                            if (this.state.loadingButton) {
                                return <i className="icon icon-plus"><span className="ml-1">Loading</span></i>
                            } else {
                                return <i className="icon icon-plus"><span style={{fontFamily: 'sans-serif'}}
                                                                           className="ml-1">Load More</span></i>
                            }
                        })()}
                    </button>
                </Col>
            </Row>
        </div>;
        if (this.state.hasError) {
            this.props.history.push('/404')
        }
        return <div className="animated fadeIn">
            <Row>
                <Col>
                    <Card>
                        <CardHeader>
                            <i className="fa fa-align-justify"/> {lang[2]}
                            {(() => {
                                if (this.state.role_id === '1' || this.state.role_id === '2' || this.state.role_id === '3') {
                                    return <Link to={{pathname: "/Students/create"}}
                                                 className="btn btn-sm btn-info pull-right"><i
                                        className='icon-plus'/> {lang[28]}</Link>;
                                }
                            })()}
                            <div className="pull-right mr-1">
                                <CsvDownloader
                                    filename="students"
                                    datas={items}
                                    columns={columns}
                                    text="Save as CSV"
                                >
                                    <button className="btn btn-sm btn-success" disabled={!this.state.enableSave}><i
                                        className="fa fa-download mr-1"/>{lang[76]} CSV
                                    </button>
                                </CsvDownloader>
                            </div>
                            <Link style={{marginRight: "10px"}}
                                  to={{pathname: `/Students/import`}}
                                  className="pull-right btn btn-sm btn-primary ml-2"><i
                                className="fa fa-upload"/>&nbsp;{lang[85]}
                            </Link>
                        </CardHeader>
                        <CardBody>
                            <Form>
                                <FormGroup>
                                    <Row>
                                        <Col xs={"6"} md={"6"} lg={"3"}>
                                            <Input type={"select"} name={"sortOrder"}
                                                   onChange={this.sortOrder}>
                                                <option value={""}>Sort By</option>
                                                {sortBy.map(sort =>
                                                    <option key={sort.id}
                                                            value={sort.value}>{sort.name}</option>
                                                )};
                                            </Input>
                                        </Col>
                                        {(() => {
                                            if (this.state.role_id === '1' || this.state.role_id === '2') {
                                                return <Col xs={"6"} md={"6"} lg={"3"}>
                                                    <Input type="select" name="clss" id="SelectLm"
                                                           onChange={this.school} className="">
                                                        <option value={""}>Select School</option>
                                                        {schools.map(item =>
                                                            <option key={item.id}
                                                                    value={item.id}>{item.name}</option>
                                                        )}
                                                    </Input>
                                                </Col>
                                            }
                                        })()}
                                        <Col xs={"6"} md={"6"} lg={"3"}>
                                            <Input type={"select"} name={"batch"}
                                                   onChange={this.selectBatch}>
                                                <option value={""}>Select Batch</option>
                                                {batches.map(batch =>
                                                    <option key={batch.id}
                                                            value={batch.id}>{batch.name}</option>
                                                )};
                                            </Input>
                                        </Col>
                                        <Col xs={"6"} md={"6"} lg={"3"}>
                                            <Input type={"select"} name={"class"}
                                                   onChange={this.selectClass}>
                                                <option value={""}> Select Class</option>
                                                {classes.map(classs =>
                                                    <option key={classs.id}
                                                            value={classs.id}>{classs.name}</option>
                                                )};
                                            </Input>
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <FormGroup>
                                    <Row>
                                        <Col xs={"6"} md={"6"} lg={"3"}>
                                            <Input type={"text"} placeholder={"Search By Student name or Roll Number"}
                                                   name={"name"}
                                                   onChange={this.searchByName}/>
                                        </Col>
                                    </Row>
                                </FormGroup>
                            </Form>
                            {/*{content}*/}
                            {items.map(item =>
                                <Row style={{border: '1px solid #53535387', marginTop: '5px'}}>
                                    <Col xs="2">
                                        <div><img src={item.picture} onError={this.addDefaultSrc} style={{
                                            height: '100px',
                                            marginLeft: '12px',
                                            width: '110px',
                                            marginTop: '7px'
                                        }}></img></div>
                                    </Col>
                                    <Col xs="4">
                                        <p>Student Name: {item.name}</p>
                                        <p>Roll Number: {item.roll_number}</p>
                                        <p>Student Id: {item.studentID}</p>
                                        <p>Date Of Birth: {item.date_of_birth}</p>
                                    </Col>
                                    <Col xs="6">
                                        <p>Batch: {item.batch_name}</p>
                                        <p>Class: {item.class_name} </p>
                                        <p>Status:
                                            {item.status === '1' || item.status === 1 || item.status === "1" ?
                                                <Badge className="badge-success">Active</Badge> :
                                                <Badge className="badge-danger">In Active</Badge>
                                            }
                                        </p>
                                        {/*<Link style={{float:'right',marginTop:'-51px'}} target="_blank" to={{pathname: `StudentProfile/${item.id}`}} id={item.id}*/}
                                        {/*className="btn btn-primary btn-sm"><i className="fa fa-eye"/>View Profile</Link>*/}
                                        <div style={{marginTop:'-71px',marginRight:'10px'}}>
                                            {(() => {
                                                if (this.state.role_id === '1' || this.state.role_id === '2' || this.state.role_id === '3') {
                                                    return <a href={'javascript:void(0)'}
                                                              style={{float: 'right'}}
                                                              className="btn btn-danger mr-1 tooltips"
                                                              data-placement="top" data-toggle="tooltip"
                                                              data-original-title="View"
                                                              onClick={this.deleteStudent.bind(this, `${item.id}`)}
                                                    ><i className="fa fa-trash"/></a>
                                                }
                                            })()}
                                            <Link to={{pathname: `/Students/Profile/${item.id}`}}
                                                  style={{float: 'right'}}
                                                  className="btn btn-success mr-1 tooltips"
                                                  data-placement="top" data-toggle="tooltip"
                                                  data-original-title="View"><i className="fa fa-eye"/></Link>
                                            <Link to={{pathname: `/Students/edit/${item.id}`}}
                                                  style={{float: 'right',marginRight:'4px'}}
                                                  className="btn btn-primary tooltips"
                                                  data-placement="top" data-toggle="tooltip"
                                                  data-original-title="Edit"><i className="fa fa-pencil"/></Link>
                                        </div>
                                    </Col>
                                </Row>
                            )}
                            <Row style={{marginTop:'15px'}}>
                                <Col id="load-more-button">
                                    {(() => {
                                        if (this.state.loadingButton) {
                                            return <button className="btn btn-success" disabled={!this.state.enable}
                                                           onClick={this.loadMoreData}>
                                                <i className="fa fa-spinner"></i>{' '}
                                                Loading
                                            </button>
                                        }
                                        else {
                                            return <button className="btn btn-success" disabled={!this.state.enable}
                                                           onClick={this.loadMoreData}>
                                                <i className="icon icon-plus"></i>{' '}
                                                Load More
                                            </button>
                                        }
                                    })()}
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

        </div>;
    }
}

export default Tables;
