import './../../../index';
import React, {Component} from 'react';
import {
    Badge, Card, CardBody, CardHeader, Col, Row, Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    FormGroup,
    Label,
    Input
} from 'reactstrap';
import axios from "axios";
import {Link} from "react-router-dom";
import ReactTable from 'react-table';
import lang from './../../../Language/en-us'
import PNotify from 'pnotify/dist/es/PNotify';

// import lang from './../../../Language/fr-ca'

class Tables extends Component {
    constructor(props) {
        super(props);
        this.getSchoolList();
        this.state = {
            items: [],
            loading: true,
            branch: false,
            status: false,
            modal: false,
            subs_months: '',
            date: '',
            model_school_id: '',
            model_result: [],
            role_id: localStorage.getItem('role'),
        };
        this.toggle = this.toggle.bind(this);
        this.subscription_months = this.subscription_months.bind(this);
        this.saveSubscription = this.saveSubscription.bind(this);
        this.sendEmail = this.sendEmail.bind(this);
        this.approve_subs = this.approve_subs.bind(this);
    }

    sendEmail(e) {

        this.setState({
            modal: !this.state.modal,
        });

        let uris = global.BASE_URL + '/sendSubscriptionEmail';
        const values = {
            school_id: this.state.model_school_id,
        };
        axios.post(uris, values).then((response) => {
            if (response.data == 'email sent') {
                PNotify.alert({
                    text: "Subscription key has been sent successfully",
                    type: 'success'
                });
            }
        }).catch(error => {
            PNotify.alert({
                text: "Some thing went wrong try again latter!",
                type: 'error'
            });
        });
    }

    approve_subs(e) {

        this.setState({
            modal: !this.state.modal,
        });
        let uris = global.BASE_URL + '/approveSubscription';
        const values = {
            school_id: this.state.model_school_id,
        };
        axios.post(uris, values).then((response) => {
            if (response.data == 'approved') {
                PNotify.alert({
                    text: "Subscription has been approved successfully!",
                    type: 'success'
                });
            } else {
                PNotify.alert({
                    text: "Some thing went wrong try again latter!",
                    type: 'error'
                });
            }
        }).catch(error => {
            PNotify.alert({
                text: "Some thing went wrong try again latter!",
                type: 'error'
            });
        });
    }

    subscription_months(e) {
        if (e.target.value > 60) {
            this.setState({subs_months: '60'})
        } else {
            this.setState({subs_months: e.target.value})
        }
        // alert(e.target.value);
        let uris = global.BASE_URL + '/addMonths';
        const values = {
            months: e.target.value,
        };
        axios.post(uris, values).then((response) => {
            // console.log(response.data);
            this.setState({date: response.data})
        }).catch(error => {
        });
    }

    saveSubscription(e) {
        if (this.state.subs_months == '' || this.state.date == '') {
            PNotify.alert({
                text: "Enter Months to continue",
                type: 'error'
            });
        } else {
            this.setState({
                modal: !this.state.modal,
            });
        }

        let uris = global.BASE_URL + '/saveSubscription';
        const values = {
            months: this.state.subs_months,
            date: this.state.date,
            school_id: this.state.model_school_id,
        };
        axios.post(uris, values).then((response) => {
            if (response.data == 'added') {
                PNotify.alert({
                    text: "Subscription has been added successfully!",
                    type: 'success'
                });
            } else {
                PNotify.alert({
                    text: "Some thing went wrong try again latter!",
                    type: 'error'
                });
            }
        }).catch(error => {
            PNotify.alert({
                text: "Some thing went wrong try again latter!",
                type: 'error'
            });
        });
    }


    toggle() {
        this.setState({
            modal: !this.state.modal,
        });
    }

    subsription_modal(e) {
        this.setState({
            modal: !this.state.modal,
        });
        this.setState({model_school_id: e});
        let uris = global.BASE_URL + '/getSchoolSubscription';
        const values = {
            months: this.state.subs_months,
            date: this.state.date,
            school_id: e,
        };
        axios.post(uris, values).then((response) => {
            this.setState({model_result: response.data});
            this.setState({subs_months: response.data[0].months});
            this.setState({date: response.data[0].date});
        }).catch(error => {
        });
    }

    delete = (e)=>{
        const values = {

            school_id: e,
        };
        let uris = global.BASE_URL + '/deleteSchool';
        axios.post(uris, values).then((response) => {
           if(response.data == 'School Deleted!'){
               this.getSchoolList();
               PNotify.alert({
                   text: "School has been deleted!",
                   type: 'success'
               });
           }else{
               PNotify.alert({
                   text: "Some thing went wrong!",
                   type: 'error'
               });
           }
        }).catch(error => {
            PNotify.alert({
                text: "Some thing went wrong!",
                type: 'error'
            });
        });
    }
    getSchoolList() {
        if (localStorage.getItem('role') === '3') {
            this.props.history.push(`/Schools/View/${localStorage.getItem('school_id')}`);
        }
        let uri = global.BASE_URL + '/getschoollist_for_school_list';
        const account = {
            role: localStorage.getItem('role'),
            id: localStorage.getItem('id'),
            school_id: localStorage.getItem('school_id'),
        };
        axios.post(uri, account).then((response) => {
            console.log(response.data[0].is_create_branch + localStorage.getItem('role'));
            if ((response.data[0].is_create_branch === '1' || response.data[0].is_create_branch === 1 || response.data[0].is_create_branch == 1) && localStorage.getItem('role') === '2') {
                this.setState({branch: true});
            } else {
                this.setState({branch: false});
            }
            this.setState({
                loading: false,
            });
            this.setState({
                items: response.data,
                loading: false,
            });
        }).catch(error => {
        });
    }

    render() {

        let branching = this.state.branch ? <Link
            to={{pathname: "/Schools/create/branch/" + localStorage.getItem('school_id')}}
            color="primary create-button"
            className="btn btn-info btn-sm pull-right"><i
            className="icon-plus"/> {lang[72]}</Link> : <div></div>

        let items = this.state.items;
        let model_result = this.state.model_result;
        let subs_months = this.state.subs_months;
        let date = this.state.date;
        let content = this.state.loading ? <div className="loading"/> : <ReactTable
            data={items}
            columns={[
                {
                    Header: "School",
                    accessor: 'name',
                },
                {
                    Header: 'Address',
                    accessor: 'address'
                },
                {
                    Header: 'Contact Name',
                    accessor: 'contact_name'
                },
                {
                    Header: 'Contact Email',
                    accessor: 'email'
                },
                {
                    Header: 'Contact Number',
                    accessor: 'contact_no'
                },
                {
                    Header: 'Status',
                    accessor: 'status',
                    Cell: ({value}) => (value === '1' || value === 1 || value == 1 || value == "1" ?
                        <Badge className="badge-success">Active</Badge> :
                        <Badge className="badge-danger">In Active</Badge>),
                    filterMethod: (filter, row) => {
                        if (filter.value === "all") {
                            return true;
                        } else if (filter.value === '1' || filter.value === 1) {
                            return row[filter.id] === '1';
                        } else {
                            return row[filter.id] === '0';
                        }
                    },
                    Filter: ({filter, onChange}) =>
                        <select
                            onChange={event => onChange(event.target.value)}
                            style={{width: "100%"}}
                            value={filter ? filter.value : "all"}
                        >
                            <option value="all">Show All</option>
                            <option value='1'>Active</option>
                            <option value='0'>In Active</option>
                        </select>
                },
                {
                    Header: 'Can Create Branch',
                    accessor: 'is_create_branch',
                    Cell: ({value}) => (value === '1' || value === "1" || value == 1 || value === 1 || value == '1' ?
                        <Badge className="badge-success">Can Create Branch</Badge> :
                        <Badge className="badge-danger">Can not Create Branch</Badge>),
                    filterMethod: (filter, row) => {
                        if (filter.value === "all") {
                            return true;
                        }
                        if (filter.value === '1') {
                            return row[filter.id] === '1';
                        }
                        return row[filter.id] === '0';
                    },
                    Filter: ({filter, onChange}) =>
                        <select
                            onChange={event => onChange(event.target.value)}
                            style={{width: "100%"}}
                            value={filter ? filter.value : "all"}
                        >
                            <option value="all">Show All</option>
                            <option value='1'>Can Create Branch</option>
                            <option value='0'>Can't Create Branch</option>
                        </select>
                },
                {
                    Header: 'Action',
                    Cell: (row, value = this.state.role_id) => (
                        <div style={{display: 'flex'}}>
                            {this.state.role_id === '1' || this.state.role_id === "1" || this.state.role_id === 1 || this.state.role_id == 1 || this.state.role_id == '1' ?
                                <div>
                                    <i style={{
                                        padding: '5px 11px 8px 10px',
                                        background: '#20a8d8',
                                        color: 'white',
                                        borderRadius: '9%',
                                        marginRight: '4px'
                                    }}
                                       onClick={this.subsription_modal.bind(this, row.original.id)}
                                       className={"fa fa-dollar"}/>
                                    <Link to={{pathname: `/Schools/View/${row.original.id}`}}
                                          className={"btn btn-success btn-sm mr-1"}><i
                                        className={"fa fa-eye"}/></Link>

                                </div>

                                : <div></div>}
                            <Link to={{pathname: `/Schools/Edit/${row.original.id}`}}
                                  className={"btn btn-primary btn-sm"}><i
                                className="fa fa-pencil"/></Link>
                            <i style={{
                                padding: '5px 11px 8px 10px',
                                background: '#d85d33',
                                color: 'white',
                                borderRadius: '9%',
                                marginRight: '4px',
                                marginLeft: '4px'
                            }}
                               onClick={this.delete.bind(this, row.original.id)}
                               className={"fa fa-trash"}/>
                        </div>)
                },
            ]}
            defaultPageSize={10}
            className="-striped -highlight -sm"
            showPagination={true}
            showPaginationTop={false}
            showPaginationBottom={true}
            pageSizeOptions={[5, 10, 20, 25, 50, 100]}
            filterable
        />;
        return (
            <div className="animated fadeIn">
                <Row>
                    <Col>
                        <Card>
                            <CardHeader>
                                <i className="fa fa-align-justify"/> {lang[1]}
                                {(() => {
                                    if (this.state.role_id === '1') {
                                        switch (this.state.role_id) {
                                            case '2':
                                                return <Link
                                                    to={{pathname: "/Schools/create/branch/" + localStorage.getItem('school_id')}}
                                                    color="primary create-button"
                                                    className="btn btn-info btn-sm pull-right"><i
                                                    className="icon-plus"/> {lang[72]}</Link>;
                                            case '1':
                                                return <Link to={{pathname: "/Schools/create"}}
                                                             color="primary create-button"
                                                             className="btn btn-info btn-sm pull-right"><i
                                                    className="icon-plus"/> {lang[28]}</Link>;
                                            default:
                                                return;
                                        }
                                    }
                                })()}
                                <Link
                                    to={{pathname: "/Schools/deleted"}}
                                    color="primary create-button"
                                    className="btn btn-warning btn-sm pull-right"
                                    style={{marginRight:'4px'}}><i
                                    className="icon-trash"/> Deleted School</Link>
                                {branching}
                                <Modal isOpen={this.state.modal} toggle={this.toggle}
                                       className={'modal-lg ' + this.props.className}>
                                    <ModalHeader toggle={this.toggle}>Subscription</ModalHeader>
                                    <ModalBody>
                                        <Row>
                                            <Col xs={"6"}>
                                                <FormGroup>
                                                    <Label htmlFor={"subs"}>Subscription</Label>
                                                    <Input type="number" id="subscription_months"
                                                           name="subscription_months"
                                                           placeholder="Enter No. of months (Max 60)"
                                                           min={"1"} max={"60"} defaultValue={this.state.subs_months}
                                                           onChange={this.subscription_months} required/>
                                                </FormGroup>
                                            </Col>
                                            <Col xs={"6"}>
                                                <FormGroup>
                                                    <Label htmlFor={"subs"}>Valid Till</Label>
                                                    <Input type="date" id="date" defaultValue={this.state.date}
                                                           name="date" readOnly required/>
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        {model_result.map(item =>

                                            <Row>
                                                <Col xs={"6"}>
                                                    <Button type="button" onClick={this.sendEmail} size="sm"
                                                            color="primary"><i
                                                        className="fa fa-envelope"/> Send Email</Button>
                                                </Col>
                                                <Col xs={"6"}>
                                                    <Button type="button" onClick={this.approve_subs} size="sm"
                                                            color="primary"><i
                                                        className="fa fa-check"/>Approve
                                                        Automatically</Button>
                                                </Col>
                                            </Row>
                                        )}
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button color="primary" onClick={this.saveSubscription}>Save</Button>{' '}
                                        <Button color="secondary" onClick={this.toggle}>Cancel</Button>
                                    </ModalFooter>
                                </Modal>
                            </CardHeader>

                            <CardBody>
                                {content}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>

        );
    }
}

export default Tables;
