import React from 'react';
import Loadable from 'react-loadable'
// import lang from './Language/fr-ca'
import lang from './Language/en-us'

function Loading() {
    return <div>Loading...</div>;
}

const roles = Loadable({
    loader: () => import('./views/Pages/Roles/roles'),
    loading: Loading,
});
const roles_edit = Loadable({
    loader: () => import('./views/Pages/Roles/edit'),
    loading: Loading,
});
const importClass = Loadable({
    loader: () => import('./views/Pages/Classes/import'),
    loading: Loading,
});
const importSubject = Loadable({
    loader: () => import('./views/Pages/Subjects/Import'),
    loading: Loading,
});
const importStaff = Loadable({
    loader: () => import('./views/Pages/Staff/import'),
    loading: Loading,
});
const importVouchers = Loadable({
    loader: () => import('./views/Pages/FeeVoucher/import'),
    loading: Loading,
});
const BioMetricAttendance = Loadable({
    loader: () => import('./views/Pages/Attandance/BioMetric'),
    loading: Loading,
});
const reportings = Loadable({
    loader: () => import('./views/Pages/reporting/reporting'),
    loading: Loading,
});
const pay_voucher = Loadable({
    loader: () => import('./views/Pages/FeeVoucher/pay_voucher'),
    loading: Loading,
});
const logs = Loadable({
    loader: () => import('./views/Pages/Logs/view_logs'),
    loading: Loading,
});
const reporting_detail = Loadable({
    loader: () => import('./views/Pages/reporting/detail'),
    loading: Loading,
});
const create_invoice = Loadable({
    loader: () => import('./views/Pages/reporting/create'),
    loading: Loading,
});
const edit_invoice = Loadable({
    loader: () => import('./views/Pages/reporting/edit'),
    loading: Loading,
});
const Schools = Loadable({
    loader: () => import('./views/Pages/Schools'),
    loading: Loading,
});

const Page404 = Loadable({
    loader: () => import('./views/Pages/Page404'),
    loading: Loading,
});

const Students = Loadable({
    loader: () => import('./views/Pages/Students'),
    loading: Loading,
});
const Classes = Loadable({
    loader: () => import('./views/Pages/Classes'),
    loading: Loading,
});
const Batches = Loadable({
    loader: () => import('./views/Pages/Batches'),
    loading: Loading,
});
const ResultCategory = Loadable({
    loader: () => import('./views/Pages/ResultCategory'),
    loading: Loading,
});
const Subjects = Loadable({
    loader: () => import('./views/Pages/Subjects'),
    loading: Loading,
});
const UpdateResult = Loadable({
    loader: () => import('./views/Pages/UpdateResult'),
    loading: Loading,
});

const FeeStructure = Loadable({
    loader: () => import('./views/Pages/FeeStructure'),
    loading: Loading,
});


const AddFee = Loadable({
    loader: () => import('./views/Pages/AddFee'),
    loading: Loading,
});

const EditFee = Loadable({
    loader: () => import('./views/Pages/EditFee'),
    loading: Loading,
});

const StudentResult = Loadable({
    loader: () => import('./views/Pages/StudentResult'),
    loading: Loading,
});
const StudentFee = Loadable({
    loader: () => import('./views/Pages/StudentFee'),
    loading: Loading,
});
// const SchoolEdit = Loadable({
//   loader: () => import('./views/Pages/Schools/SchoolEdit'),
//   loading: Loading,
// });


const Staff = Loadable({
    loader: () => import('./views/Pages/Staff'),
    loading: Loading,
});

const Attandance = Loadable({
    loader: () => import('./views/Pages/Attandance'),
    loading: Loading,
});
const NewAttendance = Loadable({
    loader: () => import('./views/Pages/Attandance/NewAttendance'),
    loading: Loading,
});

const AttendanceView = Loadable({
    loader: () => import('./views/Pages/Attandance/AttendanceView'),
    loading: Loading,
});

const create_branch = Loadable({
    loader: () => import('./views/Pages/Schools/create_branch'),
    loading: Loading,
});
const Settings = Loadable({
    loader: () => import('./views/Pages/Settings/Settings'),
    loading: Loading,
});
const Library = Loadable({
    loader: () => import('./views/Pages/Library/Library'),
    loading: Loading,
});
const create_library = Loadable({
    loader: () => import('./views/Pages/Library/create'),
    loading: Loading,
});

const create_folder = Loadable({
    loader: () => import('./views/Pages/Library/folder'),
    loading: Loading,
});
const view_library_files = Loadable({
    loader: () => import('./views/Pages/Library/files'),
    loading: Loading,
});
const headoffice_settings = Loadable({
    loader: () => import('./views/Pages/Settings/headoffice'),
    loading: Loading,
});

const Dashboard = Loadable({
    loader: () => import('./views/Dashboard'),
    loading: Loading,
});
const Users = Loadable({
    loader: () => import('./views/Pages/Users/Users'),
    loading: Loading,
});
const StaffEdit = Loadable({
    loader: () => import('./views/Pages/Staff/Edit/Edit'),
    loading: Loading,
});
const SchoolEdit = Loadable({
    loader: () => import('./views/Pages/Schools/Edit/Edit'),
    loading: Loading,
});
const SchoolView = Loadable({
    loader: () => import('./views/Pages/Schools/view/view'),
    loading: Loading,
});
const SchoolDetail = Loadable({
    loader: () => import('./views/Pages/Schools/detail/detail'),
    loading: Loading,
});
const CreateSchool = Loadable({
    loader: () => import('./views/Pages/Schools/create'),
    loading: Loading,
});
const UserEdit = Loadable({
    loader: () => import('./views/Pages/Users/Edit/Edit'),
    loading: Loading,
});
const CreateUser = Loadable({
    loader: () => import('./views/Pages/Users/create'),
    loading: Loading,
});
const CreateStaff = Loadable({
    loader: () => import('./views/Pages/Staff/create'),
    loading: Loading,
});
const StaffDelete = Loadable({
    loader: () => import('./views/Pages/Staff/delete'),
    loading: Loading,
});
const CreateStudent = Loadable({
    loader: () => import('./views/Pages/Students/create'),
    loading: Loading,
});
const EditStudent = Loadable({
    loader: () => import('./views/Pages/Students/Edit'),
    loading: Loading,
});
const CreateClass = Loadable({
    loader: () => import('./views/Pages/Classes/create'),
    loading: Loading,
});
const CreateBatch = Loadable({
    loader: () => import('./views/Pages/Batches/create'),
    loading: Loading,
});
const EditBatch = Loadable({
    loader: () => import('./views/Pages/Batches/Edit'),
    loading: Loading,
});

const batchesDetail = Loadable({
    loader: () => import('./views/Pages/Batches/detail'),
    loading: Loading,
});
const CreateSubject = Loadable({
    loader: () => import('./views/Pages/Subjects/create'),
    loading: Loading,
});
const CreateCategory = Loadable({
    loader: () => import('./views/Pages/ResultCategory/create'),
    loading: Loading,
});
const EditClass = Loadable({
    loader: () => import('./views/Pages/Classes/Edit'),
    loading: Loading,
});
const Class_detail = Loadable({
    loader: () => import('./views/Pages/Classes/detail'),
    loading: Loading,
});
const StudentProfile = Loadable({
    loader: () => import('./views/Pages/Students/profile'),
    loading: Loading,
});
const EditSubject = Loadable({
    loader: () => import('./views/Pages/Subjects/Edit'),
    loading: Loading,
});
const EditResultCategory = Loadable({
    loader: () => import('./views/Pages/ResultCategory/edit'),
    loading: Loading,
});
const EditAttendance = Loadable({
    loader: () => import('./views/Pages/Attandance/EditAttendance/EditAttendance'),
    loading: Loading,
});
const FeeVoucher = Loadable({
    loader: () => import('./views/Pages/FeeVoucher/FeeVoucher'),
    loading: Loading,
});
const singleVoucherFee = Loadable({
    loader: () => import('./views/Pages/FeeVoucher/singleVoucherFee'),
    loading: Loading,
});
const singleVoucherFee_detail = Loadable({
    loader: () => import('./views/Pages/FeeVoucher/singleVoucherFeeDetail'),
    loading: Loading,
});
const FeeVoucherList = Loadable({
    loader: () => import('./views/Pages/FeeVoucher/FeeVoucherList'),
    loading: Loading,
});
const VoucherDetail = Loadable({
    loader: () => import('./views/Pages/FeeVoucher/FeeVoucherDetailByMaster'),
    loading: Loading,
});
const FeeVoucherDetailList = Loadable({
    loader: () => import('./views/Pages/FeeVoucher/FeeVoucherDetailList'),
    loading: Loading,
});
const VoucherDetailEdit = Loadable({
    loader: () => import('./views/Pages/FeeVoucher/VoucherDetailEdit'),
    loading: Loading,
});
const StaffAttandance = Loadable({
    loader: () => import('./views/Pages/StaffAttandance/Attandance'),
    loading: Loading,
});
const NewStaffAttendance = Loadable({
    loader: () => import('./views/Pages/StaffAttandance/NewAttendance/NewAttendance'),
    loading: Loading,
});
const StaffAttendanceView = Loadable({
    loader: () => import('./views/Pages/StaffAttandance/AttendanceView/AttendanceView'),
    loading: Loading,
});
const FeeVoucherEdit_detail = Loadable({
    loader: () => import('./views/Pages/FeeVoucher/FeeVoucherEdit_detail'),
    loading: Loading,
});
const FeeDefaulter = Loadable({
    loader: () => import('./views/Pages/FeeVoucher/FeeDefaulter'),
    loading: Loading,
});
const Events = Loadable({
    loader: () => import('./views/Pages/Events/Events'),
    loading: Loading,
});
const Event_create = Loadable({
    loader: () => import('./views/Pages/Events/create'),
    loading: Loading,
});
const Event_edit = Loadable({
    loader: () => import('./views/Pages/Events/edit'),
    loading: Loading,
});
const bulk_update_result = Loadable({
    loader: () => import('./views/Pages/UpdateResult/bulk_upload'),
    loading: Loading,
});
const TimeTable = Loadable({
    loader: () => import('./views/Pages/timetable/timeTable'),
    loading: Loading,
});
const EmailTemplate = Loadable({
    loader: () => import('./views/Pages/Settings/EmailTemplate'),
    loading: Loading,
});
const SMSTemplate = Loadable({
    loader: () => import('./views/Pages/Settings/SMSTemplate'),
    loading: Loading,
});
const Homework = Loadable({
    loader: () => import('./views/Pages/Homework/homework'),
    loading: Loading,
});
const ImportStudent = Loadable({
    loader: () => import('./views/Pages/Students/Import'),
    loading: Loading,
});

// const createRole = Loadable({
//     loader: () => import('./views/Pages/Roles/create'),
//     loading: Loading,
// });

const SendCustomEmail = Loadable({
    loader: () => import('./views/Pages/Settings/CustomEmail'),
    loading: Loading,
});

const systemConfiguration = Loadable({
    loader: () => import('./views/Pages/Settings/systemConfiguration'),
    loading: Loading,
});
const FeeComponent_route = Loadable({
    loader: () => import('./views/Pages/FeeComponent/feeComponent'),
    loading: Loading,
});
const FeeComponent_create = Loadable({
    loader: () => import('./views/Pages/FeeComponent/create'),
    loading: Loading,
});
const FeeComponent_edit = Loadable({
    loader: () => import('./views/Pages/FeeComponent/edit'),
    loading: Loading,
});

const Notifications = Loadable({
    loader: () => import('./views/Pages/Notifications/Notifications'),
    loading: Loading,
});

const subscription = Loadable({
    loader: () => import('./views/Pages/subscription/subscription'),
    loading: Loading,
});
const TeacherTimeTable = Loadable({
    loader: () => import('./views/Pages/timetable/teacherTimeTable'),
    loading: Loading,
});
const attendanceReport = Loadable({
    loader: () => import('./views/Pages/Attandance/attendanceReport/Report'),
    loading: Loading,
});
const deletedSchool = Loadable({
    loader: () => import('./views/Pages/Schools/deletedSchool'),
    loading: Loading,
});
const receivePayment = Loadable({
    loader: () => import('./views/Pages/FeeVoucher/receivePayment'),
    loading: Loading,
});
const feeVoucherReporting = Loadable({
    loader: () => import('./views/Pages/AllReportings/feeVoucherReporting'),
    loading: Loading,
});
const feeVoucherStudentWise = Loadable({
    loader: () => import('./views/Pages/AllReportings/feeVoucherStudentWise'),
    loading: Loading,
});
const ReportingFeeDefaulters = Loadable({
    loader: () => import('./views/Pages/AllReportings/ReportingFeeDefaulters'),
    loading: Loading,
});
const ReportingStudentList = Loadable({
    loader: () => import('./views/Pages/AllReportings/Student/ReportingStudentList'),
    loading: Loading,
});

const ReportingStudentDetail = Loadable({
    loader: () => import('./views/Pages/AllReportings/Student/ReportingStudentDetail'),
    loading: Loading,
});

const ParentReporting = Loadable({
    loader: () => import('./views/Pages/AllReportings/Student/ParentReporting'),
    loading: Loading,
});





let item = [
    {path: '/', exact: true, name: lang[0], db_name: 'Dashboard', component: Dashboard},
    {path: '/dashboard', name: lang[0],db_name: 'Dashboard', component: Dashboard},
    {path: '/role/edit/:id', exact: true, name: lang[91],db_name: 'Role Edit', component: roles_edit},
    // {path: '/Role/create', exact: true, name: lang[90], db_name: 'Role Create', component: createRole},
    {path: '/roles', exact: true, name: lang[89], db_name: 'Roles', component: roles},
    {path: '/Subjects/import', exact: true, name: lang[85],  db_name: 'Import Subjects', component: importSubject},
    {path: '/Classes/import', exact: true, name: lang[85], db_name: 'Import Class', component: importClass},
    {path: '/Staff/importStaff', exact: true, name: lang[85],  db_name: 'Import Staff', component: importStaff},
    {path: '/Students/import', exact: true, name: lang[85],  db_name: 'Import Student', component: ImportStudent},
    {path: '/FeeVoucherEdit/:id', exact: true, name: lang[44], db_name: 'Fee Voucher Edit', component: FeeVoucherEdit_detail},
    {path: '/Students', exact: true, name: lang[2],  db_name: 'Students', component: Students},
    {path: '/Classes', exact: true, name: lang[3], db_name: 'Classes',component: Classes},
    {path: '/Classes/detail/:id', exact: true, name: lang[53],db_name: 'Class Detail', component: Class_detail},
    {path: '/Batches', exact: true, name: lang[4],db_name: 'Year Of Entry', component: Batches},
    {path: '/ResultCategory', exact: true, name: lang[7],db_name: 'Result Category', component: ResultCategory},
    {path: '/Subjects', exact: true, name: lang[31],db_name: 'Subjects', component: Subjects},
    {path: '/UpdateResult', exact: true, name: lang[8],db_name: 'Upload Result', component: UpdateResult},
    {path: '/UpdateResult/StudentResult/:id', exact: true, name: lang[32],db_name: 'Student Result', component: StudentResult},
    {path: '/FeeStructure', exact: true, name: lang[10],db_name: 'Fee Structure', component: FeeStructure},
    {path: '/FeeStructure/AddFee', exact: true, name: lang[61],db_name: 'Add Fee', component: AddFee},
    {path: '/FeeStructure/EditFee/:id', exact: true, name: lang[62],db_name: 'Edit Fee', component: EditFee},
    {path: '/StudentFee', exact: true, name: lang[11],db_name: 'Student Fee', component: StudentFee},
    {path: '/Staff', exact: true, name: lang[15],db_name: 'Staff', component: Staff},
    {path: '/Staff/create', exact: true, name: lang[63],db_name: 'Staff Create', component: CreateStaff},
    {path: '/Staff/Edit/:id', exact: true, name: lang[64],db_name: 'Staff Edit', component: StaffEdit},
    {path: '/Staff/delete/:id', exact: true, name: 'Staff Delete',db_name: 'Staff Delete', compoent: StaffDelete},
    {path: '/Schools', exact: true, name: lang[30],db_name: 'Schools', component: Schools},
    {path: '/SchoolEdit/:id', exact: true, name: lang[34],db_name: 'School Edit', component: SchoolEdit},
    {path: '/Schools/View/:id', exact: true, name: 'School View',db_name: 'School View', component: SchoolView},
    {path: '/Schools/Detail/:id', exact: true, name: 'School Detail',db_name: 'School Detail', component: SchoolDetail},
    {path: '/Schools/Edit/:id', exact: true, name: lang[34], db_name: 'School Edit',component: SchoolEdit},
    {path: '/Users/UserEdit/:id', exact: true, name: lang[66],db_name: 'User Edit', component: UserEdit},
    {path: '/Users/createUser', exact: true, name: lang[65],db_name: 'User Create', component: CreateUser},
    {path: '/Students/edit/:id', exact: true, name: lang[35], db_name: 'Student Edit',component: EditStudent},
    {path: '/Students/create', exact: true, name: lang[36],db_name: 'Student Create', component: CreateStudent},
    {path: '/Classes/create', exact: true, name: lang[51],db_name: 'Class Create', component: CreateClass},
    {path: '/Classes/edit/:id', exact: true, name: lang[52],db_name: 'Class Edit', component: EditClass},
    {path: '/Attandance', exact: true, name: lang[17], db_name: 'Attendance',component: Attandance},
    {path: '/StaffAttandance', exact: true, name: lang[18],db_name: 'Staff Attendance', component: StaffAttandance},
    {path: '/NewStaffAttendance/:school_id/:date', exact: true, name: lang[18],db_name: 'New Staff Attendance', component: NewStaffAttendance},
    {path: '/StaffAttandance/StaffAttendanceView/:id', exact: true, name: lang[37],db_name: 'Staff Attendance View', component: StaffAttendanceView},
    {path: '/NewAttendance/:batch_id/:class_id/:date', exact: true, name: lang[39],db_name: 'New Attendance', component: NewAttendance},
    {path: '/Attandance/AttendanceView/:id', exact: true, name: lang[40],db_name: 'Attendance View', component: AttendanceView},
    {path: '/Attandance/AttendanceView/EditAttendance/:id', exact: true, name: lang[38],db_name: 'Edit Attendance', component: EditAttendance},
    {path: '/Batches/Add', exact: true, name: lang[54],db_name: 'Year OF Entry create', component: CreateBatch},
    {path: '/Batches/edit/:id', exact: true, name: lang[55], db_name: 'Year Of Entry Edit', component: EditBatch},
    {path: '/Batches/detail/:id', exact: true, name: lang[55], db_name: 'Year Of Entry Edit', component: batchesDetail},
    {path: '/Subjects/Add', exact: true, name: lang[56],db_name: 'Subject Add', component: CreateSubject},
    {path: '/ResultCategory/Add', exact: true, name: lang[58], db_name: 'Result Category Create',component: CreateCategory},
    {path: '/Students/Profile/:id', exact: true, name: lang[41],db_name: 'Student Profile', component: StudentProfile},
    {path: '/Subjects/edit/:id', exact: true, name: lang[57],db_name: 'Subject Edit', component: EditSubject},
    {path: '/ResultCategory/Add', exact: true, name: lang[58], db_name: 'Result Category Create', component: CreateCategory},
    {path: '/Users', exact: true, name: lang[16],db_name: 'Users', component: Users},
    {path: '/Settings', exact: true, name: lang[19], db_name: 'Settings',component: Settings},
    {path: '/Schools/create/branch/:id', exact: true, name: lang[72],db_name: 'School Create Branch', component: create_branch},
    {path: '/Schools/create', exact: true, name: lang[71],db_name: 'School Create', component: CreateSchool},
    {path: '/ResultCategory/edit/:id', exact: true, name: lang[59], db_name: 'Result Category Edit', component: EditResultCategory},
    {path: '/Library/create/:id', exact: true, name: lang[33], db_name: 'Library Create', component: create_library},
    {path: '/Library', exact: true, name: lang[22],db_name: 'Library', component: Library},
    {path: '/folder/create', exact: true, name: lang[24],db_name: 'Folder Create', component: create_folder},
    {path: '/library/folder/view/:id', exact: true, name: lang[42],db_name: 'Library Folder View', component: view_library_files},
    {path: '/headOffice/Settings', exact: true, name: lang[20],db_name: 'Head Office Settings', component: headoffice_settings},
    {path: '/FeeVoucherList/FeeVoucher', exact: true, name: lang[48], db_name: 'Fee Voucher',component: FeeVoucher},
    {path: '/FeeVoucherList', exact: true, name: lang[12],db_name: 'Fee Voucher List', component: FeeVoucherList},
    {path: '/StudentFee/singleVoucherFee', exact: true, name: lang[49], db_name: 'Single Fee Voucher',component: singleVoucherFee},
    {path: '/StudentFee/singleVoucherFee/detail/:id', exact: true, name: lang[50], db_name: 'Single Fee Voucher Fee Detail',component: singleVoucherFee_detail},
    {path: '/FeeVoucherList/VoucherDetail/:id', exact: true, name: lang[43],db_name: 'Voucher Detail', component: VoucherDetail},
    {path: '/FeeVoucherDetailList', exact: true, name: lang[13],db_name: 'Fee Voucher Detail', component: FeeVoucherDetailList},
    {path: '/FeeVoucherList/VoucherDetailEdit/:id', exact: true, name: lang[44],db_name: 'Voucher Detail Edit', component: VoucherDetailEdit},
    {path: '/FeeDefaulter', exact: true, name: lang[14],db_name: 'Fee Defaulter', component: FeeDefaulter},
    {path: '/reporting', exact: true, name: 'Reporting',db_name: 'Reporting', component: reportings},
    {path: '/reporting/invoice/view/:id', exact: true, name: 'Reporting',db_name: 'Reporting Invoice', component: reporting_detail},
    {path: '/invoice/create', exact: true, name: 'Create Invioce',db_name: 'Invoice Create', component: create_invoice},
    {path: '/reporting/invoice/edit/:id', exact: true, name: 'Edit Invioce',db_name: 'Reporting Invoice Edit', component: edit_invoice},
    {path: '/logs', exact: true, name: lang[27],db_name: 'Logs', component: logs},
    {path: '/Events', exact: true, name: lang[26],db_name: 'Events', component: Events},
    {path: '/Events/create', exact: true, name: lang[45],db_name: 'Event Create', component: Event_create},
    {path: '/Events/edit/:id', exact: true, name: lang[46],db_name: 'Event Edit', component: Event_edit},
    {path: '/UpdateResult/bulk', exact: true, name: lang[47],db_name: 'Result Bulk', component: bulk_update_result},
    {path: '/TimeTable', exact: true, name: lang[25],db_name: 'TimeTable', component: TimeTable},
    {path: '/EmailTemplate', exact: true, name: lang[77],db_name: 'Email Template', component: EmailTemplate},
    {path: '/SMSTemplate', exact: true, name: lang[78], db_name: 'SMS Template',component: SMSTemplate},
    {path: '/Homework', exact: true, name: lang[84], db_name: 'HomeWork',component: Homework},
    {path: '/payVoucherFee/:id', exact: true, name: lang[86],db_name: 'Pay Voucher Fee', component: pay_voucher},
    {path: '/BioMetricAttendance', exact: true, name: lang[83],db_name: 'Bio Metric Attendance', component: BioMetricAttendance},
    {path: '/FeeVoucherList/importVouchers', exact: true, name: lang[85],db_name: 'Import Voucher', component: importVouchers},
    {path: '/SendCustomEmail', exact: true, name: lang[92],db_name: 'Send SMS_Email', component: SendCustomEmail},
    {path: '/systemConfiguration', exact: true, name: lang[92],db_name: 'System Configuration', component: systemConfiguration},
    {path: '/FeeComponent', exact: true, name: lang[94],db_name: 'Fee Components', component: FeeComponent_route},
    {path: '/FeeComponent/create', exact: true, name: lang[95],db_name: 'Fee Components', component: FeeComponent_create},
    {path: '/FeeComponent/edit/:id', exact: true, name: lang[96],db_name: 'Fee Components', component: FeeComponent_edit},
    {path: '/Notifications', exact: true, name: lang[97],db_name: 'Notifications', component: Notifications},
    {path: '/subscription', exact: true, name: lang[98],db_name: 'subscription', component: subscription},
    {path: '/TeacherTimeTable', exact: true, name: lang[99],db_name: 'TeacherTimeTable', component: TeacherTimeTable},
    {path: '/Attandance/report', exact: true, name: lang[99],db_name: 'attendanceReport', component: attendanceReport},
    {path: '/Schools/deleted', exact: true, name: lang[101],db_name: 'Deleted School', component: deletedSchool},
    {path: '/receivePayment', exact: true, name: lang[104],db_name: 'Receive Payment', component: receivePayment},
    {path: '/feeVoucherReporting', exact: true, name: lang[107],db_name: 'Fee Voucher Reporting', component: feeVoucherReporting},
    {path: '/FeeVoucherStudentWise', exact: true, name: lang[108],db_name: 'Fee Voucher Student Wise', component: feeVoucherStudentWise},
    {path: '/ReportingFeeDefaulters', exact: true, name: lang[109],db_name: 'Fee Defaulters Reporting', component: ReportingFeeDefaulters},
    {path: '/ReportingStudentList', exact: true, name: lang[110],db_name: 'Student List', component: ReportingStudentList},
    {path: '/ReportingStudentDetail', exact: true, name: lang[110],db_name: 'Student Detail', component: ReportingStudentDetail},
    {path: '/ParentReporting', exact: true, name: lang[110],db_name: 'Taxes Parent Reporting', component: ParentReporting}

];
let a = localStorage.getItem('permission');
if (localStorage.getItem('permission')) {
    let perm = localStorage.getItem('permission').split(',');
    var da = [];
    let coun = 0;
    item.forEach(function (item) {
        perm.forEach(function (item_static) {
            if (item.db_name === item_static) {
                da[da.length] = item;
            } else {
            }
        });
        coun++;
    });
} else {
   var da = [
       {path: '/', exact: true, name: lang[0], db_name: 'Dashboard', component: Dashboard},
       {path: '/dashboard', exact: true, name: lang[0], db_name: 'Dashboard', component: Dashboard},
    ]
}
const routes = da;
export default routes;

