import {Component} from 'react';
import axios from 'axios';

class StaffDelete extends Component {

  constructor(props) {
    alert('asd');
    super(props);
    this.deleteStaff();
    this.state = {
      modal: false,
      large: false,
      small: false,
      primary: false,
      primary1: false,
      success: false,
      warning: false,
      danger: false,
      info: false,
    };
  }

  deleteStaff() {


    let id = this.props.match.params.id;
    alert(id);
    const account = {
      id: localStorage.getItem('id'),
      delete_id: this.props.match.params.id,
      token: localStorage.getItem('token'),
      role_id: localStorage.getItem('role'),
    };
    let uri = global.BASE_URL + '/deletestaffmember/';
    axios.post(uri, account).then((response) => {
      console.log(response);
      alert('Staff Member Deleted');
      this.props.history.push('/Staff');
    }).catch(error => {
      console.log(error);
    });
  }

  componentDidMount() {
    window.onload(this.deleteStaff());
  }

  render() {
    window.onload(this.deleteStaff());
    return ('Successful');
  }
}

export default StaffDelete;
