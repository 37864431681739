import './../../../index';
import React, {Component} from 'react';
import {
    Card, CardBody, CardHeader, Col,
    Row, Input, Form, FormGroup, Label, Button, Modal, ModalBody, ModalFooter, ModalHeader
} from 'reactstrap';
import {Link} from 'react-router-dom';
import axios from "axios/index";
import ReactTable from 'react-table';
import lang from "../../../Language/en-us";
import PNotify from 'pnotify/dist/es/PNotify';
import './../../checkbox.css';
class Tables extends Component {
    constructor(props) {
        super(props);
        this.getFeeStructure();
        this.getSchools();
        this.state = {
            components: [],
            batches: [],
            schools: [],
            batch: '',
            loading: true,
            role_id: localStorage.getItem('role'),
            school_id: localStorage.getItem('school_id'),
            checked_all: false,
        };
        this.school = this.school.bind(this);
        this.toggle = this.toggle.bind(this);
        this.toggleInfo = this.toggleInfo.bind(this);
        this.transfer = this.transfer.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getBatches();
    }
    getBatches(){
        const accountss = {
            id: localStorage.getItem('id'),
            school_id: localStorage.getItem('school_id'),
            token: localStorage.getItem('token'),
            role: localStorage.getItem('role'),
            filter: '1',
        };
        let uris = global.BASE_URL + '/BatchListForSchool';

        axios.post(uris, accountss).then((response) => {
            this.setState({
                batches: response.data
            });
        }).catch(error => {
            // Error Handling
        });
    }
    transfer(e) {
        this.setState({
            transfer: e.target.value
        })
    }
    toggle() {
        this.setState({
            modal: !this.state.modal,
        });
    }

    toggleInfo() {
        // alert(this.state.transfer);
        if (this.state.transfer === 'undefined') {
            PNotify.alert({
                text: "Select atleast 1 student to continue!",
                type: 'error'
            });
            // alert('Select atleast 1 student to continue!');
            return false;
        } else {
            this.setState({
                info: !this.state.info,
            });
        }
    }

    catego(e) {
        this.setState({
            catego: e.target.value
        })
    }

    getSchools() {
        const account = {
            id: localStorage.getItem('id'),
            school_id: localStorage.getItem('school_id'),
            token: localStorage.getItem('token'),
            role: localStorage.getItem('role')
        };
        let uri = global.BASE_URL + '/getschoollist';

        axios.post(uri, account).then((response) => {
            this.setState({schools: response.data});
        }).catch(error => {
            //Error handling
        });
    }

    school(e) {
        this.setState({
            school: e.target.value,
            loading: true
        })
        const account = {
            id: localStorage.getItem('id'),
            school_id: localStorage.getItem('school_id'),
            school_id_selected: e.target.value,
            token: localStorage.getItem('token'),
            role: localStorage.getItem('role'),
            filter: '1',
        };
        let uri = global.BASE_URL + '/FeeStructureForSchool';

        axios.post(uri, account).then((response) => {
            this.setState({
                components: response.data,
                loading: false
            });
        }).catch(error => {
            // Error Handling
        });

        const accountss = {
            id: localStorage.getItem('id'),
            school_id: e.target.value,
            token: localStorage.getItem('token'),
            role: localStorage.getItem('role'),
            filter: '1',
        };
        let uris = global.BASE_URL + '/BatchListForSchool';

        axios.post(uris, accountss).then((response) => {
            this.setState({
                batches: response.data
            });
        }).catch(error => {
            // Error Handling
        });
    }

    getFeeStructure() {
        const account = {
            id: localStorage.getItem('id'),
            school_id: localStorage.getItem('school_id'),
            token: localStorage.getItem('token'),
            role: localStorage.getItem('role'),
        };
        let uri = global.BASE_URL + '/FeeStructureForSchool';

        axios.post(uri, account).then((response) => {
            this.setState({
                components: response.data,
                loading: false
            });
        }).catch(error => {
            // Error handling
        });
    }

    batch = (e) => {
        this.setState({batch: e.target.value});
    }
    handleSubmit(e) {

        if (this.state.batch === '') {
            PNotify.alert({
                text: "Select Batch to continue!",
                type: 'error'
            });
            return false;
        }
        e.preventDefault();
        var serialize = require('form-serialize');
        var form = document.querySelector('#form-data');

        var obj = serialize(form, {hash: true});
        const accountss = {
            transfer: obj.transfer,
            batch_id: this.state.batch,
            id: localStorage.getItem('id'),
            school_id: this.state.school,
            token: localStorage.getItem('token'),
            role_id: localStorage.getItem('role'),
        };
        let uri = global.BASE_URL + '/transferFeeStructureToNewBatch';
        axios.post(uri, accountss).then((response) => {
            if (response.data === 'validation') {
                PNotify.alert({
                    text: "Select atleast one Fee Structure!",
                    type: 'error'
                });
                // alert('Select atleast one student! Select batch and class.');
            } else if (response.data === 'success') {
                PNotify.alert({
                    text: "Data updated successfully!",
                    type: 'success'
                });
                // alert('Data Update Successfully');
                this.setState({loadingss: false});
                window.location.reload();
            } else {
                PNotify.alert({
                    text: "Some thing went wrong try again latter!",
                    type: 'error'
                });
            }
        }).catch(error => {
            PNotify.alert({
                text: "Some thing went wrong try again latter!",
                type: 'error'
            });
            console.log(error);
        });

    }
    render() {
        let batches = this.state.batches;
        let components = this.state.components;
        let schools = this.state.schools;
        let content = this.state.loading ? <div className="loading"/> : <Form id="form-data"><ReactTable
            data={components}
            columns={[
                {
                    Header: "Batch",
                    accessor: 'batch'
                },
                {
                    Header: 'Class',
                    accessor: 'class'
                },
                {
                    Header: 'Fee',
                    Cell: row => (
                        <div style={{float: "right"}}>
                            {row.original.currency} {row.original.fee_struc}
                        </div>)
                },
                {
                    Header: 'Actions',
                    Cell: row => (
                        <div>
                            <Link className={"btn btn-primary btn-sm"}
                                  to={{pathname: `/FeeStructure/EditFee/${row.original.id}`}}><i
                                className="icon-pencil"/></Link>


                            <Label className="containerss" style={{    marginLeft: '24px',marginBottom: '17px',display: 'inline-block'}}>
                                <Input type="checkbox" id={row.original.id}
                                       defaultChecked={!this.state.checked_all}
                                       required
                                       name="transfer[]" value={row.original.id}/>
                                <div className="checkmark"></div>
                            </Label>
                        </div>)
                },
            ]}
            defaultPageSize={10}
            className="-striped -highlight -sm -responsive"
            showPagination={true}
            showPaginationTop={false}
            showPaginationBottom={true}
            pageSizeOptions={[5, 10, 20, 25, 50, 100]}
            filterable
        /></Form>;
        return (
            <div className="animated fadeIn">

                <Row>
                    <Col>
                        <Card>

                            <CardHeader>
                                <i className="fa fa-align-justify"/> {lang[10]}
                                <Link to={{pathname: `/FeeStructure/AddFee`}}
                                      className={"btn btn-info btn-sm pull-right"}> <i
                                    className="icon-plus"/> {lang[28]}</Link>
                                <div className="pull-right mr-1">
                                    <div className="btn btn-success btn-md"
                                         style={{padding: "0px 10px 5px 10px", backgroundColor: "#317fca"}}
                                         onClick={this.toggleInfo}>
                                        <i className="fas fa-forward"><span className="ml-1">{lang[75]}</span></i>
                                    </div>
                                </div>
                            </CardHeader>

                            <CardBody>
                                {(() => {
                                    switch (this.state.role_id) {
                                        case '2':
                                            return <Form>
                                                <Row>
                                                    <FormGroup>
                                                        <Col md="12">
                                                            <Label htmlFor="selectSm"> </Label>
                                                        </Col>
                                                        <Col xs="12" md="12">
                                                            <Input type="select" name="clss" id="SelectLm"
                                                                   onChange={this.school}>
                                                                <option value={"All"}>Select School</option>
                                                                {schools.map(item =>
                                                                    <option key={item.id}
                                                                            value={item.id}>{item.name}</option>
                                                                )};
                                                            </Input>
                                                        </Col>
                                                    </FormGroup>
                                                </Row>
                                            </Form>;
                                        case '1':
                                            return <Form>
                                                <Row>
                                                    <FormGroup>
                                                        <Col md="12">
                                                            <Label htmlFor="selectSm"> </Label>
                                                        </Col>
                                                        <Col xs="12" md="12">
                                                            <Input type="select" name="clss" id="SelectLm"
                                                                   onChange={this.school}>
                                                                <option value={"All"}>Select School</option>
                                                                {schools.map(item =>
                                                                    <option key={item.id}
                                                                            value={item.id}>{item.name}</option>
                                                                )};
                                                            </Input>
                                                        </Col>
                                                    </FormGroup>
                                                </Row>
                                            </Form>;
                                    }
                                })()}
                                {content}

                                <Modal isOpen={this.state.info} toggle={this.toggleInfo}
                                       className={'modal-info ' + this.props.className}>
                                    <ModalHeader toggle={this.toggleInfo}>Select Next Batch And Class</ModalHeader>
                                    <ModalBody>
                                        {(() => {
                                            if (this.state.role_id === '1' || this.state.role_id === '2') {
                                                return <FormGroup>
                                                    <Label htmlFor="selectSm">Select School </Label>
                                                    <Input type="select" name="clss" id="SelectLm"
                                                           onChange={this.school}>
                                                        <option value={""}>Select School</option>
                                                        {schools.map(item =>
                                                            <option key={item.id} value={item.id}>{item.name}</option>
                                                        )};
                                                    </Input>
                                                </FormGroup>;
                                            }
                                        })()}
                                        <FormGroup>
                                            <Label htmlFor="nf-email">Batch</Label>
                                            <select name={"batch"} className={"col-12 form-control"} required
                                                    onChange={this.batch}>
                                                <option value="">Select Batch</option>
                                                {batches.map(item =>
                                                    <option key={item.id} value={item.id}>{item.name}</option>
                                                )};
                                            </select>
                                        </FormGroup>

                                    </ModalBody>
                                    <ModalFooter>
                                        <Button color="primary" onClick={this.handleSubmit}>Transfer</Button>{' '}
                                        <Button color="secondary" onClick={this.toggleInfo}>Cancel</Button>
                                    </ModalFooter>
                                </Modal>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>

        );
    }

}

export default Tables;
