import './../../../index';
import React, {Component} from 'react';
import {
    Badge, Card, CardBody, CardHeader, Col, Row,Input
} from 'reactstrap';
import axios from "axios/index";
import {Link} from 'react-router-dom';
import CsvDownloader from 'react-csv-downloader';
import PNotify from 'pnotify/dist/es/PNotify';
import lang from "../../../Language/en-us";

// import lang from "../../../Language/fr-ca";

class Users extends Component {
    constructor(props) {
        super(props);
        this.getSchools();
        this.state = {
            schools: [],
            items: [],
            loading: true,
            loadingButton: false,
            pages: 0,
            count: 20,
            user_name_search: '',
            hasError: false,
            enable: true,
            seleted_school: false,
            enableSave: false,
            enableDeleteButton: true,
            role_id: localStorage.getItem('role'),
            school: localStorage.getItem('school_id'),
        };
        this.handlePageChange = this.handlePageChange.bind(this);
        this.loadMoreData = this.loadMoreData.bind(this);
    }

    handlePageChange(pageNumber) {
        console.log(`active page is ${pageNumber}`);
        this.setState({activePage: pageNumber});
    }

    componentDidMount() {
        let uri = global.BASE_URL + '/usersList';
        const account = {
            id: localStorage.getItem('id'),
            school_id: localStorage.getItem('school_id'),
            token: localStorage.getItem('token'),
            count: this.state.count,
            role: localStorage.getItem('role')
        };
        axios.post(uri, account).then((response) => {
            this.setState({
                items: response.data,
                loading: false,
                enableSave: true
            });
            if (response.data !== '' || response.data !== null) {
                this.setState({
                    count: this.state.count + 20
                })
            }
        }).catch(error => {
        });
    }

    deleteUser(e) {
        e.preventDefault();
        let confirm = window.confirm('Do you really want to delete?');
        if (confirm) {
            const account = {
                delete_id: e.target.id,
                id: localStorage.getItem('id'),
                token: localStorage.getItem('token'),
                role_id: localStorage.getItem('role'),
            };

            let uri = global.BASE_URL + '/deleteuser';

            axios.post(uri, account).then((response) => {
                // alert(response.data);
                if (response.data === 'Staff Deleted!') {
                    PNotify.alert({
                        text: "User has been deleted successfully!",
                        type: 'success'
                    });
                    // alert('User has been deleted successfully');
                    // window.location.reload();
                }
                if (response.data === 'Cannot!') {
                    PNotify.alert({
                        text: "You cannot delete this user!",
                        type: 'error'
                    });
                    // alert('You cannot delete your own account!');
                } else {
                    PNotify.alert({
                        text: "Some thing went wrong try again latter!",
                        type: 'error'
                    });
                    // alert('Error, Try again later!');
                }
            }).catch(error => {
                PNotify.alert({
                    text: "Some thing went wrong try again latter!",
                    type: 'error'
                });
                // alert('Something went wrong, try again later');
            });
        }

    }
    getSchools() {
        const account = {
            id: localStorage.getItem('id'),
            school_id: localStorage.getItem('school_id'),
            token: localStorage.getItem('token'),
            role: localStorage.getItem('role')
        };
        let uri = global.BASE_URL + '/getschoollist';

        axios.post(uri, account).then((response) => {
            console.log(response.data.length);
            if (response.data.length === '1') {
                this.setState(
                    {
                        seleted_school: true
                    }
                );
            }
            this.setState(
                {
                    schools: response.data
                }
            );
        }).catch(error => {
        });
    }
    user_name_search = (e)=>{
        this.setState({user_name_search:e.target.value})
    }
    search = (e) =>{
        this.setState({
            loading: true,
            enableSave: false
        });
        const account = {
            id: localStorage.getItem('id'),
            school_id: this.state.school,
            token: localStorage.getItem('token'),
            role: localStorage.getItem('role'),
            user_name_search: this.state.user_name_search,
            count: this.state.count,
            filter: '1',
        };
        let uri = global.BASE_URL + '/usersList';

        axios.post(uri, account).then((response) => {
            this.setState({
                items: response.data,
                count: this.state.count + 20,
                loading: false,
                enableSave: true
            });
        }).catch(error => {
        });
    }
    school = (e)=> {
        this.setState({
            school: e.target.value,
        });
    }
    loadMoreData() {
        this.setState({
            enable: false,
            loadingButton: true,
            enableSave: false,
        })
        const account = {
            id: localStorage.getItem('id'),
            school_id: localStorage.getItem('school_id'),
            token: localStorage.getItem('token'),
            count: this.state.count,
            role: localStorage.getItem('role')
        };
        // const {history} = this.props;
        let uri = global.BASE_URL + '/usersList';

        axios.post(uri, account).then((response) => {
            this.setState({
                items: response.data,
                count: this.state.count + 20,
                enable: true,
                loadingButton: false,
                loading: false,
                enableSave: true
            });
        }).catch(error => {
        });
    }

    render() {
        const {items,schools} = this.state;
        const columns = [{
            id: 'name',
            displayName: 'Name'
        }, {
            id: 'role_name',
            displayName: 'Role'
        }, {
            id: 'email',
            displayName: 'Email'
        }, {
            id: 'username',
            displayName: 'Username'
        }, {
            id: 'school_name',
            displayName: 'School'
        }];
        let content = this.state.loading ? <div className="loading"/> : <div>
            <div className="row">
                {items.map(item =>
                    <div className="col-md-6 col-xl-3">
                        <div className="card m-b-30">
                            <div className="card-body row">
                                <div className="col-12 card-title align-self-center mb-0">
                                    <h5>{item.name}</h5>
                                    <p className="m-0">{item.role_name}</p>
                                </div>
                            </div>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item"><i style={{fontSize: 28 + "px"}}
                                                                   className="fa fa-envelope float-right"/>

                                    <div id="list-content">
                                        <a href={"mailto:" + item.email}>{item.email}</a>
                                    </div>
                                </li>
                                <li className="list-group-item"><i style={{fontSize: 28 + "px"}}
                                                                   className="fa fa-user float-right"/>

                                    {(() => {
                                        if (item.username === '' || item.username === null) {
                                            return <div id="list-content">
                                                No Username Found
                                            </div>
                                        } else {
                                            return <div id="list-content">
                                                {item.username}
                                            </div>
                                        }
                                    })()}
                                </li>
                                <li className="list-group-item"><i style={{fontSize: 28 + "px"}}
                                                                   className="fas fa-school float-right"/>

                                    <div id="list-content">
                                        {item.school_name}
                                    </div>
                                </li>
                                <li className="list-group-item">
                                    {item.status === '1' || item.status === 1 || item.status === "1" ?
                                        <Badge className="badge-success float-right">Active</Badge> :
                                        <Badge className="badge-danger float-right">In Active</Badge>
                                    }
                                </li>
                            </ul>
                            <div className="card-body">
                                <div className="float-right btn-group btn-group-sm">
                                    <a href={'javascript:void(0)'} onClick={this.deleteUser} id={item.id}
                                       className="btn btn-danger btn-sm mr-1"><i className="fa fa-trash"/></a>
                                    <Link to={{pathname: `/Users/UserEdit/${item.id}`}}
                                          className="btn btn-primary tooltips" data-placement="top"
                                          data-toggle="tooltip"
                                          data-original-title="Edit"><i className="fa fa-pencil"/> </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <Row>
                <Col xs="12" md="12" lg="12" id="load-more-button">
                    <button className="btn btn-success btn-md" disabled={!this.state.enable}
                            onClick={this.loadMoreData}>
                        {(() => {
                            if (this.state.loadingButton) {
                                return <i className="icon icon-plus"><span className="ml-1">Loading</span></i>
                            }
                            else {
                                return <i className="icon icon-plus"><span className="ml-1">Load More</span></i>
                            }
                        })()}
                    </button>
                </Col>
            </Row>
        </div>;
        if (this.state.hasError) {
            this.props.history.push('/404')
        }
        return (
            <div className="animated fadeIn">
                <Row>
                    <Col>
                        <Card>
                            <CardHeader>
                                <i className="fa fa-align-justify"/> {lang[16]}
                                <Link to={{pathname: "/Users/createUser"}} className="btn btn-info btn-sm pull-right"><i
                                    className="icon-plus"/> {lang[28]}</Link>
                                <div className="pull-right mr-1">
                                    <CsvDownloader
                                        filename="users"
                                        datas={items}
                                        columns={columns}
                                        text="Save as CSV"
                                    >
                                        <button className="btn btn-sm btn-success" disabled={!this.state.enableSave}><i
                                            className="fa fa-download mr-1"/>{lang[76]} CSV
                                        </button>
                                    </CsvDownloader>
                                </div>
                            </CardHeader>
                            <CardBody>
                                {/*{content}*/}
                                <Row>
                                    {(() => {
                                        switch (this.state.role_id) {
                                            case '2':
                                                return <Col md={"5"}>
                                                    <Input type="select" name="clss" id="SelectLm"
                                                           onChange={this.school} className="">
                                                        <option value={""}>Select School</option>
                                                        {schools.map(item =>
                                                            <option selected={!this.state.seleted_school}
                                                                    key={item.id}
                                                                    value={item.id}>{item.name}</option>
                                                        )};
                                                    </Input>
                                                </Col>;
                                            case '1':
                                                return <Col md={"5"}>
                                                    <Input type="select" name="clss" id="SelectLm"
                                                           onChange={this.school} className="">
                                                        <option value={""}>Select School</option>
                                                        {schools.map(item =>
                                                            <option selected={!this.state.seleted_school}
                                                                    key={item.id}
                                                                    value={item.id}>{item.name}</option>
                                                        )};
                                                    </Input>
                                                </Col>;
                                        }
                                    })()}
                                    <Col md={"5"}>
                                        <input type={"text"} name={"user_name"} id={"user_name"}
                                               className={"form-control"}
                                               onChange={this.user_name_search}
                                               placeholder={"Search by Name, UserName,RoleName and Email..."}/>
                                    </Col>
                                    <Col md={"2"}>
                                        <button id={"search"} className={"btn btn-info"} onClick={this.search} style={{color:'white'}}><i className={"fa fa-search"}></i>{' '}
                                            Search</button>
                                    </Col>
                                </Row>

                                {items.map(item =>
                                    <Row style={{border: '1px solid #53535387', marginTop: '5px'}}>
                                        {/*<Col xs="2">*/}
                                        {/*<div><img src={item.picture} style={{*/}
                                        {/*height: '100px',*/}
                                        {/*marginLeft: '12px',*/}
                                        {/*width: '110px',*/}
                                        {/*marginTop: '7px'*/}
                                        {/*}}></img></div>*/}
                                        {/*</Col>*/}
                                        <Col xs="4">
                                            <p><b style={{color: 'gray', fontWeight: '400'}}>Name</b>: {item.name}</p>
                                            <p><b style={{color: 'gray', fontWeight: '400'}}>Role</b>: {item.role_name}
                                            </p>
                                            <p><b style={{color: 'gray', fontWeight: '400'}}>Email</b>: {item.email}</p>
                                        </Col>
                                        <Col xs="8">
                                            <p><b style={{color: 'gray', fontWeight: '400'}}>UserName</b>: {item.email}
                                            </p>
                                            <p><b style={{color: 'gray', fontWeight: '400'}}>Status</b>:
                                                {item.status === '1' || item.status === 1 || item.status === "1" ?
                                                    <Badge className="badge-success">Active</Badge> :
                                                    <Badge className="badge-danger">In Active</Badge>
                                                }
                                            </p>
                                            <div style={{marginTop: '-38px', marginRight: '10px'}}>
                                                <a href={'javascript:void(0)'} onClick={this.deleteUser} id={item.id}
                                                   className="btn btn-danger btn-sm mr-1 float-right">
                                                    <i style={{fontSize: '20px'}} className="fa fa-trash"/>
                                                </a>
                                                <Link to={{pathname: `/Users/UserEdit/${item.id}`}}
                                                      className="btn btn-primary btn-sm mr-1 tooltips float-right"
                                                      data-placement="top"
                                                      data-toggle="tooltip"
                                                      data-original-title="Edit"><i style={{fontSize: '20px'}}
                                                                                    className="fa fa-pencil"/> </Link>
                                            </div>
                                        </Col>
                                    </Row>
                                )}
                                <Row>
                                    <Col xs="12" md="12" lg="12" id="load-more-button" style={{marginTop: '15px'}}>

                                        {(() => {
                                            if (this.state.loadingButton) {
                                                return <button className="btn btn-success" disabled={!this.state.enable}
                                                               onClick={this.loadMoreData}>
                                                    <i className="fa fa-spinner"></i>{' '}
                                                    Loading
                                                </button>
                                            }
                                            else {
                                                return <button className="btn btn-success" disabled={!this.state.enable}
                                                               onClick={this.loadMoreData}>
                                                    <i className="icon icon-plus"></i>{' '}
                                                    Load More
                                                </button>
                                            }
                                        })()}

                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>

        );
    }
}

export default Users;
