import React, { Component } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Alert,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import axios from "axios";
import { Link } from "react-router-dom";
import PNotify from "pnotify/dist/es/PNotify";
import lang from "./../../../Language/en-us";
import DatePicker from "react-datepicker";
import $ from "jquery";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { AppSwitch } from "@coreui/react";
// import lang from './../../../Language/fr-ca'

export default class CreateStudent extends Component {
  constructor(props) {
    super(props);
    var today = new Date();
    if (today.getMonth() + 1 <= 9) {
      var month = "0" + (today.getMonth() + 1);
    } else {
      var month = today.getMonth() + 1;
    }
    if (today.getDate() <= 9) {
      var day = "0" + today.getDate();
    } else {
      var day = today.getDate();
    }
    var date = today.getFullYear() + "-" + month + "-" + day;
    // 2013-01-08

    this.getClasses();
    this.getBatch();
    this.getSchools();
    this.student_info();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      collapse: true,
      fadeIn: true,
      large: false,
      classes: [],
      students_list: [],
      batch: [],
      schools: [],
      guardians: [],
      finger_count: 0,
      enable: true,
      loading: true,
      student_name: "",
      guardian_id: "",
      guardian_name: "",
      guardian_mob: "",
      guardian_email: "",
      relation: "",
      selected_school: "",
      student_id: "",
      roll_number: "",
      school_id: "",
      guardian_name_model: "",
      guardian_cnic_model: "",
      guardian_mob_model: "",
      guardian_email_model: "",
      relation_model: "",
      guardian_cnic: "",
      date_of_birth: "",
      timeout: 300,
      file: null,
      success: false,
      error: false,
      validation: false,
      date: Date.now(),
      role_id: localStorage.getItem("role"),
      modal: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.school = this.school.bind(this);
    this.student_name = this.student_name.bind(this);
    this.gender = this.gender.bind(this);
    this.std_age = this.std_age.bind(this);
    this.student_id = this.student_id.bind(this);
    this.roll_number = this.roll_number.bind(this);
    this.classs = this.classs.bind(this);
    this.batche = this.batche.bind(this);
    this.guardian_name = this.guardian_name.bind(this);
    this.guardian_mob = this.guardian_mob.bind(this);
    this.relation = this.relation.bind(this);
    this.guardian_email = this.guardian_email.bind(this);
    this.image = this.image.bind(this);
    this.date = this.date.bind(this);
    this.guardian_cnic = this.guardian_cnic.bind(this);
    this.addGuardian = this.addGuardian.bind(this);
    this.saveGuardian = this.saveGuardian.bind(this);

    this.viewProfile = this.viewProfile.bind(this);

    this.guardian_name_model = this.guardian_name_model.bind(this);
    this.guardian_cnic_model = this.guardian_cnic_model.bind(this);
    this.guardian_mob_model = this.guardian_mob_model.bind(this);
    this.guardian_email_model = this.guardian_email_model.bind(this);
    this.relation_model = this.relation_model.bind(this);
    this.finger_button = this.finger_button.bind(this);
    this.student_info = this.student_info.bind(this);
    this.guardian_info = this.guardian_info.bind(this);
    this.fee_concession_info = this.fee_concession_info.bind(this);

    localStorage.setItem("finger_print1", "");
    localStorage.setItem("finger_print2", "");
    localStorage.setItem("finger_print3", "");
  }

  viewProfile(e) {
    this.setState({
      large: !this.state.large,
    });
  }

  componentDidMount() {
    $("#btn_student_info").css("background-color", "#46c446");
    $("#student_info_data").show();
    $("#guardian_info_data").hide();
    $("#fee_concession_info_data").hide();
  }

  student_info() {
    $("#btn_student_info").css("background-color", "#46c446");
    $("#btn_guardian_info").css("background-color", "#c8ced3");
    $("#btn_fee_concession_info").css("background-color", "#c8ced3");
    $("#student_info_data").show();
    $("#guardian_info_data").hide();
    $("#fee_concession_info_data").hide();
    $("#btn_student_info").css("background-color", "#46c446");
    $(".nav-tabs")
      .children()
      .eq(0)
      .addClass("react-tabs__tab react-tabs__tab--selected");
    $(".nav-tabs")
      .children()
      .eq(1)
      .removeClass("react-tabs__tab--selected");
    $(".nav-tabs")
      .children()
      .eq(2)
      .removeClass("react-tabs__tab--selected");
    // console.log($(".nav-tabs").children().eq(0).attr('class'));
  }

  guardian_info() {
    $("#btn_student_info").css("background-color", "#c8ced3");
    $("#btn_guardian_info").css("background-color", "#46c446");
    $("#btn_fee_concession_info").css("background-color", "#c8ced3");

    $("#student_info_data").hide();
    $("#guardian_info_data").show();
    $("#fee_concession_info_data").hide();
    $(".nav-tabs")
      .children()
      .eq(0)
      .removeClass("react-tabs__tab--selected");
    $(".nav-tabs")
      .children()
      .eq(1)
      .addClass("react-tabs__tab react-tabs__tab--selected");
    $(".nav-tabs")
      .children()
      .eq(2)
      .removeClass("react-tabs__tab--selected");
    console.log(
      $(".nav-tabs")
        .children()
        .eq(1)
        .attr("class")
    );
    // btn_guardian_info
  }

  fee_concession_info() {
    $("#btn_student_info").css("background-color", "#c8ced3");
    $("#btn_guardian_info").css("background-color", "#c8ced3");
    $("#btn_fee_concession_info").css("background-color", "#46c446");
    $("#student_info_data").hide();
    $("#guardian_info_data").hide();
    $("#fee_concession_info_data").show();
    $(".nav-tabs")
      .children()
      .eq(0)
      .removeClass("react-tabs__tab--selected");
    $(".nav-tabs")
      .children()
      .eq(1)
      .removeClass("react-tabs__tab--selected");
    $(".nav-tabs")
      .children()
      .eq(2)
      .addClass("react-tabs__tab react-tabs__tab--selected");
    // btn_fee_concession_info
  }

  saveGuardian() {
    if (
      this.state.guardian_name_model == "" ||
      this.state.guardian_cnic_model == "" ||
      this.state.guardian_mob_model == "" ||
      this.state.guardian_email_model == "" ||
      this.state.relation_model == ""
    ) {
      PNotify.alert({
        text: "Fill all the fields",
        type: "error",
      });
      return false;
    } else {
      this.setState({
        modal: !this.state.modal,
      });
    }
    var html =
      "<div class='row' style='margin-bottom:16px;'>\n" +
      "\n" +
      "                                            <div class='col-sm-3'>\n" +
      "                                                <FormGroup>\n" +
      '                                                    <Input type="text" id="nf-st_id" name="guardian_name[]"\n' +
      '                                                           class="form-control"  placeholder="Enter Guardian Name"\n' +
      '                                                           maxLength="50" value=\'' +
      this.state.guardian_name_model +
      "' required/>\n" +
      "                                                </FormGroup>\n" +
      "                                            </div>\n" +
      "                                            <div class='col-sm-2'>\n" +
      "                                                    <FormGroup>\n" +
      '                                                        <Input type="number" id="nf-st_id" name="guardian_cnic[]"\n' +
      '                                                               class="form-control" autocomplete="off" placeholder="Enter Guardian CNIC"\n' +
      '                                                               maxLength="13" value=\'' +
      this.state.guardian_cnic_model +
      '\' minLength="13" required >\n' +
      "                                                    </FormGroup>\n" +
      "                                            </div>\n" +
      "                                            <div class='col-sm-3'>\n" +
      "                                                <FormGroup>\n" +
      '                                                    <Input type="number" id="nf-st_id" name="guardian_mob[]"\n' +
      '                                                           class="form-control" autocomplete="off"\n' +
      '                                                           placeholder="Enter Guardian Contact Number"\n' +
      '                                                           maxLength="20" value=\'' +
      this.state.guardian_mob_model +
      "' required/>\n" +
      "                                                </FormGroup>\n" +
      "                                            </div>\n" +
      "                                            <div class='col-sm-2'>\n" +
      "                                                <FormGroup>\n" +
      '                                                    <Input type="email" id="nf-st_id" name="guardian_email[]"\n' +
      '                                                           class="form-control" autocomplete="off" placeholder="Enter Guardian Email"\n' +
      '                                                           maxLength="50" value=\'' +
      this.state.guardian_email_model +
      "' required/>\n" +
      "                                                </FormGroup>\n" +
      "                                            </div>\n" +
      "                                            <div class='col-sm-2'>\n" +
      "                                                <FormGroup>\n" +
      '                                                    <select name="relation[]" class="form-control col-12" value=\'' +
      this.state.relation_model +
      "' required\n" +
      "                                                        <option value=''>Select Relation</option>\n" +
      '                                                        <option value="father">Father</option>\n' +
      '                                                        <option value="Mother">Mother</option>\n' +
      '                                                        <option value="Sibling">Sibling</option>\n' +
      '                                                        <option value="Uncle/Aunt">Uncle/Aunt</option>\n' +
      '                                                        <option value="Others">Others</option>\n' +
      "                                                    </select>\n" +
      "                                                </FormGroup>\n" +
      "                                            </div>\n" +
      "                                        </div>";
    var element = document.getElementById("guardian_data");
    element.innerHTML += html;
  }

  guardian_name_model(e) {
    this.setState({ guardian_name_model: e.target.value });
  }

  guardian_cnic_model(e) {
    this.setState({ guardian_cnic_model: e.target.value });
  }

  guardian_mob_model(e) {
    this.setState({ guardian_mob_model: e.target.value });
  }

  guardian_email_model(e) {
    this.setState({ guardian_email_model: e.target.value });
  }

  relation_model(e) {
    this.setState({ relation_model: e.target.value });
  }

  date(e) {
    // alert(e);
    this.setState({
      date: e,
    });
    // let formatted_date =  e.getDate() + "-" + ( e.getMonth() + 1) + "-" +  e.getFullYear()
    let formatted_date =
      e.getFullYear() + "-" + (e.getMonth() + 1) + "-" + e.getDate();
    // alert(formatted_date);
    this.setState({ date_of_birth: formatted_date });
  }

  finger_button(e) {
    // console.log(localStorage.getItem('finger_print1'));
    if (
      localStorage.getItem("finger_print1") !== "" &&
      localStorage.getItem("finger_print1") !== "undefined"
    ) {
      this.setState({
        finger_print_val: 33,
        finger_count: 1,
      });
    }
    if (
      localStorage.getItem("finger_print2") !== "" &&
      localStorage.getItem("finger_print2") !== "undefined"
    ) {
      this.setState({
        finger_print_val: 66,
        finger_count: 2,
      });
    }
    if (
      localStorage.getItem("finger_print3") !== "" &&
      localStorage.getItem("finger_print3") !== "undefined"
    ) {
      this.setState({
        finger_print_val: 100,
        finger_count: 3,
      });
    }
  }

  addGuardian() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  guardian_cnic(e) {
    this.setState({ guardian_cnic: e.target.value });
    if (e.target.value.length == "13") {
      const account = {
        id: localStorage.getItem("id"),
        school_id: localStorage.getItem("school_id"),
        cnic: e.target.value,
        token: localStorage.getItem("token"),
        role_id: localStorage.getItem("role"),
      };
      let uri = global.BASE_URL + "/getGuardianByCnic";
      axios
        .post(uri, account)
        .then((response) => {
          this.setState({
            guardians: response.data,
            guardian_id: response.data[0].id,
            guardian_name: response.data[0].name,
            guardian_mob: response.data[0].contact_number,
            guardian_email: response.data[0].email,
            relation: response.data[0].relation,
          });
        })
        .catch((error) => {});

      const accounts = {
        id: localStorage.getItem("id"),
        school_id: localStorage.getItem("school_id"),
        cnic: e.target.value,
        token: localStorage.getItem("token"),
        role_id: localStorage.getItem("role"),
      };
      let uris = global.BASE_URL + "/getStudentsByParentsCnic";
      axios
        .post(uris, accounts)
        .then((response) => {
          this.setState({
            students_list: response.data,
          });
        })
        .catch((error) => {});
    }
  }

  school(e) {
    this.setState({
      school: e.target.value,
      school_id: e.target.value,
    });
  }

  guardian_email(e) {
    this.setState({
      guardian_email: e.target.value,
    });
  }

  student_name(e) {
    if (/^[0-9a-zA-Z \b]+$/.test(e.target.value)) {
      this.setState({
        student_name: e.target.value,
      });
    }
  }

  gender(e) {
    this.setState({
      gender: e.target.value,
    });
  }

  std_age(e) {
    this.setState({
      std_age: e.target.value,
    });
  }

  student_id(e) {
    if (/^[0-9\b]+$/.test(e.target.value)) {
      this.setState({
        student_id: e.target.value,
      });
    }
  }
  roll_number(e) {
    if (/^[0-9\b]+$/.test(e.target.value)) {
      this.setState({
        roll_number: e.target.value,
      });
    }
  }

  classs(e) {
    this.setState({
      classs: e.target.value,
    });
  }

  batche(e) {
    this.setState({
      batche: e.target.value,
    });
  }

  guardian_name(e) {
    if (/^[0-9a-zA-Z \b]+$/.test(e.target.value)) {
      this.setState({
        guardian_name: e.target.value,
      });
    }
  }

  guardian_mob(e) {
    this.setState({
      guardian_mob: e.target.value,
    });
  }

  relation(e) {
    this.setState({
      relation: e.target.value,
    });
  }

  image(e) {
    this.setState({ file: e.target.files[0] });
  }

  getClasses() {
    const account = {
      id: localStorage.getItem("id"),
      school_id: localStorage.getItem("school_id"),
      token: localStorage.getItem("token"),
      role_id: localStorage.getItem("role"),
    };
    let uri = global.BASE_URL + "/ClassListForSchool";
    axios
      .post(uri, account)
      .then((response) => {
        this.setState({ classes: response.data });
      })
      .catch((error) => {});
  }

  getBatch() {
    const account = {
      id: localStorage.getItem("id"),
      school_id: localStorage.getItem("school_id"),
      token: localStorage.getItem("token"),
      role: localStorage.getItem("role"),
    };
    let uri = global.BASE_URL + "/BatchListForSchool";
    axios
      .post(uri, account)
      .then((response) => {
        this.setState({ batch: response.data });
      })
      .catch((error) => {});
  }

  getBatchesBySchoolId = (e) => {
    this.setState({
      school: e.target.value,
    });

    const account = {
      id: localStorage.getItem("id"),
      school_id: e.target.value,
      token: localStorage.getItem("token"),
      role: localStorage.getItem("role"),
      filter: "1",
      active: "1",
    };
    let uri = global.BASE_URL + "/BatchListForSchool";

    axios
      .post(uri, account)
      .then((response) => {
        this.setState({ batch: response.data });
      })
      .catch((error) => {
        // alert('Email or Password does not match');
      });
  };

  getClassesBySchoolId = (e) => {
    this.setState({
      batche: e.target.value,
    });
    const account = {
      id: localStorage.getItem("id"),
      school_id: this.state.school,
      token: localStorage.getItem("token"),
      batch: e.target.value,
      role: localStorage.getItem("role"),
      active: "1",
    };
    let uri = global.BASE_URL + "/ClassListByBatchForSchool";

    axios
      .post(uri, account)
      .then((response) => {
        this.setState({ classes: response.data });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getSchools() {
    const account = {
      id: localStorage.getItem("id"),
      school_id: localStorage.getItem("school_id"),
      token: localStorage.getItem("token"),
      role: localStorage.getItem("role"),
    };
    // const {history} = this.props;
    let uri = global.BASE_URL + "/getschoollist";

    axios
      .post(uri, account)
      .then((response) => {
        if (response.data.length === 1 || response.data.length == 1) {
          this.setState({ selected_school: response.data[0].id });
        }
        this.setState({ schools: response.data });
      })
      .catch((error) => {});
  }

  handleSubmit(e) {
    this.setState({
      loadingss: true,
      enable: false,
    });
    e.preventDefault();
    let school_id = "";
    const formData = new FormData();
    formData.append("image", this.state.file);
    school_id = this.state.school;
    if (
      localStorage.getItem("role") === "1" ||
      localStorage.getItem("role") === "2"
    ) {
    } else {
      school_id = localStorage.getItem("school_id");
    }

    formData.append("student_name", this.state.student_name);
    formData.append("std_age", this.state.std_age);
    formData.append("gender", this.state.gender);
    formData.append("std_id", this.state.std_id);
    formData.append("school", school_id);
    formData.append("classs", this.state.classs);
    formData.append("batche", this.state.batche);
    formData.append("guardian_name", this.state.guardian_name);
    formData.append("guardian_mob", this.state.guardian_mob);
    formData.append("guardian_email", this.state.guardian_email);
    formData.append("relation", this.state.relation);
    formData.append("date_of_birth", this.state.date_of_birth);
    formData.append("id", localStorage.getItem("id"));
    formData.append("token", localStorage.getItem("token"));
    formData.append("finger_print1", localStorage.getItem("finger_print1"));
    formData.append("finger_print2", localStorage.getItem("finger_print2"));
    formData.append("finger_print3", localStorage.getItem("finger_print3"));

    e.preventDefault();
    var serialize = require("form-serialize");
    var form = document.querySelector("#school_form");
    var obj = serialize(form, { hash: true });

    let uri = global.BASE_URL + "/CreateStudent";
    axios
      .post(uri, obj)
      .then((response) => {
        if (response.data === "validation!") {
          PNotify.alert({
            text: "Fill all the required fields!",
            type: "error",
          });
          this.setState({ validation: true, loadingss: false, enable: true });
        } else if (response.data[0] === "Created!") {
          const formData = new FormData();
          formData.append("image", this.state.file);
          formData.append("student_id", response.data[1]);
          let uri = global.BASE_URL + "/updateStudentImage";
          axios
            .post(uri, formData)
            .then((response) => {
              PNotify.alert({
                text: "Student has been created successfully!",
                type: "success",
              });
            })
            .catch((error) => {
              console.log(error);
            });

          this.setState({ loadingss: false, enable: true });
          // this.props.history.push("/Students");
        } else if (response.data === "Duplicate RollNo") {
          PNotify.alert({
            text: "Duplicate Roll Number!",
            type: "error",
          });
          this.setState({ loadingss: false, enable: true });
        } else {
          PNotify.alert({
            text: "Some thing went wrong try again latter!",
            type: "error",
          });
          this.setState({ loadingss: false, enable: true });
        }
      })
      .catch((error) => {
        this.setState({ loadingss: false });
        // this.setState({error: true});
        PNotify.alert({
          text: "Some thing went wrong try again latter!",
          type: "error",
        });
        // alert('Some thing went wrong try again later');
        this.setState({ enable: true });
      });
  }

  render() {
    let success = this.state.success ? (
      <div>
        <Alert color="primary">Student Created Successfully</Alert>
      </div>
    ) : (
      <div></div>
    );
    let error = this.state.error ? (
      <div>
        <Alert color="danger">Some thing went wrong! try again latter</Alert>
      </div>
    ) : (
      <div></div>
    );
    let validation = this.state.validation ? (
      <div>
        <Alert color="warning">Fill all the fields</Alert>
      </div>
    ) : (
      <div></div>
    );

    let loader = this.state.loadingss ? (
      <div className="loading" />
    ) : (
      <div></div>
    );
    let classes = this.state.classes;
    let batch = this.state.batch;
    let finger_count = this.state.finger_count;
    let schools = this.state.schools;
    let date_of_birth = this.state.date_of_birth;
    let student_name = this.state.student_name;
    let guardian_name = this.state.guardian_name;
    let selected_school = this.state.selected_school;
    let guardians = this.state.guardians;
    let guardian_id = this.state.guardian_id;
    let guardian_mob = this.state.guardian_mob;
    let guardian_email = this.state.guardian_email;
    let guardian_cnic = this.state.guardian_cnic;
    let relation = this.state.relation;
    let students_list = this.state.students_list;
    let student_id = this.state.student_id;
    let roll_number = this.state.roll_number;
    return (
      <div className="animated fadeIn">
        <Row>
          <Card style={{ width: 90 + "%", marginLeft: 5 + "%" }}>
            <CardHeader>
              <Link
                to={{ pathname: "/Students" }}
                className={"pull-right btn btn-sm btn-primary"}
              >
                <i className="icon icon-arrow-left" /> {lang[73]}
              </Link>
              <strong>{lang[36]}</strong>
            </CardHeader>
            <CardBody>
              <Form
                onSubmit={this.handleSubmit}
                id="school_form"
                key={"school_formasd"}
              >
                {/*<Row>*/}
                {/*<Col xs={"3"}>*/}
                {/*/!*<button type={"button"}  id="btn_student_info" color={"primary"} onClick={this.student_info}>Student Info</button>*!/*/}
                {/*<Button type="button" size="lg" id="btn_student_info" onClick={this.student_info}><i*/}
                {/*className="fa fa-child"/> Student Info</Button>*/}
                {/*</Col>*/}
                {/*<Col xs={"3"}>*/}
                {/*<Button type="button" size="lg" id="btn_guardian_info" onClick={this.guardian_info}><i*/}
                {/*className="fa fa-user"/> Guardian Info</Button>*/}
                {/*/!*<button type={"button"}  id="btn_guardian_info" color={"primary"} onClick={this.guardian_info}>Guardian Info</button>*!/*/}
                {/*</Col>*/}
                {/*<Col xs={"3"}>*/}
                {/*<Button type="button" size="lg" id="btn_fee_concession_info"  onClick={this.fee_concession_info}><i*/}
                {/*className="fa fa-user"/> Fee Concession</Button>*/}
                {/*/!*<button type={"button"} id="btn_fee_concession_info"  color={"primary"} onClick={this.fee_concession_info}>Fee Concession*!/*/}
                {/*/!*</button>*!/*/}
                {/*</Col>*/}
                {/*</Row>*/}
                <Tabs>
                  <TabList
                    className="list-inline"
                    activetabclassname="is-selected"
                  >
                    <ul className="nav nav-tabs">
                      <Tab id="std" onClick={this.student_info}>
                        Student Info
                      </Tab>
                      <Tab id="std" onClick={this.guardian_info}>
                        Guardian Info
                      </Tab>
                      <Tab id="std" onClick={this.fee_concession_info}>
                        Fee Concession
                      </Tab>
                    </ul>
                  </TabList>
                </Tabs>
                <div id={"student_info_data"}>
                  {(() => {
                    if (
                      this.state.role_id === "1" ||
                      this.state.role_id === "2"
                    ) {
                      return (
                        <FormGroup>
                          <Label htmlFor="nf-contact">School</Label>
                          <select
                            name={"school"}
                            defaultValue={selected_school}
                            className="form-control col-12"
                            required
                            onChange={this.getBatchesBySchoolId}
                          >
                            <option value={""}>Select School</option>
                            {schools.map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.name}
                              </option>
                            ))}
                            ;
                          </select>
                        </FormGroup>
                      );
                    } else {
                      return (
                        <input
                          type="hidden"
                          defaultValue={localStorage.getItem("school_id")}
                          name="school"
                        />
                      );
                    }
                  })()}

                  <input
                    type="hidden"
                    name="id"
                    value={localStorage.getItem("id")}
                  />
                  <input
                    type="hidden"
                    name="token"
                    value={localStorage.getItem("token")}
                  />
                  <Row>
                    <Col className="col-12">
                      <FormGroup>
                        <Label htmlFor="nf-name">Student Name</Label>
                        <Input
                          type="text"
                          id="nf-name"
                          name="student_name"
                          value={student_name}
                          placeholder="Student Name"
                          maxLength="50"
                          autocomplete="off"
                          required
                          onChange={this.student_name}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-4">
                      <FormGroup>
                        <Label htmlFor="nf-gender">Gender</Label>
                        <select
                          name={"gender"}
                          className="form-control col-12"
                          id="nf-gender"
                          onChange={this.gender}
                          required
                        >
                          <option value={""}>Select Gender</option>
                          <option value={"Male"}>Male</option>
                          <option value={"Female"}>Female</option>
                        </select>
                      </FormGroup>
                    </Col>
                    <Col className="col-4">
                      <FormGroup>
                        <label htmlFor="nf-st_id">Roll Number</label>
                        <Input
                          type="text"
                          id="nf-st_id"
                          name="std_id"
                          placeholder="Enter Student Roll #"
                          maxLength="10"
                          value={roll_number}
                          autocomplete="off"
                          required
                          onChange={this.roll_number}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="col-4">
                      <FormGroup>
                       <div>
                       <label
                          htmlFor="date_of_birth"
                        >
                          Date Of Birth
                        </label>
                       </div>

                        <DatePicker
                          style={{ width: "148px" }}
                          name="date_of_birth"
                          id="date_of_birth"
                          className="form-control"
                          selected={this.state.date}
                          onChange={this.date}
                          dateFormat="yyyy-MM-dd"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup>
                    <Label htmlFor="nf-image">Batch</Label>
                    <select
                      name={"batch"}
                      className={"form-control col-12"}
                      required
                      onChange={this.getClassesBySchoolId}
                    >
                      <option value={""}>Select Batch</option>
                      {batch.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="nf-contact">Class</Label>
                    <select
                      name={"class"}
                      className="form-control col-12"
                      required
                      onChange={this.classs}
                    >
                      <option value={""}>Select Class</option>
                      {classes.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                      ;
                    </select>
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="nf-image">Picture</Label>
                    <Input
                      type="file"
                      id="nf-image"
                      name="image"
                      accept="image/*"
                      onChange={this.image}
                    />
                  </FormGroup>
                  <Row style={{ marginTop: "10px" }}>
                    <Col md={"12"}>
                      <button
                        type={"button"}
                        style={{ float: "right", color: "white" }}
                        className={"btn btn-info"}
                        onClick={this.guardian_info}
                      >
                        Next <i className={"fa fa-forward"}></i>
                      </button>
                    </Col>
                  </Row>
                </div>
                <div id={"guardian_info_data"}>
                  <div id={"guardian_data"}>
                    <FormGroup>
                      <label htmlFor="nf-st_id">Guardian CNIC</label>
                      <Input
                        type="text"
                        id="nf-st_id"
                        name="guardian_cnic[]"
                        autocomplete="off"
                        placeholder="Enter Guardian CNIC"
                        maxLength="13"
                        minLength="13"
                        defaultValue={guardian_cnic}
                        required
                        onChange={this.guardian_cnic}
                      />
                    </FormGroup>
                    <Row>
                      <Col xs="3">
                        <input
                          type={"hidden"}
                          name={"guardian_id"}
                          id={"guardian_id"}
                          defaultValue={guardian_id}
                        />
                        <FormGroup>
                          <label htmlFor="nf-st_id">Guardian Name</label>

                          <Input
                            type="text"
                            id="nf-st_id"
                            name="guardian_name[]"
                            autocomplete="off"
                            placeholder="Enter Guardian Name"
                            maxLength="50"
                            defaultValue={guardian_name}
                            required
                            onChange={this.guardian_name}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs="2">
                        <FormGroup>
                          <label htmlFor="nf-st_id">Guardian CNIC</label>
                          <Input
                            type="number"
                            id="nf-st_id"
                            name="guardian_cnic[]"
                            autocomplete="off"
                            placeholder="Enter Guardian CNIC"
                            maxLength="13"
                            minLength="13"
                            defaultValue={guardian_cnic}
                            required
                            onChange={this.guardian_cnic}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs="3">
                        <FormGroup>
                          <label htmlFor="nf-st_id">Guardian Contact No.</label>
                          <Input
                            type="number"
                            id="nf-st_id"
                            name="guardian_mob[]"
                            autocomplete="off"
                            placeholder="Enter Guardian Contact Number"
                            onChange={this.guardian_mob}
                            maxLength="20"
                            defaultValue={guardian_mob}
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col xs="2">
                        <FormGroup>
                          <label htmlFor="nf-st_id">Guardian Email</label>
                          <Input
                            type="email"
                            id="nf-st_id"
                            name="guardian_email[]"
                            autocomplete="off"
                            placeholder="Enter Guardian Email"
                            maxLength="50"
                            defaultValue={guardian_email}
                            onChange={this.guardian_email}
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col xs="2">
                        <FormGroup>
                          <label htmlFor="nf-st_id">Guardian Relation.</label>
                          <select
                            name={"relation[]"}
                            className={"form-control col-12"}
                            required
                            value={relation}
                            onChange={this.relation}
                          >
                            <option value={""}>Select Relation</option>
                            <option value={"father"}>Father</option>
                            <option value={"Mother"}>Mother</option>
                            <option value={"Sibling"}>Sibling</option>
                            <option value={"Uncle/Aunt"}>Uncle/Aunt</option>
                            <option value={"Others"}>Others</option>
                          </select>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  {/*<div id={"guardian_data"}></div>*/}
                  <div>
                    <Button
                      type="button"
                      color={"primary"}
                      onClick={this.addGuardian}
                    >
                      Add Guardian
                    </Button>
                    <Modal
                      isOpen={this.state.modal}
                      toggle={this.addGuardian}
                      className={this.props.className}
                    >
                      <ModalHeader toggle={this.addGuardian}>
                        Add Guardian
                      </ModalHeader>
                      <ModalBody>
                        <FormGroup>
                          <label htmlFor="nf-st_id">Guardian Name</label>
                          <Input
                            type="text"
                            id="nf-st_id"
                            name="guardian_name"
                            autocomplete="off"
                            placeholder="Enter Guardian Name"
                            maxLength="50"
                            required
                            onChange={this.guardian_name_model}
                          />
                        </FormGroup>
                        <FormGroup>
                          <label htmlFor="nf-st_id">Guardian CNIC</label>
                          <Input
                            type="number"
                            id="nf-st_id"
                            name="guardian_cnic"
                            autocomplete="off"
                            placeholder="Enter Guardian CNIC"
                            maxLength="13"
                            minLength="13"
                            required
                            onChange={this.guardian_cnic_model}
                          />
                        </FormGroup>

                        <FormGroup>
                          <label htmlFor="nf-st_id">Guardian Contact No.</label>
                          <Input
                            type="number"
                            id="nf-st_id"
                            name="guardian_mob"
                            autocomplete="off"
                            placeholder="Enter Guardian Contact Number"
                            onChange={this.guardian_mob_model}
                            maxLength="20"
                            required
                          />
                        </FormGroup>

                        <FormGroup>
                          <label htmlFor="nf-st_id">Guardian Email</label>
                          <Input
                            type="email"
                            id="nf-st_id"
                            name="guardian_email"
                            autocomplete="off"
                            placeholder="Enter Guardian Email"
                            maxLength="50"
                            onChange={this.guardian_email_model}
                            required
                          />
                        </FormGroup>

                        <FormGroup>
                          <label htmlFor="nf-st_id">Guardian Relation.</label>
                          <select
                            name={"relation"}
                            className={"form-control col-12"}
                            required
                            onChange={this.relation_model}
                          >
                            <option value={""}>Select Relation</option>
                            <option value={"father"}>Father</option>
                            <option value={"Mother"}>Mother</option>
                            <option value={"Sibling"}>Sibling</option>
                            <option value={"Uncle/Aunt"}>Uncle/Aunt</option>
                            <option value={"Others"}>Others</option>
                          </select>
                        </FormGroup>
                      </ModalBody>
                      <ModalFooter>
                        <Button color="primary" onClick={this.saveGuardian}>
                          Save
                        </Button>{" "}
                        <Button color="secondary" onClick={this.addGuardian}>
                          Cancel
                        </Button>
                      </ModalFooter>
                    </Modal>
                    {students_list.map((item) => (
                      <Row
                        style={{ border: "1px solid black", marginTop: "5px" }}
                      >
                        <Col xs="2">
                          <div>
                            <img
                              src={item.picture}
                              style={{
                                height: "60px",
                                marginLeft: "32px",
                                width: "100px",
                                marginTop: "7px",
                              }}
                            ></img>
                          </div>
                        </Col>
                        <Col xs="4">
                          <p>Student Name: {item.name}</p>
                          <p>Student Id: {item.studentID}</p>
                        </Col>
                        <Col xs="6">
                          <p>Batch: {item.batch_name}</p>
                          <p>Class: {item.class_name} </p>

                          <Link
                            style={{ float: "right", marginTop: "-51px" }}
                            target="_blank"
                            to={{ pathname: `StudentProfile/${item.id}` }}
                            id={item.id}
                            className="btn btn-primary btn-sm"
                          >
                            <i className="fa fa-eye" />
                            View Profile
                          </Link>
                        </Col>
                      </Row>
                    ))}
                  </div>
                  <Row style={{ marginTop: "10px" }}>
                    <Col md={"6"}>
                      <button
                        type={"button"}
                        style={{ float: "left", color: "white" }}
                        className={"btn btn-info"}
                        onClick={this.student_info}
                      >
                        <i className={"fa fa-backward"}></i> Previous
                      </button>
                    </Col>
                    <Col md={"6"}>
                      <button
                        type={"button"}
                        style={{ float: "right", color: "white" }}
                        className={"btn btn-info"}
                        onClick={this.fee_concession_info}
                      >
                        Next <i className={"fa fa-forward"}></i>
                      </button>
                    </Col>
                  </Row>
                </div>
                <div id={"fee_concession_info_data"}>
                  <FormGroup>
                    <Label htmlFor="nf-status">
                      Fee Concession in Percentage
                    </Label>
                    <br></br>
                    <AppSwitch
                      className={"mx-2"}
                      id={"percentage"}
                      name={"percentage"}
                      variant={"pill"}
                      color={"primary"}
                      value={"1"}
                    />
                  </FormGroup>

                  <FormGroup>
                    <label htmlFor="nf-st_id">Fee Concession</label>
                    <Input
                      type="number"
                      id="fee_concession"
                      name="fee_concession"
                      autocomplete="off"
                      placeholder="Enter Fee Concession"
                      maxLength="50"
                      onChange={this.guardian_email}
                      required
                    />
                  </FormGroup>

                  {loader}
                  <Button
                    type="submit"
                    size="sm"
                    disabled={!this.state.enable}
                    color="primary"
                    onClick={this.handleSubmit}
                  >
                    <i className="fa fa-dot-circle-o" /> Submit
                  </Button>
                  <Button
                    type="reset"
                    size="sm"
                    color="danger"
                    disabled={!this.state.enable}
                  >
                    <i className="fa fa-ban" /> Reset
                  </Button>

                  <Row style={{ marginTop: "10px" }}>
                    <Col md={"12"}>
                      <button
                        type={"button"}
                        style={{ float: "left", color: "white" }}
                        className={"btn btn-info"}
                        onClick={this.guardian_info}
                      >
                        <i className={"fa fa-backward"}></i> Previous
                      </button>
                    </Col>
                  </Row>
                </div>
              </Form>
            </CardBody>
            <CardFooter></CardFooter>
          </Card>
        </Row>
      </div>
    );
  }
}
