import {
  Login,
  Page404,
  Page500,
  Register,
  Schools,
  SchoolEdit,
  Students,
  Classes,
  ResultCategory,
  Subjects,
  UpdateResult,
  StudentResult,
  FeeStructure,
  AddFee,
  Staff,
  Users,
  EditStaff,
  EditSchool,
  SchoolView,
  SchoolDetail,
  EditUser,
  CreateSchool,
  CreateUser,
  CreateStaff,
  DeleteStaff,
  CreateStudent,
  EditStudent,
  CreateClass,
  Attandance,
  NewAttendance,
  EmailTemplate,
  SMSTemplate
} from './Pages';

export {
  Page404,
  Page500,
  Register,
  Schools,
  Students,
  SchoolEdit,
  SchoolView,
  SchoolDetail,
  Classes,
  ResultCategory,
  Subjects,
  UpdateResult,
  StudentResult,
  FeeStructure,
  AddFee,
  CreateSchool,
  CreateUser,
  CreateStaff,
  DeleteStaff,
  CreateStudent,
  EditStudent,
  CreateClass,
  Attandance,
  NewAttendance,
  Staff,
  Users,
  Login,
  EditStaff,
  EditSchool,
  EditUser,
  EmailTemplate,
  SMSTemplate
};
