import './../../../index';
import React, {Component} from 'react';
import {
    Card, CardBody, CardHeader, Col, Row,
    Table, Button, Input, Form, FormGroup, Label, Alert
} from 'reactstrap';
import axios from "axios/index";
import {Link} from "react-router-dom";
import PNotify from 'pnotify/dist/es/PNotify';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import lang from './../../../../Language/en-us'

// import lang from './../../../../Language/fr-ca'
class Tables extends Component {
    constructor(props) {
        super(props);
        this.getBatches();
        this.getSchools();
        var today = new Date();
        if ((today.getMonth() + 1) <= 9) {
            var month = "0" + (today.getMonth() + 1);
        } else {
            var month = (today.getMonth() + 1);
        }
        if (today.getDate() <= 9) {
            var day = "0" + (today.getDate());
        } else {
            var day = (today.getDate());
        }
        var date = today.getFullYear() + '-' + month + '-' + day;
        // 2013-01-08
        this.state = {
            date: date
        };

        // console.log(this.state.date);
        // var date = new Date();

        // console.log(date);
        this.state = {
            items: [],
            classes: [],
            batches: [],
            category: [],
            subjects: [],
            name: [],
            enable: true,
            checked: true,
            clss: '',
            batch: '',
            catego: '',
            already: false,
            success: false,
            error: false,
            validation: false,
            marks: [],
            date: new Date(),
            handleEmailListChange: [],
            schools: [],
            role_id: localStorage.getItem('role'),

        };
        this.getStudentsList();
        this.present = this.present.bind(this);
        this.absent = this.absent.bind(this);
        this.late = this.late.bind(this);
        this.batch = this.batch.bind(this);
        this.clss = this.clss.bind(this);
        this.school = this.school.bind(this);
        this.submit_attandacnce = this.submit_attandacnce.bind(this);
        this.date = this.date.bind(this);
    }

    getStudentsList() {
        this.getbatchClassName();
        const account = {
            id: localStorage.getItem('id'),
            school_id: localStorage.getItem('school_id'),
            token: localStorage.getItem('token'),
            role: localStorage.getItem('role'),
            batch: this.props.match.params.batch_id,
            clss: this.props.match.params.class_id,
            active: '1',
        };
        let uri = global.BASE_URL + '/StudentListForAttandanceSchool';
        axios.post(uri, account).then((response) => {
            this.setState({
                items: response.data,
                loading: false
            });
        }).catch(error => {
        });
    }

    getbatchClassName() {
        const account = {
            id: localStorage.getItem('id'),
            school_id: localStorage.getItem('school_id'),
            token: localStorage.getItem('token'),
            role: localStorage.getItem('role'),
            batch: this.props.match.params.batch_id,
            clss: this.props.match.params.class_id,
            active: '1',
        };
        let uri = global.BASE_URL + '/getBatchClassName';
        axios.post(uri, account).then((response) => {
            this.setState({
                name: response.data,
                loading: false
            });
        }).catch(error => {
        });
    }

    date(e) {
        this.setState({
            date: e
        })
    }

    present(e) {
        this.setState({
            present: e.target.value
        })
    }

    absent(e) {
        this.setState({
            absent: e.target.value
        })
    }

    late(e) {
        this.setState({
            late: e.target.value
        })
    }

    getSchools() {
        if (localStorage.getItem('role') === '1' || localStorage.getItem('role') === '2') {
            const account = {
                id: localStorage.getItem('id'),
                school_id: localStorage.getItem('school_id'),
                token: localStorage.getItem('token'),
                role: localStorage.getItem('role')
            };
            let uri = global.BASE_URL + '/getschoollist';

            axios.post(uri, account).then((response) => {
                this.setState({schools: response.data});
                this.setState({checked: true});
            }).catch(error => {
            });
        }
    }

    school(e) {
        this.setState({
            school: e.target.value
        });
        const account = {
            id: localStorage.getItem('id'),
            school_id: e.target.value,
            token: localStorage.getItem('token'),
            role: localStorage.getItem('role'),
            filter: '1',
            active: '1',
        };
        let uri = global.BASE_URL + '/BatchListForSchool';

        axios.post(uri, account).then((response) => {
            this.setState({batches: response.data});
        }).catch(error => {
        });
    }

    clss(e) {
        this.setState({
            clss: e.target.value
        });
        const account = {
            id: localStorage.getItem('id'),
            school_id: localStorage.getItem('school_id'),
            token: localStorage.getItem('token'),
            role: localStorage.getItem('role'),
            clss: e.target.value,
            batch: this.state.batch,
            active: '1',
        };
        let uri = global.BASE_URL + '/StudentListForAttandanceSchool';

        axios.post(uri, account).then((response) => {
            this.setState({items: response.data});
        }).catch(error => {
            console.log(error);
        });
    }

    batch(e) {
        this.setState({
            batch: e.target.value
        })
        const account = {
            id: localStorage.getItem('id'),
            school_id: localStorage.getItem('school_id'),
            token: localStorage.getItem('token'),
            batch: e.target.value,
            role: localStorage.getItem('role'),
            active: '1',
        };
        let uri = global.BASE_URL + '/ClassListByBatchForSchool';

        axios.post(uri, account).then((response) => {
            this.setState({classes: response.data});
        }).catch(error => {
            console.log(error);
        });
    }


    submit_attandacnce(e) {
        e.preventDefault();
        this.setState({enable: false});
        var serialize = require('form-serialize');
        var form = document.querySelector('#form-data');
        var obj = serialize(form, {hash: true});
        console.log(obj);
        let uri = global.BASE_URL + '/Attandance';

        axios.post(uri, obj).then((response) => {
            if (response.data === 'Already!') {
                PNotify.alert({
                    text: "You have already marked attendance of selected class and selected date!",
                    type: 'error'
                });
                // this.setState({already: true});
                // alert('You have already marked attendance');
                // this.props.history.push('/Attandance');
                this.setState({enable: true});
            } else if (response.data === 'Attendance created!') {
                // this.setState({success: true});
                PNotify.alert({
                    text: "Attendance has been marked successfully!",
                    type: 'success'
                });
                // alert('You have successfully mark attendance.');
                // this.props.history.push('/Attandance');
            } else if (response.data === 'Error!') {
                PNotify.alert({
                    text: "Some thing went wrong try again later!",
                    type: 'error'
                });
                // this.setState({error: true});
                // alert('Some thing went wrong!');
                this.setState({enable: true});
            } else if (response.data === 'Mark Attendance!') {
                // this.setState({validation: true});
                PNotify.alert({
                    text: "Mark Attendance to continue!",
                    type: 'error'
                });
                // alert('Mark Attendance!');
                this.setState({enable: true});
            }
        }).catch(error => {
            this.setState({enable: true});
            console.log(error);
            PNotify.alert({
                text: "Some thing went wrong try again later!",
                type: 'error'
            });
            // this.setState({error: true});
            // alert('Some thing went wrong try again latter.');
        });
    }


    getBatches() {
        const account = {
            id: localStorage.getItem('id'),
            school_id: localStorage.getItem('school_id'),
            token: localStorage.getItem('token'),
            role: localStorage.getItem('role'),
        };
        let uri = global.BASE_URL + '/BatchListForSchool';

        axios.post(uri, account).then((response) => {

            this.setState({batches: response.data});
        }).catch(error => {
        });
    }


    render() {


        let success = this.state.success ? <div><Alert color="primary">
            You have successfully marked attendance
        </Alert></div> : <div></div>;
        let error = this.state.error ? <div><Alert color="danger">
            Some thing went wrong! try again latter
        </Alert></div> : <div></div>;
        let validation = this.state.validation ? <div><Alert color="warning">
            Mark Attendance
        </Alert></div> : <div></div>;
        let already = this.state.already ? <div><Alert color="warning">
            You have already marked attendance
        </Alert></div> : <div></div>;
        let {items, classes, batches, schools, name} = this.state;
        return (
            <div className="animated fadeIn">

                <Row>
                    <Col>
                        <Card>

                            <CardHeader>
                                {success}
                                {error}
                                {validation}
                                {already}
                                <Link to={{pathname: '/Attandance'}} className="pull-right btn btn-sm btn-primary"><i
                                    className="icon icon-arrow-left"/>{lang[73]}</Link>
                                <i className="fa fa-align-justify"/> {lang[17]}
                            </CardHeader>

                            <CardBody>
                                <Form id="form-data">

                                    <Row>

                                        <input type="hidden" name="id" value={localStorage.getItem('id')}/>
                                        <input type="hidden" name="present_student[]" value={this.state.present}/>
                                        <input type="hidden" name="batch" value={this.props.match.params.batch_id}/>
                                        <input type="hidden" name="clss" value={this.props.match.params.class_id}/>
                                        <input type="hidden" name="date" value={this.props.match.params.date}/>
                                        {name.map(items =>
                                            <Row>
                                                <Col sm="4">
                                                    <FormGroup>
                                                        <input type="text" className="form-control"
                                                               value={items.batch_name}/>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm="3">
                                                    <FormGroup>

                                                        <input type="text" className="form-control"
                                                               value={items.clas_name}/>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm="3">
                                                    <FormGroup>
                                                        <input type="text" className="form-control"
                                                               value={this.props.match.params.date}/>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        )}
                                        < input type="hidden" name="school_id"
                                                defaultValue={localStorage.getItem('school_id')}/>
                                        <input type="hidden" name="user_id" defaultValue={localStorage.getItem('id')}/>
                                        <input type="hidden" name="token" defaultValue={localStorage.getItem('token')}/>
                                        <input type="hidden" name="remember_token"
                                               defaultValue={localStorage.getItem('token')}/>
                                        <input type="hidden" name="role" defaultValue={localStorage.getItem('role')}/>

                                    </Row>
                                    <Table hover bordered striped responsive size="sm">

                                        <thead>
                                        <tr>
                                            <th>Roll #</th>
                                            <th>Student Name</th>
                                            <th>Class</th>
                                            <th>Batchss</th>
                                            <th>Action</th>

                                        </tr>
                                        </thead>
                                        <tbody>
                                        {items.map(items =>
                                            <tr key={items.id}>
                                                <td>{items.roll_number}</td>
                                                <td>{items.name}</td>
                                                <td>{items.class}</td>
                                                <td>{items.batch}</td>
                                                <input type="hidden" name="student_id[]" id="student_id"
                                                       value={items.id}/>
                                                <td>
                                                    <FormGroup row>
                                                        <Col md="1">
                                                        </Col>
                                                        <Col md="9">
                                                            <FormGroup check inline>
                                                                <Input className="form-check-input" type="radio"
                                                                       defaultChecked
                                                                       id={"inline-radio1-" + items.id} name={items.id}
                                                                       value='present'/>
                                                                <Label className="form-check-label" check
                                                                       htmlFor={"inline-radio1-" + items.id}>Present</Label>
                                                            </FormGroup>
                                                            <FormGroup check inline>
                                                                <Input className="form-check-input" type="radio"
                                                                       id={"inline-radio2-" + items.id} name={items.id}
                                                                       value='absent'/>
                                                                <Label className="form-check-label" check
                                                                       htmlFor={"inline-radio2-" + items.id}>Absent</Label>
                                                            </FormGroup>
                                                            <FormGroup check inline>
                                                                <Input className="form-check-input" type="radio"
                                                                       id={"inline-radio3-" + items.id} name={items.id}
                                                                       value='late'/>
                                                                <Label className="form-check-label" check
                                                                       htmlFor={"inline-radio3-" + items.id}>Late</Label>
                                                            </FormGroup>
                                                        </Col>
                                                    </FormGroup>

                                                </td>
                                            </tr>
                                        )}


                                        </tbody>


                                    </Table>
                                    <Button type="submit" size="sm" onClick={this.submit_attandacnce} color="primary"
                                    ><i
                                        className="fa fa-dot-circle-o"/> Submit</Button>

                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>

        );
    }
}

export default Tables;
