import './../../../index';
import React, {Component} from 'react';
import {
    Card, CardBody, CardHeader, Col, Row, Alert, Table, Badge, Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button, Input, Form, FormGroup, Label, InputGroup, InputGroupAddon, InputGroupText
} from 'reactstrap';
import axios from "axios/index";
import {Link} from "react-router-dom";
import PNotify from 'pnotify/dist/es/PNotify';
import $ from 'jquery';

class Tables extends Component {
    constructor(props) {
        super(props);
        this.getBatch();
        this.getClass();
        this.getComponent();
        this.getSchools();
        this.state = {
            items: [],
            selected_school: '',
            students: [],
            subjects: [],
            marks: [],
            settings: [],
            category: [],
            batch: [],
            components: [],
            classes: [],
            student: [],
            success: false,
            error: false,
            already: false,
            validation: false,
            monthType: [],
            enable: true,
            components_id: '',
            type: '',
            modal: false,
            sessions: '',
            monthTypeDta: '',
            totalfee: 0,
            schools: [],
            component_id_list: [],
            selected_components: [],
            datee: '',
            role_id: localStorage.getItem('role'),
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.sessions = this.sessions.bind(this);
        this.school = this.school.bind(this);
        this.add_component_model = this.add_component_model.bind(this);
        this.save_components = this.save_components.bind(this);
    }
    remove(e){
        console.log(this.state.component_id_selected);
        var component_id = this.state.component_id_selected;
        var i;
        var txt = "";
        var componentss = [];
        for (i = 0; i < component_id.length; i++) {
            if (component_id[i] != e) {
                txt = txt + component_id[i] + ",";
                componentss.push(component_id[i]);
            }
        }
        this.setState({
            components_id: componentss,
        });
        this.setState({
            component_id_selected: componentss,
        });
        // $("#"+e).remove();
        const account = {
            id: localStorage.getItem('id'),
            school_id: localStorage.getItem('school_id'),
            token: localStorage.getItem('token'),
            role: localStorage.getItem('role'),
            component_id_selected: componentss
        };
        let uri = global.BASE_URL + '/getComponentsById';
        axios.post(uri, account).then((response) => {
            this.setState({selected_components: response.data});
            var total = 0;
            for (var i = 0; i < response.data.length; i++) {
                total += response.data[i].fee << 0;
            }
            this.setState({totalfee: total});
            // console.log("total is "+total);
        }).catch(error => {
            console.log(error);
        });
    }
    save_components() {
        var coffee = document.forms[1];
        var txt = "";
        var i;
        var component = [];
        for (i = 0; i < coffee.length; i++) {
            if (coffee[i].checked) {
                txt = txt + coffee[i].value + ",";
                component.push(coffee[i].value);
            }
        }
        this.setState({component_id_selected:component});
        const account = {
            id: localStorage.getItem('id'),
            school_id: localStorage.getItem('school_id'),
            token: localStorage.getItem('token'),
            role: localStorage.getItem('role'),
            component_id_selected: component
        };
        let uri = global.BASE_URL + '/getComponentsById';
        axios.post(uri, account).then((response) => {
            this.setState({selected_components: response.data});
            var total = 0;
            for (var i = 0; i < response.data.length; i++) {
                total += parseInt(response.data[i].fee);
            }
            this.setState({totalfee: total});
            // console.log("total is "+total);
        }).catch(error => {
            console.log(error);
        });
        this.setState({
            modal: !this.state.modal,
        });
        this.setState({
            components_id: component,
        });
        // console.log("selected " + txt);
    }

    add_component_model() {
        this.setState({
            modal: !this.state.modal,
        });
    }

    getSchools() {
        if (localStorage.getItem('role') === '1' || localStorage.getItem('role') === '2') {
            const account = {
                id: localStorage.getItem('id'),
                school_id: localStorage.getItem('school_id'),
                token: localStorage.getItem('token'),
                role: localStorage.getItem('role')
            };
            let uri = global.BASE_URL + '/getschoollist';

            axios.post(uri, account).then((response) => {
                if (response.data.length === 1 || response.data.length == 1) {
                    this.setState({selected_school: response.data[0].id});
                }
                this.setState({schools: response.data});
            }).catch(error => {
                console.log(error);
            });
        }
        const account = {
            id: localStorage.getItem('id'),
            school_id: localStorage.getItem('school_id'),
            token: localStorage.getItem('token'),
            role: localStorage.getItem('role')
        };
        let uri = global.BASE_URL + '/getschoolsettings';

        axios.post(uri, account).then((response) => {
            if (response.data[0].currency === '' || response.data[0].currency === null) {
                this.setState({settings: 'PKR'});
            } else {
                this.setState({settings: response.data[0].currency});
            }
        }).catch(error => {
            console.log(error);
        });
    }

    getComponent() {
        const account = {
            id: localStorage.getItem('id'),
            school_id: localStorage.getItem('school_id'),
            token: localStorage.getItem('token'),
            role_id: localStorage.getItem('role'),
        };
        let uri = global.BASE_URL + '/ComponentListForSchool';

        axios.post(uri, account).then((response) => {
            this.setState({components: response.data});
        }).catch(error => {
            console.log(error);
        });
    }

    school(e) {
        this.setState({
            school: e.target.value
        });
        const account = {
            id: localStorage.getItem('id'),
            school_id: e.target.value,
            token: localStorage.getItem('token'),
            role: localStorage.getItem('role'),
            filter: '1',
        };
        let uri = global.BASE_URL + '/BatchListForSchool';

        axios.post(uri, account).then((response) => {
            this.setState({batch: response.data});
        }).catch(error => {
            console.log(error);
        });
        const accountss = {
            id: localStorage.getItem('id'),
            school_id: e.target.value,
            token: localStorage.getItem('token'),
            role: localStorage.getItem('role')
        };
        let uriss = global.BASE_URL + '/getschoolsettings';

        axios.post(uriss, accountss).then((response) => {
            if (response.data[0].currency === '' || response.data[0].currency === null) {
                this.setState({settings: 'PKR'});
            } else {
                this.setState({settings: response.data[0].currency});
            }
        }).catch(error => {
            console.log(error);
        });
        const accountsss = {
            id: localStorage.getItem('id'),
            school_id: e.target.value,
            token: localStorage.getItem('token'),
            role_id: localStorage.getItem('role'),
            filter: '1',
        };
        let urisss = global.BASE_URL + '/ComponentListForSchool';

        axios.post(urisss, accountsss).then((response) => {
            this.setState({components: response.data});
        }).catch(error => {
            console.log(error);
        });
    }


    sessions(e) {
        this.setState({
            sessions: e.target.value
        });
        const account = {
            id: localStorage.getItem('id'),
            school_id: localStorage.getItem('school_id'),
            token: localStorage.getItem('token'),
            role_id: localStorage.getItem('role'),
            batch: e.target.value,
        };
        let uri = global.BASE_URL + '/ClassListByBatchForSchool';

        axios.post(uri, account).then((response) => {
            this.setState({classes: response.data});
        }).catch(error => {
            console.log(error);
        });
    }

    getBatch() {
        const account = {
            id: localStorage.getItem('id'),
            school_id: localStorage.getItem('school_id'),
            token: localStorage.getItem('token'),
            role: localStorage.getItem('role'),
        };
        let uri = global.BASE_URL + '/BatchListForSchool';

        axios.post(uri, account).then((response) => {
            this.setState({batch: response.data});
        }).catch(error => {
            console.log(error);
        });
    }

    getClass() {
        const account = {
            id: localStorage.getItem('id'),
            school_id: localStorage.getItem('school_id'),
            token: localStorage.getItem('token'),
            role_id: localStorage.getItem('role'),
        };
        let uri = global.BASE_URL + '/ClassListForSchool';

        axios.post(uri, account).then((response) => {
            this.setState({classes: response.data});
        }).catch(error => {
            console.log(error);
        });
    }


    handleSubmit(e) {
        this.setState({
            enable: false
        });
        e.preventDefault();
        var serialize = require('form-serialize');
        var form = document.querySelector('#form-data');
        var obj = serialize(form, {hash: true});

        let uri = global.BASE_URL + '/FeeStructure';
        axios.post(uri, obj).then((response) => {
            console.log(response);
            if (response.data === "validation error") {
                // alert('Fill All the fields.');

                PNotify.alert({
                    text: "Fill all the required fields!",
                    type: 'error'
                });                // this.setState({validation: true});
                this.setState({enable: true});
            } else if (response.data === 'created!') {
                // this.setState({success: true});
                PNotify.alert({
                    text: "Fee Structure has been created successfully!",
                    type: 'success'
                });
                // alert('Fee Structure Successfully Added');
            } else if (response.data === 'already') {
                PNotify.alert({
                    text: "Fee Structure already added!",
                    type: 'error'
                });
                // this.setState({already: true});
                // alert('Fee structure already created of this class');
                this.setState({enable: true});
            } else {
                PNotify.alert({
                    text: "Some thing went wrong try again latter!",
                    type: 'error'
                });
                // this.setState({error: true});
                // alert('Error');
                this.setState({enable: true});
            }
        }).catch(error => {
            this.setState({enable: true});
            console.log(error);
            PNotify.alert({
                text: "Some thing went wrong try again latter!",
                type: 'error'
            });
            // this.setState({error: true});
            // alert('Some thing went wrong, try again latter!');
        });
    }


    render() {

        let success = this.state.success ? <div><Alert color="primary">
            Fee Structure Successfull Added
        </Alert></div> : <div></div>;
        let error = this.state.error ? <div><Alert color="danger">
            Some thing went wrong! try again latter
        </Alert></div> : <div></div>;
        let validation = this.state.validation ? <div><Alert color="warning">
            Fill all the fields
        </Alert></div> : <div></div>;
        let already = this.state.already ? <div><Alert color="warning">
            Fee Structure already added
        </Alert></div> : <div></div>;

        let {settings, batch, classes, schools, selected_school, components, selected_components, totalfee,datee} = this.state;
        return (
            <div className="animated fadeIn">
                <Row>
                    <Col xs="12" sm="1"/>
                    <Col xs="12" sm="10">
                        <Card>
                            <CardHeader>

                                {success}
                                {error}
                                {validation}
                                {already}
                                <Link to={{pathname: '/FeeStructure/'}} className="pull-right btn btn-sm btn-primary"><i
                                    className="icon icon-arrow-left"/>Go back</Link>

                                <strong>Fee Structure</strong>
                                <small> Form</small>
                            </CardHeader>
                            <CardBody>
                                <Form id="form-data">
                                    <Row>
                                        {(() => {
                                            if (this.state.role_id === '1' || this.state.role_id === '2') {
                                                return <Col xs="12" md="6" sm="12" lg="6">
                                                    <FormGroup>
                                                        <Label htmlFor="batch">Select School</Label>
                                                        <Input type="select" defaultValue={selected_school} id="school"
                                                               name="school" required
                                                               onChange={this.school}>
                                                            <option value="">Select School</option>
                                                            {schools.map(items =>
                                                                <option value={items.id}>{items.name}</option>
                                                            )}
                                                        </Input>
                                                    </FormGroup>
                                                </Col>;
                                            }
                                        })()}
                                        <input type="hidden" name="id" value={localStorage.getItem('id')}/>

                                        <Col xs="12" md="6" sm="12" lg="6">
                                            <FormGroup>
                                                <Label htmlFor="batch">Select Batch</Label>
                                                <Input type="select" id="batch" name="batch" required
                                                       onChange={this.sessions}>
                                                    <option value="">Select Batch</option>
                                                    {batch.map(items =>
                                                        <option value={items.id}>{items.name}</option>
                                                    )}
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col xs="12" md="6" sm="12" lg="6">
                                            <FormGroup>
                                                <Label htmlFor="batch">Select Class</Label>
                                                <Input type="select" id="class" name="class" required>
                                                    <option value="">Select Class</option>
                                                    {classes.map(items =>
                                                        <option value={items.id}>{items.name}</option>
                                                    )}
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        {/*<Col xs="12" md="6" sm="12" lg="6">*/}
                                        {/*<FormGroup>*/}
                                        {/*<Label htmlFor="batch">Select Student</Label>*/}
                                        {/*<Input type="select" id="student_id" name="student_id" required>*/}
                                        {/*<option value="">Select Student</option>*/}
                                        {/*{student.map(items =>*/}
                                        {/*<option value={items.id}>{items.name}</option>*/}
                                        {/*)}*/}
                                        {/*</Input>*/}
                                        {/*</FormGroup>*/}
                                        {/*</Col>*/}

                                        <Col xs="6">
                                            <FormGroup>
                                                <Label htmlFor="fee">Fee</Label>
                                                <div className="controls">
                                                    <InputGroup className="input-prepend">
                                                        <InputGroupAddon addonType="prepend">
                                                            <InputGroupText>{settings}</InputGroupText>
                                                        </InputGroupAddon>
                                                        {/* <Input id="fee" name="fee" size="4" type="number" min="1"
                                                               value={totalfee}
                                                               required/> */}
                                                        <Input id="fee" name="fee" size="4" type="number" min="1" max='4' placeholder='0'
                                                            //    value={totalfee}
                                                            defaultValue={totalfee}
                                                               required/>
                                                        <InputGroupAddon addonType="append">
                                                            <InputGroupText>.00</InputGroupText>
                                                        </InputGroupAddon>
                                                    </InputGroup>
                                                </div>
                                            </FormGroup>
                                        </Col>
                                        <input type={"hidden"} name={"components"} id={"components"}
                                               value={this.state.components_id}/>
                                        <Col xs="12" md="12" sm="12" lg="12">
                                            <Button type="button" style={{float: "right", marginBottom: '10px'}}
                                                    size="sm" onClick={this.add_component_model}
                                                    color="primary"> Add Components</Button>
                                        </Col>

                                        <Modal isOpen={this.state.modal} toggle={this.add_component_model}
                                               className={'modal-lg ' + this.props.className}>
                                            <ModalHeader toggle={this.add_component_model}>Add Components</ModalHeader>
                                            <ModalBody>
                                                <Form id="component">
                                                    <FormGroup>
                                                        <Label>Select Components</Label>
                                                        <Table responsive>
                                                            <thead>
                                                            <tr>
                                                                <th>Component</th>
                                                                <th>Description</th>
                                                                <th>Fee</th>
                                                                <th>Is Taxable</th>
                                                                <th>Tax</th>
                                                                <th>Select</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {components.map(item =>
                                                                <tr>
                                                                    <td>{item.component_name}</td>
                                                                    <td>{item.description}</td>
                                                                    <td>{item.fee}</td>
                                                                    <td>
                                                                        {(() => {
                                                                            if (item.is_taxable == '1' || item.is_taxable === 1) {
                                                                                return <Badge color="success">Yes</Badge>;
                                                                            } else {
                                                                                return <Badge color="danger">No</Badge>;
                                                                            }
                                                                        })()}
                                                                    </td>
                                                                    <td>{item.tax}
                                                                        {(() => {
                                                                            if (item.is_taxable_percentage == '1' || item.is_taxable_percentage === 1) {
                                                                                return '%';
                                                                            } else {
                                                                                return '';
                                                                            }
                                                                        })()}

                                                                    </td>
                                                                    <td>
                                                                        <center>
                                                                            <FormGroup check className="checkbox">
                                                                                <Input className="form-check-input"
                                                                                       type="checkbox"
                                                                                       id={"components_" + item.id}
                                                                                       name={"components[]"} value={item.id}/>
                                                                            </FormGroup>
                                                                        </center>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                            </tbody>
                                                        </Table>
                                                    </FormGroup>
                                                </Form>
                                            </ModalBody>
                                            <ModalFooter>
                                                <Button color="primary" onClick={this.save_components}>
                                                    Save</Button>{' '}
                                                <Button color="secondary"
                                                        onClick={this.add_component_model}>Cancel</Button>
                                            </ModalFooter>
                                        </Modal>
                                        <Col xs="12" md="12" sm="12" lg="12">
                                            <Table responsive>
                                                <thead>
                                                <tr>
                                                    <th>Component Name</th>
                                                    <th>Description</th>
                                                    <th>Fee</th>
                                                    <th>Is Taxable</th>
                                                    <th>Tax</th>
                                                    <th>Show On Voucher</th>
                                                    <th>Action</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {selected_components.map(item =>
                                                    <tr id={item.id}>
                                                        <td>{item.component_name}</td>
                                                        <td>{item.description}</td>
                                                        <td>{item.fee}</td>
                                                        <td>
                                                            {(() => {
                                                                if (item.is_taxable == '1' || item.is_taxable === 1) {
                                                                    return <Badge color="success">Yes</Badge>;
                                                                } else {
                                                                    return <Badge color="danger">No</Badge>;
                                                                }
                                                            })()}
                                                        </td>
                                                        <td>{item.tax}
                                                            {(() => {
                                                                if (item.is_taxable_percentage == '1' || item.is_taxable_percentage === 1) {
                                                                    return '%';
                                                                } else {
                                                                    return '';
                                                                }
                                                            })()}

                                                        </td>
                                                        <td>
                                                            <center><Input className="form-check-input" type="checkbox"
                                                                           id={"show_on_voucher" + item.id}
                                                                           name={item.id} value={"1"}/></center>
                                                        </td>
                                                        <td><center><i className="fa fa-remove" onClick={this.remove.bind(this, item.id)}></i></center></td>
                                                    </tr>
                                                )}

                                                </tbody>
                                            </Table>
                                        </Col>
                                        <input type={"hidden"} value={localStorage.getItem('school_id')}
                                               name="school_id"/>
                                        <input type={"hidden"} value={localStorage.getItem('token')} name="token"/>
                                        <input type={"hidden"} value={localStorage.getItem('id')} name="user_id"/>
                                        <input type={"hidden"} value={localStorage.getItem('role')} name="role_id"/>
                                        <input type={"hidden"} name="component_id" id="component_id"/>


                                    </Row>

                                    <Button type="submit" size="sm" disabled={!this.state.enable}
                                            onClick={this.handleSubmit} color="primary"><i
                                        className="fa fa-dot-circle-o"/> Submit</Button>

                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>

        );
    }

}

export default Tables;
