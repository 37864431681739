import './../../../index';
import React, {Component} from 'react';
import {
  Badge, Card, CardBody, CardHeader, Col, Row,
} from 'reactstrap';
import axios from "axios";
import {Link} from "react-router-dom";
import ReactTable from 'react-table';
import lang from './../../../../Language/en-us'
// import lang from './../../../../Language/fr-ca'

class Tables extends Component {
  constructor(props) {
    super(props);
    this.getSchools();
    this.state = {
      items: [],
      modal: false,
      large: false,
      small: false,
      primary: false,
      primary1: false,
      success: false,
      warning: false,
      danger: false,
      loading: true,
      info: false,
      is_branching: false,
    };
  }

  getSchools() {
    let uri = global.BASE_URL + '/getschoollistHead';
    const account = {
      user_role: localStorage.getItem('role'),
      user_id: localStorage.getItem('id'),
      user_school_id: localStorage.getItem('school_id'),
      id: this.props.match.params.id,
    };
    axios.post(uri, account).then((response) => {
      this.setState({is_branching: response.data[0].is_create_branch});
      if (response.data[0].is_create_branch === 1 || localStorage.getItem('role') === '1') {
        this.setState({is_branching: true});
      }
      this.setState({items: response.data, loading: false});
    }).catch(error => {
    });
  }

  render() {
    let content = this.state.is_branching ?
      <Link to={{pathname: `/Schools/create/branch/${this.props.match.params.id}`}} color="primary create-button"
            className="btn btn-info btn-sm pull-right"><i className="icon-plus"/> {lang[28]}</Link> : <div></div>
    let items = this.state.items;
    let data = this.state.loading ? <div className="loading"/> : <ReactTable
      data={items}
      columns={[
        {
          Header: "School",
          accessor: 'name'
        },
        {
          Header: 'Address',
          accessor: 'address'
        },
        {
          Header: 'Contact Name',
          accessor: 'contact_name'
        },
        {
          Header: 'Contact Email',
          accessor: 'email'
        },
        {
          Header: 'Contact Number',
          accessor: 'contact_no'
        },
        {
          Header: 'Status',
          accessor: 'status',
          Cell: ({value}) => (value === '1' || value === 1 || value === "1"  || value == "1"  || value == 1 ? <Badge className="badge-success">Active</Badge> :
            <Badge className="badge-danger">In Active</Badge>),
          filterMethod: (filter, row) => {
            if (filter.value === "all") {
              return true;
            }
            if (filter.value === '1') {
              return row[filter.id] === '1';
            }
            return row[filter.id] === '0';
          },
          Filter: ({filter, onChange}) =>
            <select
              onChange={event => onChange(event.target.value)}
              style={{width: "100%"}}
              value={filter ? filter.value : "all"}
            >
              <option value="all">Show All</option>
              <option value='1'>Active</option>
              <option value='0'>In Active</option>
            </select>
        },
        {
          Header: 'Action',
          Cell: row => (
              <div>
                  <Link target={"_blank"} to={{pathname: `/Schools/Detail/${row.original.id}`}} className={"btn btn-sm btn-success mr-1"}><i className="fa fa-eye"/></Link>
                  <Link to={{pathname: `/SchoolEdit/${row.original.id}`}} className={"btn btn-sm btn-primary mr-1"}><i className="fa fa-pencil"/></Link>
                  {/*<Link to={{pathname: `/SchoolDetailImpersonation/${row.original.id}`}} className={"btn btn-sm btn-primary mr-1"}><i className="fa fa-eye"/>Detail</Link>*/}
              </div>
          )
        },
      ]}
      defaultPageSize={10}
      className="-striped -highlight -sm"
      showPagination={true}
      showPaginationTop={false}
      showPaginationBottom={true}
      pageSizeOptions={[5, 10, 20, 25, 50, 100]}
      filterable
    />;
    return (
      <div className="animated fadeIn">
        <Row>
          <Col>
            <Card>

              <CardHeader>

                <i className="fa fa-align-justify"/> {lang[1]}
                {content}
                  <Link style={{marginRight:"10px"}} to={{pathname: '/Schools/'}} className="pull-right btn btn-sm btn-primary"><i className="icon icon-arrow-left" /> {lang[73]}</Link>
              </CardHeader>

              <CardBody>
                {data}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>

    );
  }
}

export default Tables;
