import './../../../index';
import React, {Component} from 'react';
import {
  Card, CardBody, CardHeader, Col, Row, Alert,
  Table, Button, Input, Form, FormGroup,
} from 'reactstrap';
import axios from "axios/index";
import {Link} from "react-router-dom";
import PNotify from 'pnotify/dist/es/PNotify';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import lang from './../../../Language/en-us'

// import lang from './../../../Language/fr-ca'
class Tables extends Component {
  constructor(props) {
    super(props);
    this.getSubjects();
    this.getCategory();
    this.state = {
      date: new Date(),
      subjects: [],
      category: [],
      success: false,
      error: false,
      validation: false,
      enable: true,
      loading: true,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.date = this.date.bind(this);
  }

  catego(e) {
    this.setState({
      catego: e.target.value
    })
  }

  date(e) {
    this.setState({
      date: e
    })
  }

  getCategory() {
    const account = {
      id: localStorage.getItem('id'),
      school_id: localStorage.getItem('school_id'),
      token: localStorage.getItem('token'),
      role: localStorage.getItem('role')
    };
    let uri = global.BASE_URL + '/ResultCategoryListForSchool';

    axios.post(uri, account).then((response) => {
      this.setState({category: response.data});
    }).catch(error => {
    });
  }

  getSubjects() {
    console.log(this.props.match.params.id);
    const account = {
      id: localStorage.getItem('id'),
      school_id: localStorage.getItem('school_id'),
      token: localStorage.getItem('token'),
      role: localStorage.getItem('role'),
      student_id: this.props.match.params.id,
    };
    let uri = global.BASE_URL + '/getSubjectsFromStudent';

    axios.post(uri, account).then((response) => {
      this.setState({
        subjects: response.data,
        loading: false,
      });
    }).catch(error => {
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({
      enable: false
    })
    let serialize = require('form-serialize');
    let form = document.querySelector('#form-data');
    let obj = serialize(form, {hash: true});

    let uri = global.BASE_URL + '/CreateResult';
    axios.post(uri, obj).then((response) => {
      console.log(response);
      if (response.data === "Result Created!") {
        // this.setState({success: true});
        PNotify.alert({
          text: "Result has been added successfully!",
          type: 'success'
        });
        // alert('You have successfully submit the result');
        // this.props.history.push('/UpdateResult');
      } else if (response.data === "validation!") {
        // alert('Fill all the fields');
        PNotify.alert({
          text: "Fill all the required fields!",
          type: 'error'
        });
        // this.setState({validation: true});
        this.setState({
          enable: true
        });
      }
      console.log(form);
    }).catch(error => {
      PNotify.alert({
        text: "Some thing went wrong try again latter!",
        type: 'error'
      });
      // this.setState({error: true});
      // alert('Something went wrong, try again later')
      this.setState({
        enable: true
      });
    });
  }


  render() {


    let success = this.state.success ? <div><Alert color="primary">
      Result Successfully Submitted
    </Alert></div> : <div></div>;
    let error = this.state.error ? <div><Alert color="danger">
      Some thing went wrong! try again latter
    </Alert></div> : <div></div>;
    let validation = this.state.validation ? <div><Alert color="warning">
      Fill all the fields
    </Alert></div> : <div></div>;

    let category = this.state.category;
    let subjects = this.state.subjects;
    let content = this.state.loading ? <div className="loading"/> : <tbody>
    <input type={"hidden"} value={subjects.batch_id} name="batch_id"/>
    <input type={"hidden"} value={subjects.batch_id} name="batch_id"/>
    <input type={"hidden"} value={this.props.match.params.id} name="student"/>
    <input type={"hidden"} value={localStorage.getItem('school_id')} name="school_id"/>
    <input type={"hidden"} value={localStorage.getItem('token')} name="token"/>
    <input type={"hidden"} value={localStorage.getItem('id')} name="user_id"/>
    <input type={"hidden"} value={localStorage.getItem('role')} name="role_id"/>
    {subjects.map(items =>
      <tr key={items.id}>
        <td>
          {items.name}</td>
        <td>
          <input type={"hidden"} value={items.id} name="subjects[]"/>
          <input type="text" name={items.id} required/>
        </td>
      </tr>
    )}
    </tbody>;
    return (
      <div className="animated fadeIn">
        <Row>
          <Col>
            <Card>
              <CardHeader>

                {success}
                {error}
                {validation}
                <Link to={{pathname: '/UpdateResult/'}} className="pull-right btn btn-sm btn-primary"><i
                  className="icon icon-arrow-left"/> {lang[73]}</Link>
                <i className="fa fa-align-justify"/> {lang[31]}
              </CardHeader>
              <CardBody>
                <Form id="form-data">
                  <Row>
                    <Col xs="6">
                      <FormGroup>
                        <Input type={"select"} name={"category"}>
                          <option value={""}>Select Category</option>
                          {category.map(item =>
                            <option key={item.id} value={item.id}>{item.name}</option>
                          )};
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col xs="6">
                      <FormGroup>
                        <DatePicker
                          name="date"
                          id="date"
                          className="form-control"
                          selected={this.state.date}
                          onChange={this.date}
                          dateFormat="yyyy-MM-dd"
                        />
                        {/*<Input type={"date"} name={"date"} placeholder={"Enter Month"}>*/}
                        {/*</Input>*/}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Table hover bordered striped responsive size="sm">
                    <thead>
                    <tr>
                      <th>Subject Name</th>
                      <th>Marks</th>
                    </tr>
                    </thead>
                    {content}
                  </Table>
                  <Row>
                    <Col xs="12" md="12">
                      <Input type="textarea" name="comments" id="textarea-input" rows="3"
                             placeholder="Comments..."/>
                    </Col>
                  </Row>
                  <Button type="submit" size="sm" onClick={this.handleSubmit} color="primary" className={"mt-1"}
                          disabled={!this.state.enable}><i
                    className="fa fa-dot-circle-o"/> Submit</Button>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>

    );
  }

}

export default Tables;
