import React, {Component} from 'react';
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row
} from 'reactstrap';
import axios from 'axios';
import {Link} from 'react-router-dom';
import PNotify from 'pnotify/dist/es/PNotify';

class Forgot extends Component {
  constructor(props) {
    super(props);
    this.state = {email: '', password: '', enable: true, loading: false};
    this.checkUser();
    this.handleChange1 = this.handleChange1.bind(this);
    this.handleChange2 = this.handleChange2.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange1(e) {
    this.setState({
      email: e.target.value,
      password: '',
      loading: false,
    })
  }

  handleChange2(e) {
    this.setState({
      password: e.target.value
    })
  }

  checkUser() {
    PNotify.defaults.styling = 'material';
// This icon setting requires the Material Icons font. (See below.)
    PNotify.defaults.icons = 'material';
    PNotify.defaults.icons = 'fontawesome4'; // Font Awesome 4
// or
    PNotify.defaults.icons = 'fontawesome5'; // Font Awesome 5
    if (localStorage.getItem('token') === '' || localStorage.getItem('token') === null
      || localStorage.getItem('token') === "" || localStorage.getItem('token') === undefined) {
      // this.props.history.push("/login");
    } else {
      const account = {
        token: localStorage.getItem('token'),
        id: localStorage.getItem('id'),
        email: localStorage.getItem('email'),
        invalidData: true
      };

      let uri = global.BASE_URL + '/checkUser';
      axios.post(uri, account).then((response) => {
        if (response.status === 200) {
          //
          this.props.history.redirect("/");

        } else {
          window.location.reload();
        }
      }).catch(error => {
        // window.location.reload();
        // this.props.history.push("/login");
        // alert('Email or Password does not match');
      });
    }
  }

  componentWillUpdate(nextProps, nextState) {
    nextState.invalidData = !(nextState.email && nextState.password);
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({enable: false, loading: true});
    const account = {
      email: this.state.email,
      password: this.state.password
    };
    let uri = global.BASE_URL + '/forgot';
    axios.post(uri, account).then((response) => {
      if (response.data === 'success') {
        this.setState({loading: false, enable: true});
        // alert('User Inactive');
        PNotify.alert({
          text: "Email has been sent to your Email!",
          type: 'success'
        });
      } else if (response.data === 'No Email Found') {
        this.setState({loading: false, enable: true});
        PNotify.alert({
          text: "No Email Found",
          type: 'error'
        });
        // alert("User's School is Inactive");
      }
    }).catch(error => {
      this.setState({loading: false, enable: true});
      PNotify.alert({
        text: "Some thing went wrong try again latter!",
        type: 'error'
      });
    });
  }

  render() {
    let loader = this.state.loading ? <div className="loading"/> : <div></div>
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <CardGroup>
                <Card className="p-4">
                  <CardBody>
                    <Form onSubmit={this.handleSubmit}>
                      <h1>Forgot Password</h1>
                      <p className="text-muted">Enter Email</p>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-user"/>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input type="text" placeholder="email" autoComplete="email"
                               name={"email"} onChange={this.handleChange1}/>
                      </InputGroup>

                      <Row>
                        <Col xs="6">
                          <Button color="primary" type="submit" className="px-4"
                          >Submit</Button>
                        </Col>

                      </Row>
                      <Row>
                        <Col xs="6">
                          {loader}
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
                <Card className="text-white bg-primary py-5 d-md-down-none" style={{width: 44 + '%'}}>
                  <CardBody className="text-center">
                    <div>
                      <h2>Sign up</h2>
                      <p>Want to be more productive or Just wanna shift to online school
                        management system to ensure better performance of your staff..?</p>
                      <Link to={{pathname: '/register'}}><Button color="primary" className="mt-3"
                                                                 active>Register
                        Now!</Button></Link>
                    </div>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Forgot;
