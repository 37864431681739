import './../../../index';
import React, {Component} from 'react';
import {
  Card, CardBody, CardHeader, Col, Row,
  Input, Form, FormGroup, Label, Button, Alert,
  InputGroup, InputGroupAddon, InputGroupText,Badge,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,Table
} from 'reactstrap';
import axios from "axios/index";
import {Link} from "react-router-dom";
import PNotify from 'pnotify/dist/es/PNotify';

class Tables extends Component {
  constructor(props) {
    super(props);
    // this.getStudents();
    this.getBatch();
    this.getClass();
    this.getSchools();
    this.getEditForm();
    this.getComponents();
    this.getSelectedComponents();
    // this.getCategory();
    this.state = {
      items: [],
      students: [],
      subjects: [],
      marks: [],
      settings: [],
        selected_components: [],
      category: [],
      success: false,
      error: false,
      validation: false,
      monthType: [],
      batch: [],
        components: [],
      classes: [],
      edit_form: [],
      sessions: '',
        totalfee: '0',
      enable: true,
      loading: true,
        modal: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.add_component_model = this.add_component_model.bind(this);
    this.save_components = this.save_components.bind(this);
  }
    getComponents() {
        const account = {
            id: localStorage.getItem('id'),
            school_id: localStorage.getItem('school_id'),
            token: localStorage.getItem('token'),
            role_id: localStorage.getItem('role'),
        };
        let uri = global.BASE_URL + '/ComponentListForSchool';

        axios.post(uri, account).then((response) => {
            this.setState({components: response.data});
        }).catch(error => {
            console.log(error);
        });
    }
    getSelectedComponents() {
        const account = {
            id: localStorage.getItem('id'),
            school_id: localStorage.getItem('school_id'),
            token: localStorage.getItem('token'),
            role_id: localStorage.getItem('role'),
            fee_id: this.props.match.params.id
        };
        let uri = global.BASE_URL + '/getComponentsBymaster_id';

        axios.post(uri, account).then((response) => {
            this.setState({selected_components: response.data});
            var total = '';
            var txt = '';
            var component = [];
            for (var i = 0; i <  response.data.length; i++) {
                     txt = txt + response.data[i].id + ",";
                    component.push(response.data[i].id);
            }
            this.setState({
                components_id: component,
            });
            this.setState({component_id_selected:component})
            var total = 0;
            for (var i = 0; i < response.data.length; i++) {
                total += response.data[i].fee << 0;
            }
console.log(total)
            this.setState({totalfee: total});
        }).catch(error => {
            console.log(error);
        });
    }

  catego(e) {
    this.setState({
      catego: e.target.value
    })
  }


  getBatch() {
    const account = {
      id: localStorage.getItem('id'),
      school_id: localStorage.getItem('school_id'),
      token: localStorage.getItem('token'),
      role: localStorage.getItem('role'),
    };
    // const {history} = this.props;
    let uri = global.BASE_URL + '/BatchListForSchool';

    axios.post(uri, account).then((response) => {
      // console.log(response.data);
      this.setState({batch: response.data});

    }).catch(error => {
      // alert('Email or Password does not match');
    });
  }


  getEditForm() {
    const account = {
      id: localStorage.getItem('id'),
      school_id: localStorage.getItem('school_id'),
      token: localStorage.getItem('token'),
      fee_id: this.props.match.params.id
    };
    // const {history} = this.props;
    let uri = global.BASE_URL + '/EditFeeByID';

    axios.post(uri, account).then((response) => {
      // console.log(response);
      this.setState({edit_form: response.data, loading: false});
// console.log(edit_form);
    }).catch(error => {
      // alert('Email or Password does not match');
    });
  }

  getSchools() {
    if (localStorage.getItem('role') === '1' || localStorage.getItem('role') === '2') {
      const account = {
        id: localStorage.getItem('id'),
        school_id: localStorage.getItem('school_id'),
        token: localStorage.getItem('token'),
        role: localStorage.getItem('role')
      };
      let uri = global.BASE_URL + '/getschoollist';

      axios.post(uri, account).then((response) => {
        this.setState({schools: response.data});
      }).catch(error => {
        console.log(error);
      });
    }
    const accountss = {
      id: localStorage.getItem('id'),
      school_id: localStorage.getItem('school_id'),
      token: localStorage.getItem('token'),
      role: localStorage.getItem('role')
    };
    let uriss = global.BASE_URL + '/getschoolsettings';

    axios.post(uriss, accountss).then((response) => {
      if (response.data[0].currency === '') {
        this.setState({settings: 'PKR'});
      } else {
        this.setState({settings: response.data[0].currency});
      }
    }).catch(error => {
      console.log(error);
    });
  }

  school(e) {
    this.setState({
      school: e.target.value
    });
    const account = {
      id: localStorage.getItem('id'),
      school_id: e.target.value,
      token: localStorage.getItem('token'),
      role: localStorage.getItem('role'),
      filter: '1',
    };
    let uri = global.BASE_URL + '/BatchListForSchool';

    axios.post(uri, account).then((response) => {
      this.setState({batch: response.data});
    }).catch(error => {
      console.log(error);
    });
    const accountss = {
      id: localStorage.getItem('id'),
      school_id: e.target.value,
      token: localStorage.getItem('token'),
      role: localStorage.getItem('role')
    };
    let uriss = global.BASE_URL + '/getschoolsettings';

    axios.post(uriss, accountss).then((response) => {
      if (response.data[0].currency === '') {
        this.setState({settings: 'PKR'});
      } else {
        this.setState({settings: response.data[0].currency});
      }
    }).catch(error => {
      console.log(error);
    });
  }

  sessions(e) {
    this.setState({
      sessions: e.target.value
    });
    const account = {
      id: localStorage.getItem('id'),
      school_id: localStorage.getItem('school_id'),
      token: localStorage.getItem('token'),
      role_id: localStorage.getItem('role'),
      batch: e.target.value,
    };
    let uri = global.BASE_URL + '/ClassListByBatchForSchool';

    axios.post(uri, account).then((response) => {
      this.setState({classes: response.data});
    }).catch(error => {
      console.log(error);
    });
  }

  monthTypeDta(e) {
    this.setState({
      monthTypeDta: e.target.value
    });
  }

  type(e) {

    this.setState({
      type: e.target.value
    });
    const account = {
      id: localStorage.getItem('id'),
      school_id: localStorage.getItem('school_id'),
      token: localStorage.getItem('token'),
      value: e.target.value,
      sessions: this.state.sessions
    };
    let uri = global.BASE_URL + '/GetMonthsType';

    axios.post(uri, account).then((response) => {
      // console.log(response);
      this.setState({monthType: response.data});
    }).catch(error => {
      console.log(error);
    });
  }

  getClass() {
    const account = {
      id: localStorage.getItem('id'),
      school_id: localStorage.getItem('school_id'),
      token: localStorage.getItem('token'),
      role_id: localStorage.getItem('role'),
    };
    let uri = global.BASE_URL + '/ClassListForSchool';

    axios.post(uri, account).then((response) => {
      this.setState({classes: response.data});
    }).catch(error => {
      console.log(error);
    });
  }

  handleSubmit(e) {
    this.setState({enable: false});
    e.preventDefault();
    var serialize = require('form-serialize');
    var form = document.querySelector('#form-data');
// str -> "foo=bar"
//
    var obj = serialize(form, {hash: true});
    // obj.append('school_id', localStorage.getItem('school_id'));
    let uri = global.BASE_URL + '/UpdateFeeStructure';
    axios.post(uri, obj).then((response) => {
        this.setState({enable: true});
      // browserHistory.push('localhost:8080/reactProject/public');

      if (response.data === " Updated!") {
        PNotify.alert({
          text: "Fee Structure has been updated successfully!",
          type: 'success'
        });
        // this.setState({success: true});
        // alert('Updated Successfully');
        // this.props.history.push("/FeeStructure");
      } else {
        PNotify.alert({
          text: "Some thing went wrong try again latter!",
          type: 'error'
        });
        // this.setState({error: true});
      }
    }).catch(error => {
      this.setState({enable: true});
      PNotify.alert({
        text: "Some thing went wrong try again latter!",
        type: 'error'
      });
      // this.setState({error: true});
      // alert('Email or Password does not match');
    });
  }

  generateFee(e) {
    e.preventDefault();
    const account = {
      id: localStorage.getItem('id'),
      school_id: localStorage.getItem('school_id'),
      token: localStorage.getItem('token'),
      value: this.state.value,
      sessions: this.state.sessions,
      monthTypeDta: this.state.monthTypeDta,
    };
    let uri = global.BASE_URL + '/GetMonthsType';

    axios.post(uri, account).then((response) => {
      // console.log(response);
      this.setState({monthType: response.data});
    }).catch(error => {
      console.log(error);
    });
  }
    remove(e){
        // console.log(this.state.component_id_selected);
        var component_id = this.state.component_id_selected;
        var i;
        var txt = "";
        var componentss = [];
        for (i = 0; i < component_id.length; i++) {
            if (component_id[i] != e) {
                txt = txt + component_id[i] + ",";
                componentss.push(component_id[i]);
            }
        }
        this.setState({
            components_id: componentss,
        });
        this.setState({
            component_id_selected: componentss,
        });
        // $("#"+e).remove();
        const account = {
            id: localStorage.getItem('id'),
            school_id: localStorage.getItem('school_id'),
            token: localStorage.getItem('token'),
            role: localStorage.getItem('role'),
            component_id_selected: componentss
        };
        let uri = global.BASE_URL + '/getComponentsById';
        axios.post(uri, account).then((response) => {
            this.setState({selected_components: response.data});
            var total = 0;
            for (var i = 0; i < response.data.length; i++) {
                total += response.data[i].fee << 0;
            }
            console.log(total);
            this.setState({totalfee: total});
            // console.log("total is "+total);
        }).catch(error => {
            console.log(error);
        });
    }
    save_components() {
        var coffee = document.forms[1];
        var txt = "";
        var i;
        var component = [];
        for (i = 0; i < coffee.length; i++) {
            if (coffee[i].checked) {
                txt = txt + coffee[i].value + ",";
                component.push(coffee[i].value);
            }
        }
        this.setState({component_id_selected:component});
        const account = {
            id: localStorage.getItem('id'),
            school_id: localStorage.getItem('school_id'),
            token: localStorage.getItem('token'),
            role: localStorage.getItem('role'),
            component_id_selected: component
        };
        let uri = global.BASE_URL + '/getComponentsById';
        axios.post(uri, account).then((response) => {
            this.setState({selected_components: response.data});
            var total = 0;
            for (var i = 0; i < response.data.length; i++) {
                total += response.data[i].fee << 0;
            }
console.log(total);
            this.setState({totalfee: total});
            // console.log("total is "+total);
        }).catch(error => {
            console.log(error);
        });
        this.setState({
            modal: !this.state.modal,
        });
        this.setState({
            components_id: component,
        });
        // console.log("selected " + txt);
    }

    add_component_model() {
        this.setState({
            modal: !this.state.modal,
        });
    }
  render() {
    let success = this.state.success ? <div><Alert color="primary">
      Fee Structure Successfully Updated
    </Alert></div> : <div></div>;
    let error = this.state.error ? <div><Alert color="danger">
      Some thing went wrong! try again latter
    </Alert></div> : <div></div>;
    let validation = this.state.validation ? <div><Alert color="warning">
      Fill all the fields
    </Alert></div> : <div></div>;
    let settings = this.state.settings;
    let batch = this.state.batch;
    let classes = this.state.classes;
    let schools = this.state.schools;
    let edit_form = this.state.edit_form;
    let components = this.state.components;
    let selected_components = this.state.selected_components;
    let totalfee = this.state.totalfee;
    let content = this.state.loading ? <div className="loading"/> : <CardBody>
      <Form id="form-data">
        <Row>
          {(() => {
            if( this.state.role_id === '1' || this.state.role_id === '2'){
              return <Col xs="12" md="6" sm="12" lg="6">
                <FormGroup>
                  <Label htmlFor="batch">Select School</Label>
                  <Input type="select" id="school" name="school" required disabled
                         onChange={this.school}>
                    <option value="">Select School</option>
                    {schools.map(items =>
                      <option value={items.id}>{items.name}</option>
                    )}
                  </Input>
                </FormGroup>
              </Col>;
            }
          })()}
          <input type="hidden" name="id" value={localStorage.getItem('id')}/>

          <Col xs="12" md="6" sm="12" lg="6">
            <FormGroup>
              <Label htmlFor="batch">Select Batch</Label>
              {edit_form.map(edit =>
                <Input type="select" id="batch" name="batch" required disabled
                       onChange={this.sessions} defaultValue={edit.batch_id}>
                  <option value="">Select Batch</option>
                  {batch.map(items =>
                    <option value={items.id}>{items.name}</option>
                  )}
                </Input>
              )}
            </FormGroup>
          </Col>
          <Col xs="12" md="6" sm="12" lg="6">
            <FormGroup>
              <Label htmlFor="batch">Select Class</Label>
              {edit_form.map(edit =>
                <Input type="select" id="class" name="class" required disabled defaultValue={edit.class_id}
                       value={edit.class_id}>
                  <option value="">Select Class</option>
                  {classes.map(items =>
                    <option value={items.id}
                            selected={edit.class_id === items.id || edit.class_id === `${items.id}`}>{items.name}</option>
                  )}
                </Input>
              )}
            </FormGroup>
          </Col>

          <Col xs="6">
            <FormGroup>
              <Label htmlFor="fee">Fee</Label>
              <div className="controls">
                <InputGroup className="input-prepend">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>{settings}</InputGroupText>
                  </InputGroupAddon>
                  {edit_form.map(edit =>
                    <Input id="fee" name="fee" size="4" defaultValue={edit.fee} type="text"/>
                  )}
                  <InputGroupAddon addonType="append">
                    <InputGroupText>.00</InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </div>
            </FormGroup>
          </Col>
            <input type={"hidden"} name={"components"} id={"components"}
                   value={this.state.components_id}/>
            <Col xs="12" md="12" sm="12" lg="12">
                <Button type="button" style={{float: "right", marginBottom: '10px'}}
                        size="sm" onClick={this.add_component_model}
                        color="primary"> Add Components</Button>
            </Col>

            <Modal isOpen={this.state.modal} toggle={this.add_component_model}
                   className={'modal-lg ' + this.props.className}>
                <ModalHeader toggle={this.add_component_model}>Add Components</ModalHeader>
                <ModalBody>
                    <Form id="component">
                        <FormGroup>
                            <Label>Select Components</Label>
                            <Table responsive>
                                <thead>
                                <tr>
                                    <th>Component</th>
                                    <th>Description</th>
                                    <th>Fee</th>
                                    <th>Is Taxable</th>
                                    <th>Tax</th>
                                    <th>Select</th>
                                </tr>
                                </thead>
                                <tbody>
                                {components.map(item =>
                                    <tr>
                                        <td>{item.component_name}</td>
                                        <td>{item.description}</td>
                                        <td>{item.fee}</td>
                                        <td>
                                            {(() => {
                                                if (item.is_taxable == '1' || item.is_taxable === 1) {
                                                    return <Badge color="success">Yes</Badge>;
                                                } else {
                                                    return <Badge color="danger">No</Badge>;
                                                }
                                            })()}
                                        </td>
                                        <td>{item.tax}
                                            {(() => {
                                                if (item.is_taxable_percentage == '1' || item.is_taxable_percentage === 1) {
                                                    return '%';
                                                } else {
                                                    return '';
                                                }
                                            })()}

                                        </td>
                                        <td>
                                            <center>
                                                <FormGroup check className="checkbox">
                                                    <Input className="form-check-input"
                                                           type="checkbox"
                                                           id={"components_" + item.id}
                                                           name={"components[]"} value={item.id}/>
                                                </FormGroup>
                                            </center>
                                        </td>
                                    </tr>
                                )}
                                </tbody>
                            </Table>
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.save_components}>
                        Save</Button>{' '}
                    <Button color="secondary"
                            onClick={this.add_component_model}>Cancel</Button>
                </ModalFooter>
            </Modal>
            <Col xs="12" md="12" sm="12" lg="12">
                <Table responsive>
                    <thead>
                    <tr>
                        <th>Component Name</th>
                        <th>Description</th>
                        <th>Fee</th>
                        <th>Is Taxable</th>
                        <th>Tax</th>
                        <th>Show On Voucher</th>
                        <th>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {selected_components.map(item =>
                        <tr id={item.id}>
                            <td>{item.component_name}</td>
                            <td>{item.description}</td>
                            <td>{item.fee}</td>
                            <td>
                                {(() => {
                                    if (item.is_taxable == '1' || item.is_taxable === 1) {
                                        return <Badge color="success">Yes</Badge>;
                                    } else {
                                        return <Badge color="danger">No</Badge>;
                                    }
                                })()}
                            </td>
                            <td>{item.tax}
                                {(() => {
                                    if (item.is_taxable_percentage == '1' || item.is_taxable_percentage === 1) {
                                        return '%';
                                    } else {
                                        return '';
                                    }
                                })()}

                            </td>
                            <td>
                                <center><Input className="form-check-input" type="checkbox"
                                               defaultChecked={item.show_on_voucher}
                                               id={"show_on_voucher" + item.id}
                                               name={item.id} value={"1"}/></center>
                            </td>
                            <td><center><i className="fa fa-remove" onClick={this.remove.bind(this, item.id)}></i></center></td>
                        </tr>
                    )}

                    </tbody>
                </Table>
            </Col>
          <input type={"hidden"} value={localStorage.getItem('school_id')} name="school_id"/>
          <input type={"hidden"} value={localStorage.getItem('token')} name="token"/>
          <input type={"hidden"} value={localStorage.getItem('id')} name="user_id"/>
          <input type={"hidden"} value={localStorage.getItem('role')} name="role_id"/>
          <input type={"hidden"} value={this.props.match.params.id} name="edit_id"/>

        </Row>


        <Button type="submit" disabled={!this.state.enable} size="sm" onClick={this.handleSubmit} color="primary"><i
          className="fa fa-dot-circle-o"/> Submit</Button>
      </Form>
    </CardBody>;
    return (
      <div className="animated fadeIn">
        <Row>
          <Col xs="12" sm="1"></Col>
          <Col xs="12" sm="10">
            <Card>
              <CardHeader>

                {success}
                {error}
                {validation}
                <Link to={{pathname: '/FeeStructure/'}} className="pull-right btn btn-sm btn-primary"><i
                  className="icon icon-arrow-left"/>Go back</Link>

                <strong>Fee Structure</strong>
                <small> Form</small>
              </CardHeader>
              {content}
            </Card>
          </Col>
        </Row>
      </div>

    );
  }

}

export default Tables;
