import './../../../index';
import React, {Component} from 'react';
import {
    Badge, Card, CardBody, CardHeader, Col, Row, Input, Form, FormGroup
} from 'reactstrap';
import axios from "axios/index";
import {Link} from 'react-router-dom';
import CsvDownloader from 'react-csv-downloader';
import PNotify from 'pnotify/dist/es/PNotify';
import lang from "../../../Language/en-us";

// import ReactTable from 'react-table';

class Users extends Component {
    constructor(props) {
        super(props);
        this.getSchools();
        this.getBatch();
        this.state = {

            items: [],
            classes: [],
            batch: '',
            class: '',
            schools: [],
            school: '',
            count: 20,
            batches: [],
            hasError: false,
            loadingButton: false,
            loading: true,
            seleted_school: false,
            seleted_batch: false,
            seleted_class: false,
            enable: true,
            enableSave: false,
            role_id: localStorage.getItem('role'),
        };
        this.school = this.school.bind(this);
        this.getUsers = this.getUsers.bind(this);
        this.loadMoreData = this.loadMoreData.bind(this);
        this.selectBatch = this.selectBatch.bind(this);
        this.selectClass = this.selectClass.bind(this);
        this.searchByName = this.searchByName.bind(this);
        this.addDefaultSrc = this.addDefaultSrc.bind(this);
    }
    addDefaultSrc(ev){
        ev.target.src = 'https://portal.e-idara.com/teacher_avatar.jpg'
    }
    toggle() {
        this.setState({
            modal: !this.state.modal,
        });
    }

    getSchools() {
        const account = {
            id: localStorage.getItem('id'),
            school_id: localStorage.getItem('school_id'),
            token: localStorage.getItem('token'),
            role: localStorage.getItem('role')
        };
        let uri = global.BASE_URL + '/getschoollist';

        axios.post(uri, account).then((response) => {
            console.log(response.data.length);
            if (response.data.length === '1') {
                this.setState(
                    {
                        seleted_school: true
                    }
                );
            }
            this.setState(
                {
                    schools: response.data
                }
            );
        }).catch(error => {
        });
    }

    componentDidMount() {
        this.setState({
            school: localStorage.getItem('school_id')
        })
        this.getUsers();
    }

    loadMoreData() {
        this.setState({
            enable: false,
            loadingButton: true,
            enableSave: false
        })
        if (this.state.school === '') {
            alert('Please select school');
            this.setState({
                enable: true,
                loadingButton: false,
            })
            return;
        }
        const account = {
            id: localStorage.getItem('id'),
            school_id: this.state.school,
            token: localStorage.getItem('token'),
            role_id: localStorage.getItem('role'),
            count: this.state.count,
            filter: '1'
        };
        // const {history} = this.props;
        let uri = global.BASE_URL + '/StaffList';

        axios.post(uri, account).then((response) => {
            this.setState({
                items: response.data,
                count: this.state.count + 20,
                enable: true,
                loadingButton: false,
                loading: false,
                enableSave: true,
                enableDeleteButton: true,
            });
        }).catch(error => {
        });
    }

    school(e) {
        this.setState({
            school: e.target.value,
            loading: true,
            enableSave: false
        });
        const account = {
            id: localStorage.getItem('id'),
            school_id: e.target.value,
            token: localStorage.getItem('token'),
            role_id: localStorage.getItem('role'),
            count: this.state.count,
            filter: '1',
        };
        let uri = global.BASE_URL + '/StaffList';

        axios.post(uri, account).then((response) => {
            this.setState({
                items: response.data,
                count: this.state.count + 20,
                loading: false,
                enableSave: true
            });
        }).catch(error => {
        });
    }

    getUsers() {
        const account = {
            id: localStorage.getItem('id'),
            school_id: localStorage.getItem('school_id'),
            token: localStorage.getItem('token'),
            count: this.state.count,
            role_id: localStorage.getItem('role'),
        };
        let uri = global.BASE_URL + '/StaffList';
        axios.post(uri, account).then((response) => {
            //console.log(response.data);
            if (response.data === '') {
                alert('empty');
            }
            this.setState({
                items: response.data,
                count: this.state.count + 20,
                loading: false,
                enableSave: true
            });
        }).catch(error => {
        });
    }

    deleteStaff(id){
        debugger
        console.log('hhgjjgkfdkdkkdkdkdkkdkdkdkdkkd')
        // e.preventDefault();
        let confirm = window.confirm('Do you really want to delete?');
        if (confirm) {
            const account = {
                edit_id: id,
                id: localStorage.getItem('id'),
                token: localStorage.getItem('token'),
                role_id: localStorage.getItem('role'),
            };
            let uri = global.BASE_URL + '/deleteStaffMember';

            axios.post(uri, account).then((response) => {
                // alert(response.data);
                if (response.data === 'Staff Deleted!') {
                    PNotify.alert({
                        text: "Staff member has been deleted successfully!",
                        type: 'success'
                    });
                    // alert('Staff member has been deleted successfully');
                    // window.location.reload();
                } else {
                    this.setState({
                        enableDeleteButton: true
                    });
                    PNotify.alert({
                        text: "Some thing went wrong try again latter!",
                        type: 'error'
                    });
                    // alert('Error, Try again later!');
                }
            }).catch(error => {
                PNotify.alert({
                    text: "Some thing went wrong try again latter!",
                    type: 'error'
                });
                // alert('Something went wrong, try again later');
            });
        }
    }

    selectBatch(e) {
        if (e.target.value !== '') {
            this.setState({
                batch: e.target.value,
                loading: true,
                enableSave: false
            });

            const values = {
                school_id: this.state.school,
                batch_id: e.target.value,
                token: localStorage.getItem('token'),
                id: localStorage.getItem('id'),
                count: this.state.count,
            };
            let uri2 = global.BASE_URL + '/SttafListForBatch';
            axios.post(uri2, values).then((response) => {
                this.setState({
                    items: response.data,
                    loading: false,
                    count: this.state.count + 20,
                    enableSave: true
                });
            });
            let uri = global.BASE_URL + '/ClassListForBatch'
            axios.post(uri, values).then((response) => {
                if (response.data.length === '1') {
                    this.setState(
                        {
                            seleted_class: true
                        }
                    );
                }
                this.setState({
                    classes: response.data
                });
            });
        }
    }

    selectClass(e) {
        if (e.target.value !== '') {
            this.setState({
                class: e.target.value,
                loading: true,
                count: this.state.count + 10,
                enableSave: false
            })

            let uri = global.BASE_URL + '/SttafListForClass';
            const values = {
                school_id: this.state.school,
                batch_id: this.state.batch,
                class_id: e.target.value,
                token: localStorage.getItem('token'),
                id: localStorage.getItem('id')
            };
            axios.post(uri, values).then((response) => {
                this.setState({
                    items: response.data,
                    loading: false,
                    count: this.state.count + 20,
                    enableSave: true
                });
                console.log(this.state.items);
            });
        }
    }

    searchByName(e) {
        this.setState({
            loading: true,
            enableSave: false
        })
        let uri = global.BASE_URL + '/searchSttafByName';
        const values = {
            school_id: this.state.school,
            batch_id: this.state.batch,
            class_id: this.state.class,
            std_name: e.target.value,
            token: localStorage.getItem('token'),
            id: localStorage.getItem('id')
        };

        axios.post(uri, values).then((response) => {
            this.setState({
                items: response.data,
                loading: false,
                enableSave: true
            });
        });

    }


    getBatch() {
        const account = {
            id: localStorage.getItem('id'),
            school_id: localStorage.getItem('school_id'),
            token: localStorage.getItem('token'),
            role: localStorage.getItem('role')
        };
        let uri = global.BASE_URL + '/BatchListForSchool';

        axios.post(uri, account).then((response) => {
            if (response.data.length === '1') {
                this.setState(
                    {
                        seleted_batch: true
                    }
                );
            }
            this.setState({
                batches: response.data
            });
        }).catch(error => {
        });
    }


  


    render() {
        let {items, schools, batches, classes} = this.state;
        // let {items, schools} = this.state;
        const columns = [{
            id: 'name',
            displayName: 'Name'
        }, {
            id: 'contact',
            displayName: 'Contact'
        }, {
            id: 'address',
            displayName: 'Address'
        }];
        let content = this.state.loading ? <div className="loading"/> : <div>
            <div className="row">
                {items.map(item =>
                    <div className="col-md-6 col-xl-3">
                        <div className="card m-b-30">
                            <div className="card-body row">
                                <div className="col-6">
                                    {(() => {
                                        if (item.picture === null || item.picture === '') {
                                            return <img className="img-responsive"
                                                        src={'https://portal.e-idara.com/build/assets/img/avatars/9.png'}
                                                        alt={item.name} height="70px" width="70px"/>;
                                        } else {
                                            return <img className="img-responsive" src={item.picture} alt={item.name}
                                                        height="70px" width="70px"/>
                                        }
                                    })()}
                                </div>
                                <div className="col-6 card-title align-self-center mb-0">
                                    <h5>{item.name}</h5>
                                    <p
                                        className="m-0">{item.status === '1' || item.status === "1" || item.status === 1 ?
                                        <Badge className="badge-success">Active</Badge> :
                                        <Badge className="badge-danger">In Active</Badge>}</p>
                                </div>
                            </div>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item"><i style={{fontSize: 28 + "px"}}
                                                                   className="fa fa-home float-right"/>
                                    <div id="list-content">
                                        {(() => {
                                            if (item.address === '' || item.address === null) {
                                                return <p>No Address Provided</p>
                                            } else {
                                                return <p>{item.address}</p>
                                            }
                                        })()}
                                    </div>
                                </li>
                                <li className="list-group-item"><i style={{fontSize: 28 + "px"}}
                                                                   className="fa fa-mobile float-right"/>
                                    <div className={"list-content"}>
                                        {(() => {
                                            if (item.contact === '' || item.contact === null) {
                                                return <p>No Contact Provided</p>
                                            } else {
                                                return <p>{item.contact}</p>
                                            }
                                        })()}

                                    </div>
                                </li>
                            </ul>
                            <div className="card-body">
                                <div className="float-right btn-group btn-group-sm">
                                   
                                    {/* <a onClick={()=>this.deleteStaff(item.id)} */}
                                    <a onClick={()=>this.deleteStaff(item.id)}
                                       className="btn btn-danger btn-sm mr-1"><i className="fa fa-trash"/></a>
                                    <Link to={{pathname: `/Staff/Edit/${item.id}`}} className="btn btn-primary tooltips"
                                          data-placement="top"
                                          data-toggle="tooltip"
                                          data-original-title="Edit"><i className="fa fa-pencil"/> </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <Row>
                <Col id="load-more-button">
                    <button className="btn btn-success btn-md" disabled={!this.state.enable}
                            onClick={this.loadMoreData}>
                        {(() => {
                            if (this.state.loadingButton) {
                                return <i className="icon icon-plus"><span className="ml-1">Loading</span></i>
                            } else {
                                return <i className="icon icon-plus"><span style={{fontFamily: 'sans-serif'}}
                                                                           className="ml-1">Load More</span></i>
                            }
                        })()}
                    </button>
                </Col>
            </Row>
        </div>;
        if (this.state.hasError) {
            this.props.history.push('/404')
        }
        return (
            <div className="animated fadeIn">
                <Row>
                    <Col>
                        <Card>
                            <CardHeader>
                                <i className="fa fa-align-justify"/> {lang[15]}
                                <Link to={{pathname: "/Staff/create"}}
                                      className={"btn btn-info btn-sm pull-right add-staff"}><i
                                    className={"icon-plus"}/> {lang[28]}</Link>
                                <div className="pull-right mr-1">
                                    <CsvDownloader
                                        filename="staff"
                                        datas={items}
                                        columns={columns}
                                        text="Save as CSV"
                                    >
                                        <button className="btn btn-sm btn-success" disabled={!this.state.enableSave}>
                                            <i className="fa fa-download mr-1"/>
                                            {lang[76]} CSV
                                        </button>
                                    </CsvDownloader>
                                </div>
                                <Link style={{marginRight: "10px"}}
                                      to={{pathname: `/Staff/importStaff`}}
                                      className="pull-right btn btn-sm btn-primary ml-2"><i
                                    className="fa fa-upload"/>&nbsp;{lang[85]}
                                </Link>
                            </CardHeader>
                            <CardBody>
                                {(() => {
                                    switch (this.state.role_id) {
                                        case '2':
                                            return <Form>
                                                <FormGroup>
                                                    <Row>
                                                        <Col xs={"6"} md={"6"} lg={"3"}>
                                                            <Input type="select" name="clss" id="SelectLm"
                                                                   onChange={this.school} className="">
                                                                <option value={""}>Select School</option>
                                                                {schools.map(item =>
                                                                    <option selected={!this.state.seleted_school}
                                                                            key={item.id}
                                                                            value={item.id}>{item.name}</option>
                                                                )};
                                                            </Input>
                                                        </Col>


                                                        <Col xs={"6"} md={"6"} lg={"3"}>
                                                            <Input type={"text"} placeholder={"Staff name"}
                                                                   name={"name"}
                                                                   onChange={this.searchByName}/>

                                                        </Col>
                                                    </Row>
                                                </FormGroup>
                                            </Form>;
                                        case '1':
                                            return <Form>
                                                <FormGroup>
                                                    <Row>
                                                        <Col xs={"6"} md={"6"} lg={"3"}>
                                                            <Input type="select" name="school_id" id="SelectLm"
                                                                   onChange={this.school} className="">
                                                                <option value={""}>Select School</option>
                                                                {schools.map(item =>
                                                                    <option selected={!this.state.seleted_school}
                                                                            key={item.id}
                                                                            value={item.id}>{item.name}</option>
                                                                )};
                                                            </Input>
                                                        </Col>

                                                        <Col xs={"6"} md={"6"} lg={"3"}>
                                                            <Input type={"text"} placeholder={"Staff name"}
                                                                   name={"name"}
                                                                   onChange={this.searchByName}/>
                                                        </Col>
                                                    </Row>
                                                </FormGroup>
                                            </Form>;
                                        case '3':
                                            return <Form>
                                                <FormGroup>
                                                    <Row>


                                                        <Col xs={"6"} md={"6"} lg={"3"}>
                                                            <Input type={"text"} placeholder={"Staff name"}
                                                                   name={"name"}
                                                                   onChange={this.searchByName}/>

                                                        </Col>
                                                    </Row>
                                                </FormGroup>
                                            </Form>;
                                        default:
                                            return;
                                    }
                                })()}
                                {/*{content}*/}
                                {items.map(item =>
                                    <Row style={{border: '1px solid #53535387', marginTop: '5px'}}>
                                        <Col xs="2">
                                            <div><img className="img-responsive" src={item.picture}
                                                      alt={item.name} onError={this.addDefaultSrc}
                                                      height="70px" width="70px"/></div>
                                        </Col>
                                        <Col xs="4">
                                            <p><b style={{color: 'gray', fontWeight: '400'}}>Name</b>: {item.name}</p>
                                            <p><b style={{color: 'gray', fontWeight: '400'}}>Address</b>: {item.address}</p>
                                        </Col>
                                        <Col xs="6">

                                            <p><b style={{color: 'gray', fontWeight: '400'}}>Contact</b>: {item.contact}
                                            </p>
                                            <p><b style={{color: 'gray', fontWeight: '400'}}>Status</b>:
                                                {item.status === '1' || item.status === 1 || item.status === "1" ?
                                                    <Badge className="badge-success">Active</Badge> :
                                                    <Badge className="badge-danger">In Active</Badge>
                                                }
                                            </p>
                                            <div style={{marginTop: '-52px', marginRight: '10px'}}>
                                                <a href={'javascript:void(0)'} onClick={()=>this.deleteStaff(item.id)} id={item.id}
                                                   className="btn btn-danger btn-sm mr-1 float-right">
                                                    <i style={{fontSize: '20px'}} className="fa fa-trash"/>
                                                </a>
                                                <Link to={{pathname: `/Staff/Edit/${item.id}`}}
                                                      className="btn btn-primary btn-sm mr-1 tooltips float-right"
                                                      data-placement="top"
                                                      data-toggle="tooltip"
                                                      data-original-title="Edit"><i style={{fontSize: '20px'}}
                                                                                    className="fa fa-pencil"/> </Link>

                                            </div>
                                        </Col>
                                    </Row>
                                )}
                                <Row style={{marginTop:'10px'}}>
                                    <Col id="load-more-button">

                                        {(() => {
                                            if (this.state.loadingButton) {
                                                return <button className="btn btn-success" disabled={!this.state.enable}
                                                               onClick={this.loadMoreData}>
                                                    <i className="fa fa-spinner"></i>{' '}
                                                    Loading
                                                </button>
                                            }
                                            else {
                                                return <button className="btn btn-success" disabled={!this.state.enable}
                                                               onClick={this.loadMoreData}>
                                                    <i className="icon icon-plus"></i>{' '}
                                                    Load More
                                                </button>
                                            }
                                        })()}

                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>

        );
    }
}

export default Users;
