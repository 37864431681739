import React, {Component} from 'react';
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Row, Alert, Progress
} from 'reactstrap';
import axios from 'axios';
import {Link} from "react-router-dom";
import PNotify from 'pnotify/dist/es/PNotify';
import lang from './../../../Language/en-us'
// import lang from './../../../Language/fr-ca'

export default class CreateUser extends Component {
    constructor(props) {
        super(props);
        this.getSchools();
        this.getRoles();
        this.handleSubmit = this.handleSubmit.bind(this);
        this.password = this.password.bind(this);
        this.state = {
            name: '',
            selected_school: '',
            user_name: '',
            collapse: true,
            fadeIn: true,
            schools: [],
            classess: [],
            roles: [],
            success: false,
            error: false,
            email_already: false,
            validation: false,
            timeout: 300,
            password_class:'',
            password_value:'',
            password_status:'',
            enable: true,
            role: false,
            role_id: localStorage.getItem('role'),
            school: localStorage.getItem('school_id'),
        };
        this.school = this.school.bind(this);
        this.role = this.role.bind(this);
        this.name = this.name.bind(this);
        this.user_name = this.user_name.bind(this);
    }

    password(e) {
        if (e.target.value.length <= 5) {
            this.setState({password_value: 15})
            this.setState({password_class:'danger'})
            this.setState({password_status:'Very Week'})
        }
        if (e.target.value.length >= 5) {
            this.setState({password_value: 35})
            this.setState({password_class:'warning'})
            this.setState({password_status:'Week'})
        }
        if (e.target.value.length >= 8) {
            this.setState({password_value: 60})
            this.setState({password_class:'info'})
            this.setState({password_status:'Normal'})
        }
        if (e.target.value.length >= 12) {
            this.setState({password_value: 80})
            this.setState({password_class:'primary'})
            this.setState({password_status:'Strong'})
        }
        if (e.target.value.length >= 15) {
            this.setState({password_value: 100})
            this.setState({password_class:'success'})
            this.setState({password_status:'Very Strong'})
        }
    }

    name(e) {
        const re = /^[0-9\b]+$/;
        if (/^[0-9a-zA-Z \b]+$/.test(e.target.value)) {
            this.setState({
                name: e.target.value
            });
        }
    }

    user_name(e) {
        const re = /^[0-9\b]+$/;
        if (/^[0-9a-zA-Z \b]+$/.test(e.target.value)) {
            this.setState({
                user_name: e.target.value
            });
        }
    }

    role(e) {
        if (e.target.value === '4') {
            this.setState({
                role: true
            })
        } else {
            this.setState({
                role: false
            })
        }
    }


    school(e) {
        this.setState({
            school: e.target.value
        })
        const account = {
            id: localStorage.getItem('id'),
            school_id: e.target.value,
            token: localStorage.getItem('token'),
            role_id: localStorage.getItem('role'),
            filter: '1',
            active: '1',
        };
        let uri = global.BASE_URL + '/ClassListForSchool';
        axios.post(uri, account).then((response) => {
            console.log(response);
            this.setState({classess: response.data});
        }).catch(error => {
        });
    }

    getSchools() {
        if (localStorage.getItem('role') === '1' || localStorage.getItem('role') === '2') {
            const account = {
                id: localStorage.getItem('id'),
                school_id: localStorage.getItem('school_id'),
                token: localStorage.getItem('token'),
                role: localStorage.getItem('role')
            };
            // const {history} = this.props;
            let uri = global.BASE_URL + '/getschoollist';

            axios.post(uri, account).then((response) => {
                if (response.data.length === 1 || response.data.length == 1) {
                    this.setState({selected_school: response.data[0].id});
                }
                this.setState({schools: response.data});
            }).catch(error => {
            });
        }
    }

    getRoles() {
        const account = {
            id: localStorage.getItem('id'),
            school_id: localStorage.getItem('school_id'),
            token: localStorage.getItem('token'),
            role: localStorage.getItem('role')
        };
        let uri = global.BASE_URL + '/getRoles';
        axios.post(uri, account).then((response) => {
            console.log(response);
            this.setState({roles: response.data});
        }).catch(error => {
        })

    }

    handleSubmit(e) {
        this.setState({enable: false});
        e.preventDefault();
        var serialize = require('form-serialize');
        var form = document.querySelector('#user_form');

        var obj = serialize(form, {hash: true});
        let uri = global.BASE_URL + '/createuser';
        axios.post(uri, obj).then((response) => {
            if (response.data === 'validation!') {
                PNotify.alert({
                    text: "Fill all the required fields!",
                    type: 'error'
                });
                // this.setState({validation: true});
                // alert('Please Fill all the fields.');
                this.setState({enable: true});
            } else if (response.data === 'email!') {
                PNotify.alert({
                    text: "Email already exists!!",
                    type: 'error'
                });
                // this.setState({email_already: true});
                // alert('Email already exists.');
                this.setState({enable: true});
            } else if (response.data === 'User created!') {
                // this.setState({success: true});
                PNotify.alert({
                    text: "User has been created successfully!",
                    type: 'success'
                });
                // alert('User Created Successfully');
                // this.props.history.push("/Users")
                // return (document.body)
            } else {
                PNotify.alert({
                    text: "Some thing went wrong try again latter!",
                    type: 'error'
                });
                // this.setState({error: true});
                // alert('Some thing went wrong try again latter.');
                this.setState({enable: true});
            }
            ;
        }).catch(error => {
            PNotify.alert({
                text: "Some thing went wrong try again latter!",
                type: 'error'
            });
            // this.setState({error: true});
            this.setState({enable: true});
        });
    }

    render() {
        let success = this.state.success ? <div><Alert color="primary">
            User Successfully Created
        </Alert></div> : <div></div>;
        let error = this.state.error ? <div><Alert color="danger">
            Some thing went wrong! try again latter
        </Alert></div> : <div></div>;
        let validation = this.state.validation ? <div><Alert color="warning">
            Fill all the fields
        </Alert></div> : <div></div>;
        let email_already = this.state.email_already ? <div><Alert color="warning">
            Email already exists
        </Alert></div> : <div></div>;

        let {schools, classess, roles, name, user_name, selected_school,password_class,
            password_value,
            password_status} = this.state;
        let classes = this.state.role ? <FormGroup row>
            <Col md="12">
                <Input type="select" name="classes[]" id="classes" multiple>
                    {classess.map(item =>
                        <option key={item.id} value={item.id}>{item.name}</option>
                    )};
                </Input>
            </Col>
        </FormGroup> : <div></div>;
        return (
            <div className="animated fadeIn">
                <Row>
                    <Card style={{width: 80 + "%", marginLeft: 10 + '%'}}>
                        <CardHeader>
                            {success}
                            {error}
                            {validation}
                            {email_already}
                            <Link to={{pathname: '/users'}} className="pull-right btn btn-sm btn-primary"><i
                                className="icon icon-arrow-left"/> {lang[73]}</Link>
                            <strong> {lang[65]}</strong>
                        </CardHeader>
                        <CardBody>
                            <Form onSubmit={this.handleSubmit} id="user_form" key={"form1"}>
                                {(() => {
                                    if (this.state.role_id === '1' || this.state.role_id === '2') {
                                        return <FormGroup>
                                            <Label htmlFor="nf-contact">School</Label>
                                            <select name={"school_id"} defaultValue={selected_school}
                                                    className="form-control col-12"
                                                    onChange={this.school} required>
                                                <option value={""}>Select School</option>
                                                {schools.map(item =>
                                                    <option key={item.id} value={item.id}>{item.name}</option>
                                                )};
                                            </select>

                                        </FormGroup>
                                    } else {
                                        return <input type="hidden" defaultValue={localStorage.getItem('school_id')}
                                                      name="school_id"
                                                      onChange={this.school}/>;
                                    }
                                })()}
                                <input type="hidden" name="id" value={localStorage.getItem('id')}/>

                                <FormGroup>
                                    <Label htmlFor="nf-school">Role</Label>
                                    <select name={"role"} id="role" className={"form-control col-12"} required
                                            onChange={this.role}>
                                        <option value={""}>Select Role</option>
                                        {roles.map(item =>
                                            <option key={item.id} value={item.id}>{item.name}</option>
                                        )}
                                    </select>

                                </FormGroup>
                                {classes}
                                <FormGroup>
                                    <Label htmlFor="nf-name">Name</Label>
                                    <Input type="text" id="nf-name" name="name" placeholder="User Name"
                                           onChange={this.name}
                                           value={name} maxLength="50"
                                           autoComplete="name" required/>

                                </FormGroup>
                                <FormGroup>
                                    <Label htmlFor="nf-email">Email</Label>
                                    <Input type="email" id="nf-eamil" name="email" placeholder="User Email"
                                           maxLength="50"
                                           autoComplete="email" required/>

                                </FormGroup>
                                <FormGroup>
                                    <Label htmlFor="nf-username">Username</Label>
                                    <Input id="nf-username" name="username" type={"text"} placeholder="Enter Username.."
                                           onChange={this.user_name}
                                           value={user_name}
                                           maxLength="50" required
                                           autoComplete="username"/>

                                </FormGroup>
                                <FormGroup>
                                    <Label htmlFor="nf-password">Password</Label>
                                    <Input type="password" id="nf-password" name="password" onChange={this.password}
                                           autoComplete="off" autoFill="off"
                                           placeholder={"Enter Password"} required/>

                                </FormGroup>
                                <center>
                                    <div style={{width: '50%', marginTop: '-5px'}}>
                                        <Progress multi>
                                            <Progress bar color={password_class} value={password_value}>{password_status}</Progress>
                                        </Progress>
                                    </div>
                                </center>


                                <Button type="submit" disabled={!this.state.enable} size="sm" color="primary"><i
                                    className="fa fa-dot-circle-o"/> Submit</Button>
                                <Button type="reset" size="sm" color="danger"><i className="fa fa-ban"/> Reset</Button>
                            </Form>
                        </CardBody>
                        <CardFooter></CardFooter>
                    </Card>
                </Row>
            </div>
        );
    }
}
