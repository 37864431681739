import React, {Component} from "react";
import PropTypes from 'prop-types';
import ReactJoyride, {ACTIONS, EVENTS, STATUS} from "react-joyride";


const style = {
    noteStyle: {
        color: '#ff0000',
        fontWeight: 'bold'
    }
}

class Demo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            continuous: true,
            loading: false,
            run: false,
            runHidden: false,
            stepIndex: 0,
            tourText: "Let's Start The Tour",
            steps: [
                {
                    content: (
                        <React.Fragment>
                            <h2>Let's start the tour!</h2>
                            <p>In this tour you will be guided step by step to get familiar with the working of the app</p>
                        </React.Fragment>
                    ),
                    placement: "center",
                    locale: {skip: "SKIP"},
                    target: "body"
                },
                {
                    content: (
                        <div>
                            <p>Add,Edit or Delete by clicking here</p>
                            <h4>Click next and then click on subjects</h4>
                        </div>
                    ),
                    placement: "bottom-start",
                    target: ".subject-list",
                    title: "Subjects List",
                },
                {
                    content: (
                        <React.Fragment>
                            <p>Edit a subject by clicking on
                                <button className="btn btn-sm btn-primary ml-1">
                                    <i className="fa fa-pencil"/>
                                </button></p>
                            <p align="center"> & </p>
                            <p>Delete by clicking on
                                <button className="btn btn-danger btn-sm ml-1">
                                    <i className="fa fa-trash"/>
                                </button></p>
                        </React.Fragment>
                    ),
                    placement: "center",
                    target: "body",
                    title: 'Edit or Delete a subject'
                },
                {
                    content: "You can import from csv file as well",
                    placement: "bottom-start",
                    target: ".subject-import",
                    title: "Import Subjects"
                },
                {
                    content: (
                        <div>
                            <p>Add a subject by clicking on this button.</p>
                            <h4>Go on! and click next and then click on this button</h4>
                        </div>
                    ),
                    placement: "bottom-start",
                    target: ".subject-add",
                    title: "Add a new subject",
                },
                {
                    content: (
                        <div>
                            <p>Latest year of entry will be created automatically once the school is created</p>
                            <p><span style={style.noteStyle}>Note: </span> You can add more by clicking on it</p>
                            <p>Now Click Next and then YearOfEntry</p>
                        </div>
                    ),
                    placement: "bottom-start",
                    target: ".year-of-entry",
                    title: "Year of Entry",
                },
                {
                    content: (
                        <React.Fragment>
                            <p>Create new entry by clicking on Add new</p>
                            <p>Edit a Year by clicking on
                                <button className="btn btn-sm btn-primary ml-1">
                                    <i className="fa fa-pencil"/>
                                </button></p>
                            <p align="center"> & </p>
                            <p>Delete by clicking on
                                <button className="btn btn-danger btn-sm ml-1">
                                    <i className="fa fa-trash"/>
                                </button></p>
                        </React.Fragment>
                    ),
                    placement: "center",
                    target: ".card",
                    title: "Year of Entry",
                },
                {
                    title: "Staff",
                    content: (
                        <React.Fragment>
                            <p>View, Create, Edit or delete a staff</p>
                            <h4>Click next and then click on staff</h4>
                        </React.Fragment>
                    ),
                    target: ".staff-members",
                    placement: "top"
                },
                {
                    title: "Add or View Class",
                    content: "You can see classes list or add new",
                    target: ".classes",
                    placement: "top"
                },
                {
                    title: "Students List",
                    content: (
                        <div>
                            <p>Add, View, Edit or Delete a Student.</p>
                            <p>Click next and click on <strong>Students</strong></p>
                        </div>
                    ),
                    target: ".students-list",
                    placement: "top"
                },
                {
                    title: "Students View",
                    content: (
                        <div>
                            <p>You can view student profile by clicking on student picture or
                                <button className={"btn btn-success btn-sm ml-1"}>
                                    <i className={"fa fa-eye"}/>
                                </button></p>
                        </div>
                    ),
                    target: ".card",
                    placement: "center"
                },
                {
                    title: 'Results',
                    content: 'Here you can define result categories like monthly, yearly as well bulk upload the result',
                    target: '.result-category',
                    placement: 'top'
                },
                {
                    title: 'Fee',
                    content: (
                        <React.Fragment>
                            <p>Fee related things like fee structure or student fee can be controlled from here</p>
                            <h4>Click on next and then click here</h4>
                        </React.Fragment>
                    ),
                    target: '.student-fee',
                    placement: 'top'
                },
                {
                    title: 'Fee Vouchers',
                    content: 'Fee vouchers can be created from here',
                    target: '.fee-voucher',
                    placement: 'top'
                },
                {
                    title: 'Fee Defaulters',
                    content: 'Fee Defaulter will show here and you can send and email to every individual to remind them',
                    target: '.fee-defaulter',
                    placement: 'top'
                },
                {
                    title: 'Users',
                    content: 'User accounts can be created, updated or deleted here',
                    target: '.users',
                    placement: 'top'
                },
                {
                    title: 'Student Attendance',
                    content: 'Daily based attendance can be viewed or created here',
                    target: '.student-attendance',
                    placement: 'top'
                },
                {
                    title: 'Staff Attendance',
                    content: 'Daily based attendance can be viewed or created here',
                    target: '.staff-attendance',
                    placement: 'top'
                },
                {
                    title: 'School Settings',
                    content: 'Attendance time, Currency or Logo can be set here',
                    target: '.school-settings',
                    placement: 'top'
                },
                {
                    title: 'Email Templates',
                    content: 'Email template for Absent, Fee Defaulter and events can be designed here',
                    target: '.email-template',
                    placement: 'top'
                },
                {
                    title: 'SMS Templates',
                    content: 'SMS template for Absent, Fee Defaulter and events can be designed here',
                    target: '.sms-template',
                    placement: 'top'
                },
                {
                    title: 'Library Management',
                    content: 'Library folders, files can be added or deleted here',
                    target: '.library',
                    placement: 'top'
                },
                {
                    title: 'Time Table',
                    content: (
                        <React.Fragment>
                            <p>Timetable for classes can be created or edited here</p>
                            <p><span style={style.noteStyle}>Tip: </span> To Edit Timetable, click on added time</p>
                        </React.Fragment>
                    ),
                    target: '.timetable',
                    placement: 'top'
                },
                {
                    title: 'Home work',
                    content: (
                        <React.Fragment>
                            <p>Homework for classes can be assigned here</p>
                        </React.Fragment>
                    ),
                    target: '.homework',
                    placement: 'top'
                },
                {
                    title: 'Events',
                    content: (
                        <React.Fragment>
                            <p>This is for defining the latest happenings and events in the School</p>
                        </React.Fragment>
                    ),
                    target: '.events',
                    placement: 'top'
                },
                {
                    title: "Tour Completed",
                    content: (
                        <div>
                            <p><strong>Congratulations!</strong> You have completed the tour successfully.</p>
                            <p>If you need any help in any case, feel free to contact us</p>
                        </div>
                    ),
                    target: "body",
                    placement: "center"
                },
            ]
        };
    }

    static propTypes = {
        joyride: PropTypes.shape({
            callback: PropTypes.func
        })
    };

    static defaultProps = {
        joyride: {}
    }

    handleClickStart = e => {
        e.preventDefault();

        this.setState({
            run: true,
            stepIndex: 0
        });
    };

    handleJoyrideCallback = data => {
        const {joyride} = this.props;
        const {action, index, type, status} = data;

        // if (type === EVENTS.TOUR_END && this.state.run) {
        //   // Need to set our running state to false, so we can restart if we click start again.
        //   this.setState({run: false});
        // }
        if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status) && this.state.run) {
            // Need to set our running state to false, so we can restart if we click start again.
            this.setState({run: false, runHidden: false});
        } else if (type === EVENTS.STEP_AFTER && (index === 1 || index === 6 || index === 10 || index === 15 || index === 16 || index === 20 || index === 22)) {
            this.setState({run: false, loading: true});

            setTimeout(() => {
                this.setState({
                    loading: false,
                    run: true,
                    stepIndex: index + (action === ACTIONS.PREV ? -1 : 1)
                });
            }, 2500);
        } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
            // Update state to advance the tour
            this.setState({stepIndex: index + (action === ACTIONS.PREV ? -1 : 1)});
        } else if (type === EVENTS.TOOLTIP_CLOSE) {
            this.setState({stepIndex: index + 1});
        }
        if (typeof joyride.callback === "function") {
            joyride.callback(data);
        } else {
            return 0;
        }
    };

    render() {
        const {run, steps, runHidden, tourText, ...joyrideProps} = this.state;
        const props = {
            ...joyrideProps,
            ...this.props.joyride
        };
        return (
            <div className="demo-wrapper">
                <ReactJoyride
                    continuous
                    showProgress
                    showSkipButton
                    {...props}
                    run={run}
                    steps={steps}
                    styles={{
                        options: {
                            zIndex: 10000000
                        }
                    }}
                    locale={{
                        back: <span>Back</span>,
                        close: <span>Close</span>,
                        last: <span>End</span>,
                        next: <span>Next</span>,
                        skip: <span>Skip</span>
                    }}
                    callback={this.handleJoyrideCallback}
                />
                <div>
                    {!runHidden && (
                        <button className="btn btn-md btn-tour pull-right" onClick={this.handleClickStart}>
                            {!run ? tourText : 'On Your Way...'}
                        </button>
                    )}
                </div>
            </div>
        );
    }
}

export default Demo;
