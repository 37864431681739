import React, {Component} from 'react';
import {DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, Badge, Tooltip} from 'reactstrap';
import PropTypes from 'prop-types';
import {AppHeaderDropdown, AppNavbarBrand, AppSidebarToggler} from '@coreui/react';
import favicon from './../../assets/img/brand/favicon.ico'
import axios from "axios/index";
import {Link} from "react-router-dom";
import PNotify from 'pnotify/dist/es/PNotify';
import Demo from './../../views/Essentials/demo'

const propTypes = {
    children: PropTypes.node,
};

const defaultProps = {};

class DefaultHeader extends Component {
    constructor(props) {
        super(props);
        this.getlogo();
        this.getNotifications();
        var today = new Date();
        if ((today.getMonth() + 1) <= 9) {
            var month = "0" + (today.getMonth() + 1);
        } else {
            var month = (today.getMonth() + 1);
        }
        if (today.getDate() <= 9) {
            var day = "0" + (today.getDate());
        } else {
            var day = (today.getDate());
        }
        var date = today.getFullYear() + '-' + month + '-' + day;
        this.state = {
            logo: null,
            impersonation_mode: false,
            free_trial_data: false,
            subs_date: date,
            schoolName: [],
            free_trial: [],
            loadingss: false,
            notification: [],
            notification_User: [],
            role_id: localStorage.getItem('role'),
            tooltipOpen: [false, false],
            subscription_date: localStorage.getItem('subscription'),
            tooltips: [
                {
                    placement: 'top',
                    text: 'Top',
                },
                {
                    placement: 'bottom',
                    text: 'Bottom',
                },
                {
                    placement: 'left',
                    text: 'Left',
                },
                {
                    placement: 'right',
                    text: 'Right',
                },
            ],
        };
    }



    mark_as_read(id, module) {
        this.setState({loadingss: true});
        const account = {
            id: id,
            module: module,
            school_id: localStorage.getItem('school_id'),
            token: localStorage.getItem('token'),
            role: localStorage.getItem('role'),
            user_id: localStorage.getItem('id'),
        };
        let uri = global.BASE_URL + '/markAsRead';
        axios.post(uri, account).then((response) => {
            this.setState({loadingss: false});
            this.getNotifications();
        }).catch(error => {
            console.log(error);
        });
    }

    toggle(i) {
        const newArray = this.state.tooltipOpen.map((element, index) => {
            return (index === i ? !element : false);
        });
        this.setState({
            tooltipOpen: newArray,
        });
    }


    logout = () => {
        localStorage.clear();
    }

    remove_impersonation = () => {
        localStorage.setItem('role', '1');
        localStorage.setItem('school_id', '');
        localStorage.setItem('impersonation_id', '');
        window.location.reload();
    }

    getlogo() {
        if(localStorage.getItem('role') !='1'){
            this.setState({free_trial_data:true});
        }


        PNotify.defaults.styling = 'material';
// This icon setting requires the Material Icons font. (See below.)
        PNotify.defaults.icons = 'material';
        PNotify.defaults.icons = 'fontawesome4'; // Font Awesome 4
// or
        PNotify.defaults.icons = 'fontawesome5'; // Font Awesome 5
        const account = {
            id: localStorage.getItem('id'),
            school_id: localStorage.getItem('school_id'),
            token: localStorage.getItem('token'),
            role: localStorage.getItem('role'),
        };
        let uri = global.BASE_URL + '/getSchoolLogo';
        axios.post(uri, account).then((response) => {
            this.setState({
                logo: response.data
            })
        }).catch(error => {
            console.log(error);
        });
        if (localStorage.getItem('impersonation_id') !== '' || localStorage.getItem('impersonation_id') != null) {

            console.log(localStorage.getItem('impersonation_id'));
            const account = {
                id: localStorage.getItem('impersonation_id'),
            };
            let uri = global.BASE_URL + '/getSchoolName';
            axios.post(uri, account).then((response) => {
                // console.log(response.data[0].name);
                this.setState({
                    schoolName: response.data[0].name,

                })
                this.setState({
                    impersonation_mode: true
                })
            }).catch(error => {
                console.log(error);
            });
        }
    }

    getNotifications() {
        const account = {
            id: localStorage.getItem('id'),
            school_id: localStorage.getItem('school_id'),
            token: localStorage.getItem('token'),
            role: localStorage.getItem('role'),
        };
        let uri = global.BASE_URL + '/getNotifications';
        axios.post(uri, account).then((response) => {
            this.setState({
                notification: response.data,
            })
        }).catch(error => {
            console.log(error);
        });
        if (localStorage.getItem('role') === '1') {
            const accounts = {
                id: localStorage.getItem('id'),
                school_id: localStorage.getItem('school_id'),
                token: localStorage.getItem('token'),
                role: localStorage.getItem('role'),
            };
            let uris = global.BASE_URL + '/getNotificationsUsers';
            axios.post(uris, accounts).then((response) => {
                this.setState({
                    notification_User: response.data,
                })
            }).catch(error => {
                console.log(error);
            });
        }
    }


    render() {
        let loader = this.state.loadingss ? <div className="loading"/> : <div></div>
        // let logo = this.state.logo === '' ? this.state.logo :'http://portal.e-idara.com/build/logo.png';
        // const {children, ...attributes} = this.props;
        let {logo, schoolName, notification, notification_User, free_trial, subscription_date} = this.state;
        let styles = {
            color: '#000'
        };
        let impersonation = this.state.impersonation_mode ? <div>
                <h4 style={{marginLeft: "100px", color: "#2dade3"}}>
                    {schoolName}
                    <a href={"#"} onClick={this.remove_impersonation}>
                        <i className="fa fa-remove"/>
                    </a>
                </h4>
            </div>
            : <div/>


        let free_trial_data = this.state.free_trial_data ? <div>
                <h4 style={{marginLeft: "100px", color: "#2dade3"}}>Your subscription will end on {subscription_date}</h4>
            </div>
            : <div/>


        return (
            <React.Fragment>
                <AppSidebarToggler className="d-lg-none" display="md" mobile/>
                <AppNavbarBrand
                    full={{src: logo, width: 50, height: 50, alt: 'E-Idara'}}
                    minimized={{src: favicon, width: 30, height: 30, alt: 'TSP'}}

                />
                <AppSidebarToggler className="d-md-down-none" display="lg"/>
                <Nav className="d-md-down-none" navbar>
                    <NavItem className="px-3">
                        <h3>E-Idara</h3>
                    </NavItem>
                    {impersonation}
                    {free_trial_data}
                </Nav>


                <Nav className="ml-auto mr-3" navbar>
                    <Nav className="ml-auto" navbar>
                        <Demo/>
                        <AppHeaderDropdown direction="down">
                            <DropdownToggle nav>
                                <i className="icon-bell"/><Badge pill
                                                                 color="danger">{notification.length + notification_User.length}</Badge>
                            </DropdownToggle>
                            <DropdownMenu right style={{height: "400px", overflowX: 'hidden', overflowY: 'visible'}}>
                                <DropdownItem header tag="div" className="text-center"><strong>You
                                    have {notification.length + notification_User.length} Notifications</strong></DropdownItem>
                                <center><a style={{fontSize: '15px', marginTop: '17px', marginBottom: '-10px'}}
                                           onClick={this.mark_as_read.bind(this, '1', 'All')} href="javascript:void(0);"
                                           id="All">Mark all as read</a></center>
                                <Link
                                    style={{float: 'right', marginTop: '-21px', fontSize: '15px', marginRight: '10px'}}
                                    to={{pathname: "/Notifications"}}>View All Notifications <i
                                    className={"fa fa-eye"}></i></Link>
                                {notification.map(items =>
                                    <DropdownItem style={{minWidth: "680px"}}><Link
                                        to={{pathname: "/Students/Profile/" + items.stu_id}}
                                        style={styles}><i
                                        className="fa fa-bell-o"/>{items.student_name} <span
                                        style={{fontSize: "10px"}}>(Roll#:{items.student_id} School Name: {items.school_name} Class: {items.class_name})
                                    </span></Link>
                                        <Link to={{pathname: "/StudentFee/singleVoucherFee/detail/" + items.id}}>
                                            <Badge
                                                color="info">Pending
                                                Fee:{items.currency} {items.pending_amount.toLocaleString()}</Badge></Link>
                                        <a style={{
                                            float: 'right',
                                            fontSize: '10px',
                                            marginTop: '17px',
                                            marginBottom: '-10px'
                                        }} onClick={this.mark_as_read.bind(this, items.id, 'Voucher')}
                                           href="javascript:void(0);" id="VoucherToolTip">Mark as read</a>
                                    </DropdownItem>
                                )}

                                {(() => {
                                    if (this.state.role_id === '1') {
                                        return <DropdownItem header tag="div" className="text-center"><strong>New
                                            Registered Schools</strong></DropdownItem>;
                                    }
                                })()}


                                {notification_User.map(items =>
                                    <DropdownItem style={{minWidth: "700px"}}><Link
                                        to={{pathname: "/Schools/View/" + items.id}}
                                        target="_blank">
                                        <i className="fa fa-bell-o" style={{padding: '8px 0'}}/><Badge
                                        color="info" style={{left: "37px", padding: '8px'}}>{items.name} needs to
                                        be approved</Badge></Link>
                                        <a style={{
                                            float: 'right',
                                            fontSize: '10px',
                                            marginTop: '27px',
                                            marginBottom: '-10px'
                                        }} onClick={this.mark_as_read.bind(this, items.id, 'Registered School')}
                                           href="javascript:void(0);" id="SchoolToolTip">Mark as read</a>

                                    </DropdownItem>
                                )}

                            </DropdownMenu>
                        </AppHeaderDropdown>
                    </Nav>
                    <AppHeaderDropdown direction="down">
                        <DropdownToggle nav>

                            <span className='ml-2 font-sm'>{localStorage.getItem('name')}</span>
                            <img src={'https://portal.e-idara.com/assets/img/avatars/9.png'} className="img-avatar" alt="img"/>
                        </DropdownToggle>
                        <DropdownMenu right style={{right: 'auto'}}>
                            <Link to={{pathname: "/login"}} onClick={this.logout}><DropdownItem><i
                                className="fa fa-lock"/> Logout</DropdownItem>
                            </Link>
                        </DropdownMenu>
                    </AppHeaderDropdown>
                </Nav>
            </React.Fragment>
        );
    }
}

DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;

export default DefaultHeader;
