import React, {Component} from 'react';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from 'reactstrap';
import axios from 'axios';
import PNotify from 'pnotify/dist/es/PNotify';

class Register extends Component {
  constructor(props) {
    super(props);
    this.checkUser();
    this.state = {
      user_name: '',
      email: '',
      password: '',
      address: '',
      contact_number: '',
      logo: '',
      school_name: '',
      enable: true,
    };

    this.handleChange1 = this.handleChange1.bind(this);
    this.handleChange2 = this.handleChange2.bind(this);
    this.handleChange3 = this.handleChange3.bind(this);
    this.handleChange4 = this.handleChange4.bind(this);
    this.handleChange5 = this.handleChange5.bind(this);
    this.handleChange6 = this.handleChange6.bind(this);
    this.handleChange7 = this.handleChange7.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange1(e) {
    this.setState({
      school_name: e.target.value
    })
  }

  handleChange2(e) {
    this.setState({
      address: e.target.value
    })
  }


  handleChange4(e) {
    this.setState({
      user_name: e.target.value,
    })
  }

  handleChange5(e) {
    this.setState({
      email: e.target.value,
    })
  }

  handleChange6(e) {
    this.setState({
      contact_number: e.target.value,
    })
  }

  handleChange7(e) {
    this.setState({
      password: e.target.value
    })
  }


  checkUser() {
    const account = {
      token: localStorage.getItem('token'),
      id: localStorage.getItem('id'),
      email: localStorage.getItem('email'),
    };
    let uri = global.BASE_URL + '/checkUser';
    axios.post(uri, account).then((response) => {
      if (response.status === 200 || response.ok) {
        this.props.history.push("/");
      } else {
      }
    }).catch(error => {
    });
  }

  handleChange3(e) {
    this.setState({logo: e.target.files[0]})
  }

  handleSubmit(e) {
    this.setState({enable: false});
    e.preventDefault();
    const account = {
      school_name: this.state.school_name,
      address: this.state.address,
      logo: this.state.logo,
      user_name: this.state.user_name,
      email: this.state.email,
      contact_number: this.state.contact_number,
      password: this.state.password,
      user_token: localStorage.getItem('token'),
      user_id: localStorage.getItem('id'),
      user_email: localStorage.getItem('email'),
      user_role: localStorage.getItem('role'),
      register: '1',
    };

    const formData = new FormData();
    formData.append('logo', this.state.logo);

    formData.append('school_name', this.state.school_name);
    formData.append('address', this.state.address);
    formData.append('user_name', this.state.user_name);
    formData.append('email', this.state.email);
    formData.append('contact_number', this.state.contact_number);
    formData.append('password', this.state.password);
    formData.append('user_token', localStorage.getItem('token'));
    formData.append('user_id', localStorage.getItem('id'));
    formData.append('user_email', localStorage.getItem('email'));
    formData.append('user_role', localStorage.getItem('role'));
    formData.append('register', '1');


    let uri = global.BASE_URL + '/create';
    axios.post(uri, formData).then((response) => {
      if (response.data === 'Validation') {
        alert('Fill all required fields');
      } else if (response.data === 'School created!') {
        PNotify.alert({
          text: "School has been created successfully",
          type: 'success'
        });
        console.log(account);
        console.log(response);
        this.props.history.push("/login");
      } else {
        PNotify.alert({
          text: "Some thing went wrong!",
          type: 'error'
        });
      }
    }).catch(error => {
      this.setState({enable: true});
    });
  }

  render() {
    return (
      <div className="app flex-row align-items-center" style={{marginTop: "100px"}}>
        <Container>
          <Row className="justify-content-center">
            <Col md="6">
              <Card className="mx-4">
                <CardBody className="p-4">
                  <Form onSubmit={this.handleSubmit}>
                    <h1>Register</h1>
                    <p className="text-muted">Create your account</p>
                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="icon-user"/>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input type="text" placeholder="School Name" autoComplete="school_name" required
                             name={"school_name"} onChange={this.handleChange1}/>
                    </InputGroup>
                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>@</InputGroupText>
                      </InputGroupAddon>
                      <textarea placeholder="School Address" name={"address"}
                                onChange={this.handleChange2} style={{width: 91 + '%'}}/>
                    </InputGroup>
                    <InputGroup className="mb-3">
                      <Input type="file" name={"logo"} onChange={this.handleChange3}/>
                    </InputGroup>
                    <InputGroup className="mb-4">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="icon-lock"/>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input type="text" placeholder="User Name" autoComplete="name" required
                             name={"user_name"} onChange={this.handleChange4}/>
                    </InputGroup>
                    <InputGroup className="mb-4">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="icon-lock"/>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input type="text" placeholder="Email" autoComplete="email" name={"email"} required
                             onChange={this.handleChange5}/>
                    </InputGroup>
                    <InputGroup className="mb-4">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="icon-lock"/>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input type="text" placeholder="Contact Number" autoComplete="number" required
                             name={"contact_number"} onChange={this.handleChange6}/>
                    </InputGroup>
                    <InputGroup className="mb-4">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="icon-lock"/>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input type="password" placeholder="password" name={"password"} required
                             onChange={this.handleChange7}/>
                    </InputGroup>
                    <Button color="success" disabled={!this.state.enable} block>Submit</Button>
                  </Form>
                </CardBody>
                <CardFooter className="p-4">
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Register;
