import './../../../index';
import React, {Component} from 'react';
import {
    Card, CardBody, CardHeader, Col, Row,
    Input, Form, FormGroup, Label,
} from 'reactstrap';
import {Link} from 'react-router-dom';
import axios from "axios/index";
import ReactTable from 'react-table';
import lang from './../../../Language/en-us'
import {
    ModalBody, ModalFooter, ModalHeader, Button, Modal
} from 'reactstrap';
// import Button from "reactstrap/src/Button";

// import lang from './../../../Language/fr-ca'

class Tables extends Component {
    constructor(props) {
        super(props);
        this.getSchools();
        this.getAttendanceList();
        this.getBatches();
        var today = new Date(),
            date = (today.getMonth() + 1) + '-' + today.getDate() + '-' + today.getFullYear();

        this.state = {
            batch: [],
            selected_school: '',
            classes: [],
            items: [],
            schools: [],
            hasError: false,
            modal: false,
            loading: true,
            attendance_date: date,
            role_id: localStorage.getItem('role'),
        };

        this.getAttendanceList = this.getAttendanceList.bind(this);
        this.school = this.school.bind(this);
        this.openmodel = this.openmodel.bind(this);
        this.toggle = this.toggle.bind(this);
        this.batch_change = this.batch_change.bind(this);
        this.schoolForBatch = this.schoolForBatch.bind(this);
        this.submit = this.submit.bind(this);
    }
    submit(e){
        var batch_id = document.getElementById('batch').value;
        var class_id = document.getElementById('classes').value;
        var date = document.getElementById('date').value;
        var path = '/NewAttendance/'+batch_id+"/"+class_id+"/"+date;
        this.props.history.push(path);
        // window.location = '../asd/'+batch_id+"/"+class_id+"/"+date;
    }
    getBatches() {
        const account = {
            id: localStorage.getItem('id'),
            school_id: localStorage.getItem('school_id'),
            token: localStorage.getItem('token'),
            role: localStorage.getItem('role'),
            active: '1',
        };
        let uri = global.BASE_URL + '/BatchListForSchool';

        axios.post(uri, account).then((response) => {
            // console.log(response);
            this.setState({batch: response.data}).bind();
        }).catch(error => {
        });

    }
    batch_change(e) {
        this.setState({
            batch_change: e.target.value,
        });
        const account = {
            batch: e.target.value,
            id: localStorage.getItem('id'),
            school_id: localStorage.getItem('school_id'),
            token: localStorage.getItem('token'),
            role: localStorage.getItem('role')
        };
        let uri = global.BASE_URL + '/ClassListByBatchForSchool';

        axios.post(uri, account).then((response) => {
            this.setState({classes: response.data});
        }).catch(error => {
        });

    }
    openmodel(e) {
        this.setState({
            modal: !this.state.modal,
        });
    }

    toggle() {
        this.setState({
            modal: !this.state.modal,
        });
    }

    getSchools() {
        if (localStorage.getItem('role') === '1' || localStorage.getItem('role') === '2') {
            const account = {
                id: localStorage.getItem('id'),
                school_id: localStorage.getItem('school_id'),
                token: localStorage.getItem('token'),
                role: localStorage.getItem('role')
            };
            let uri = global.BASE_URL + '/getschoollist';

            axios.post(uri, account).then((response) => {
                if(response.data.length === 1 || response.data.length == 1){
                    this.setState({selected_school: response.data[0].id});
                }
                this.setState({
                    schools: response.data
                });
            }).catch(error => {
            });
        }
    }

    school(e) {
        this.setState({
            school: e.target.value,
            loading: true
        });
        const account = {
            id: localStorage.getItem('id'),
            school_id: e.target.value,
            token: localStorage.getItem('token'),
            role: localStorage.getItem('role'),
            filter: '1',
        };
        let uri = global.BASE_URL + '/AttendanceListForSchool';
        axios.post(uri, account).then((response) => {
            this.setState({
                items: response.data,
                loading: false
            });
        }).catch(error => {
        });
    }
    schoolForBatch(e) {

        const account = {
            id: localStorage.getItem('id'),
            school_id: e.target.value,
            token: localStorage.getItem('token'),
            role: localStorage.getItem('role'),
            active: '1',
            filter: '1',
        };
        let uri = global.BASE_URL + '/BatchListForSchool';

        axios.post(uri, account).then((response) => {
            // console.log(response);
            this.setState({batch: response.data}).bind();
        }).catch(error => {
        });

    }

    getAttendanceList() {
        const account = {
            id: localStorage.getItem('id'),
            school_id: localStorage.getItem('school_id'),
            token: localStorage.getItem('token'),
            role: localStorage.getItem('role'),
        };
        let uri = global.BASE_URL + '/AttendanceListForSchool';

        axios.post(uri, account).then((response) => {
            this.setState({
                items: response.data,
                loading: false
            });
        }).catch(error => {
        });
    }


    render() {
        console.log(this.state.attendance_date);
        let {items, schools, batch, classes ,selected_school} = this.state;
        let content = this.state.loading ? <div className="loading"/> : <ReactTable
            data={items}
            columns={[
                {
                    Header: "Class",
                    accessor: 'class',
                },
                {
                    Header: "Batch",
                    accessor: 'batch'
                },
                {
                    Header: 'Date',
                    accessor: 'date'
                },
                {
                    Header: 'View',
                    Cell: row => (<Link to={{pathname: `/Attandance/AttendanceView/${row.original.id}`}}>View</Link>)
                },
            ]}
            defaultPageSize={5}
            className="-striped -highlight -sm"
            showPagination={true}
            showPaginationTop={false}
            showPaginationBottom={true}
            pageSizeOptions={[5, 10, 20, 25, 50, 100]}
            filterable
        />;
        if (this.state.hasError) {
            this.props.history.push('/404')
        }
        return (
            <div className="animated fadeIn">

                <Row>
                    <Col>
                        <Card>

                            <CardHeader>
                                <i className="fa fa-align-justify"/> {lang[79]}
                                <button color="primary create-button" onClick={this.openmodel}
                                        className="btn btn-info btn-sm pull-right"><i className="icon-plus"/> {lang[28]}
                                </button>
                                <Link to={{pathname: "/NewAttendance"}} style={{marginRight: "10px"}}
                                      color="primary create-button"
                                      className="btn btn-info btn-sm pull-right"><i className="icon-plus"/> {lang[83]}
                                </Link>
                                <Link to={{pathname: "/Attandance/report"}} style={{marginRight: "10px"}}
                                      color="primary create-button"
                                      className="btn btn-info btn-sm pull-right"><i className="icon-plus"/> {lang[100]}
                                </Link>
                            </CardHeader>

                            <CardBody>
                                {(() => {
                                    if (this.state.role_id === '1' || this.state.role_id === '2') {
                                        return <Form>
                                            <Row>
                                                <FormGroup>
                                                    <Col md="12">
                                                        <Label htmlFor="selectSm"> </Label>
                                                    </Col>
                                                    <Col xs="12" md="12">
                                                        <Input type="select" name="clss" id="SelectLm" defaultValue={selected_school}
                                                               onChange={this.school}>
                                                            <option value={""}>Select School</option>
                                                            {schools.map(item =>
                                                                <option key={item.id}
                                                                        value={item.id}>{item.name}</option>
                                                            )};
                                                        </Input>
                                                    </Col>
                                                </FormGroup>
                                            </Row>
                                        </Form>;
                                    }
                                })()}
                                <Modal isOpen={this.state.modal} toggle={this.toggle}
                                       className={this.props.className}>
                                    <ModalHeader toggle={this.toggle}>New Attendance</ModalHeader>
                                    <ModalBody>
                                        <Form id="edit_form" key={"form1"}>

                                            {/*<input type="hidden" name="edit_id" value={this.state.edit_model_id}/>*/}
                                            <input type="hidden" name="id" value={localStorage.getItem('id')}/>
                                            <input type="hidden" name="token" value={localStorage.getItem('token')}/>
                                            {(() => {
                                                if (this.state.role_id === '1' || this.state.role_id === '2') {
                                                    return <Form>
                                                        <Row>
                                                            <FormGroup>
                                                                <Col md="12">
                                                                    <Label htmlFor="selectSm"> </Label>
                                                                </Col>
                                                                <Col xs="12" md="12">
                                                                    <Input type="select" name="clss" id="SelectLm"
                                                                           onChange={this.schoolForBatch}>
                                                                        <option value={""}>Select School</option>
                                                                        {schools.map(item =>
                                                                            <option key={item.id}
                                                                                    value={item.id}>{item.name}</option>
                                                                        )};
                                                                    </Input>
                                                                </Col>
                                                            </FormGroup>
                                                        </Row>
                                                    </Form>;
                                                }
                                            })()}
                                            <Row>
                                                <Col sm="6">
                                                    <FormGroup>
                                                        <Label htmlFor="nf-email">Batch</Label>
                                                        <select name="batch" id="batch" onChange={this.batch_change}
                                                                className={"col-12 form-control"}
                                                                required>
                                                            <option value={""}>Select</option>
                                                            {batch.map(item =>
                                                                <option key={item.id} value={item.id}
                                                                        selected={this.state.edit_model_batch === item.id}>{item.name}</option>
                                                            )};
                                                        </select>

                                                    </FormGroup>
                                                </Col>
                                                <Col sm="6">
                                                    <FormGroup>
                                                        <Label htmlFor="nf-email">Class</Label>
                                                        <select name="classes" id="classes" onChange={this.clss}
                                                                className={"col-12 form-control"}
                                                                required>
                                                            <option value="">Select</option>
                                                            {classes.map(item =>
                                                                <option key={item.id} value={item.id}
                                                                        selected={this.state.edit_model_class === item.id}>{item.name}</option>
                                                            )};
                                                        </select>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm="12">
                                                    <FormGroup>
                                                        <Label htmlFor="nf-email">Date</Label>
                                                        <input type="text" readOnly={true} id="date" name="date" defaultValue={this.state.attendance_date} className="form-control"/>
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                        </Form>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button color="primary" onClick={this.submit}>Save</Button>{' '}
                                        <Button color="secondary" onClick={this.toggle}>Cancel</Button>
                                    </ModalFooter>
                                </Modal>
                                {content}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>

        );
    }
}

export default Tables;
